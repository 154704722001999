td span {
    line-height: 1.2;
}

table {
    border-collapse: separate;
    border-spacing: 0;
    border: solid 1px #d8d8d8;
    border-radius: 3px;
    -moz-border-radius: 3px;
    width: 80%;
    margin: 0 auto;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    background-color: #ffffff;
}

// .table:last-child {
//   margin-bottom:0;
// }

.table-responsive {
    width: 100%;
}

.table > thead > tr > th {
    border-bottom: none;
    vertical-align: top;
}

.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
    padding: 10px 20px;
}

.x_content .table:last-child,
.table:last-child {
    margin-bottom: 0;
}

table th,
table td {
    padding: 15px 20px;
    border-right: solid 1px #d8d8d8;
    font-size: 1rem;
}

table th:last-child,
table td:last-child {
    border-right: none;
}

thead tr:first-child {
    border-radius: 3px;
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);

    th {
        font-size: 0.88rem;
        font-weight: bold;
    }
}

thead tr:last-child {
    border-radius: 3px;
    background-color: #f6f8fa;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    border-top: solid 1px #d8d8d8;
    border-bottom: solid 1px #d8d8d8;
    text-transform: uppercase;
    background-image: none;

    th {
        font-size: 1rem;
        font-weight: bold;
    }
}

thead tr:only-child {
    border-radius: 3px;
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
    text-transform: none;
    border-bottom: 0;
    th {
        font-size: 0.88rem;
    }

    border-top: 0;
}

.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
    padding: 15px 20px;
    line-height: 1.2;

    &.py-9px {
        padding-top: 9px;
        padding-bottom: 9px;
    }
}

table .numColumn {
    width: 1%;
}

.creation table .content {
    padding: 20px;
}

.subContent {
    padding: 20px;
    background: #fafafa;
}

.creation .uploadDiv {
    border: 1px dashed #989b9c;
    border-radius: 3px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.creation .uploadDiv div {
    text-align: center;
}

.creation .uploadDiv div i {
    color: #767676;
    font-size: 30px;
}

.creation .uploadDiv .btn {
    margin-left: 0;
    margin-top: 10px;
}

table td.borderNone,
.table > tbody > tr > td.borderNone,
.table > thead > tr > th.borderNone,
.table > thead > tr.borderNone {
    border: none;
}

table td.borderNone,
.table > tbody > tr > td.borderTop1pxGrey {
    border-top: 1px solid #d8d8d8;
}

@media screen and (max-width: 767px) {
    .table-responsive {
        border: none;
    }

    .table-responsive > .table > thead > tr > th,
    .table-responsive > .table > thead > tr > td,
    .table-responsive > .table > tbody > tr > th,
    .table-responsive > .table > tbody > tr > td,
    .table-responsive > .table > tfoot > tr > th,
    .table-responsive > .table > tfoot > tr > td {
        white-space: normal;
    }

    .table-responsive > .table > thead > tr > th.whiteSpaceNowrap,
    .table-responsive > .table > thead > tr > td.whiteSpaceNowrap,
    .table-responsive > .table > tbody > tr > th.whiteSpaceNowrap,
    .table-responsive > .table > tbody > tr > td.whiteSpaceNowrap,
    .table-responsive > .table > tfoot > tr > th.whiteSpaceNowrap,
    .table-responsive > .table > tfoot > tr > td.whiteSpaceNowrap {
        white-space: nowrap;
    }

    .table-responsive.creationMethod {
        margin-bottom: 0;
    }
}

@media (min-width: 992px) {
    .table-responsive.creation {
        margin-bottom: 10px;
    }
}

@media (max-width: 991px) {
    .table-responsive.creation {
        margin-bottom: 30px;
    }

    .creation .uploadDiv .btn {
        margin-top: 10px;
    }

    .creation div:first-child > .uploadDiv .btn {
        text-align: center;
    }
}

th {
    white-space: nowrap;
}

.course-table-responsive,
.module-activities-view {
    .table-responsive {
        overflow-x: inherit;
    }
}

thead {
    tr:last-child th {
        padding-top: 6px;
        padding-bottom: 6px;
        // font-size: 10px;
        font-size: 0.88rem;
    }

    &.subContent-header {
        tr th {
            padding: 15px 20px;
            font-size: 1rem;
        }
    }
}

.isStudent {
    table,
    .table,
    .subTable {
        font-size: 1em;

        thead {
            tr {
                &:first-child {
                    th {
                        font-size: 1.333rem;

                        span {
                            font-size: 1.112rem;
                        }
                    }
                }

                &:last-child {
                    th {
                        font-size: 0.8888rem;
                    }
                }
            }
        }

        td {
            font-size: 1rem;
        }
    }
}

.overflowXinherit {
    .table-responsive {
        overflow-x: inherit;
    }
}

caption {
    font-weight: bold;
    color: #222;
    font-size: 1rem;
}

.tableBodyFixedHeight-attendance {
    width: 100%;

    .attendance-new-table {
        border: none;
        height: auto;
        box-shadow: none;

        .subHeader {
            padding: 10px 20px;
            color: #8b8b8f;
            background: #efefef;
        }

        .leftRightWidth {
            // width: calc((100% - 132px) / 2);
            // max-width: calc((100% - 132px) / 2);
            width: 50%;
            max-width: 50%;
        }
    }

    table {
        border-collapse: collapse;
        width: 100%;

        thead {
            th {
                position: sticky;
                top: 0;
                z-index: 9;
            }
        }

        tbody {
            tr {
                th,
                td {
                    padding: 8px 20px;
                    border: 0;
                }
            }
        }

        &.table {
            & > thead {
                & > tr {
                    & > th {
                        background: #fff;
                        border: 0;
                        // border-bottom: solid 1px #d8d8d8;
                    }
                }
            }
        }
    }
}
.tableBodyFixedHeight-lti-course-list {
    thead,
    tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    tbody {
        display: block;
        max-height: 400px;
        overflow-y: auto;
    }

    .selectAllFixedWidth {
        width: 132px;
        max-width: 132px;
    }
}

.table-layout-fixed {
    table-layout: fixed;
}
