
.addStudentsDiv:hover {
    cursor: pointer;
}
.profile_pic {
    width: auto;
    float: none;
    text-align: center;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-weight: normal;
}
::-moz-placeholder {
    /* Firefox 19+ */
    font-weight: normal;
}
:-ms-input-placeholder {
    /* IE 10+ */
    font-weight: normal;
}
:-moz-placeholder {
    /* Firefox 18- */
    font-weight: normal;
}

.student_name {
    max-width: 130px;
    word-wrap: break-word;
}
.vertical6DotsButton {
    padding: 0;
    border-radius: 0;

    &.paddingTop5 {
        padding-top: 5px;
    }

    &.disabled,
    &:disabled {
        cursor: not-allowed;
        color: #c5d0de;
    }
}

.z-index-10000 {
    z-index: 10000;
}

.z-index-1 {
    z-index: 1;
}
