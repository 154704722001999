
.x_panel {
    padding: 0;
    border-radius: 3px;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    background-color: #ffffff;
    border: solid 1px #d8d8d8;
    padding-left: 0px;
    margin-bottom: 20px;
}

.x_panel:first-child {
    padding-left: 0;
}

.x_panel:last-child,
.marginBottom0 .x_panel {
    margin-bottom: 0;
}

.x_title {
    padding: 15px 20px;
    margin: 0;
    // font-size: 16px;
    font-size: 0.889rem;
    font-weight: bold;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    border-bottom: solid 1px #d8d8d8;
    display: flex;
    align-items: center;
    line-height: 1;
    min-height: 67px;

    .dropdown {
        &.open #viewDropdownMenuButton {
            margin-bottom: 0;
        }

        button {
            span {
                color: #222;
            }
        }
    }

    .caret {
        color: #fff;
    }

    h1,
    h2 {
        font-size: 0.88rem;
        margin: 0;
    }
}

.x_title p {
    margin: 0;
    font-weight: normal;
}

.x_content {
    padding: 15px 20px;
    position: inherit;
    float: none;
    margin-top: 0;
}

.cardsCon .x_content {
    padding: 20px 20px 0 0;
}

#lmsIntegration .x_content {
    padding: 0;
}

.questionIndex .x_title {
    line-height: 2.3;
}

.pull-right {
    position: absolute;
    right: 20px;
    font-size: 1rem;
}

.pull-right a {
    color: #222;
}

.krpanelToggle {
    width: calc(100% - 48px);
}

.new-panel {
    .x_content {
        padding: 0;
    }
}

@media (min-width: 992px) {
    .questionIndexPanel .x_panel {
        margin-bottom: 0;
    }
}

@media (min-width: 992px) {
    .dashboardCols .x_panel {
        margin-bottom: 0;
    }
}
