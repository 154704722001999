
#test_confirm_modal .modal-body .message {
    text-align: center;
}
#test_confirm_modal .modal-body .message .fa {
    font-size: 40px;
    color: #e69618;
}
.individual {
    padding: 91px 25px 95px !important;
}

@media (min-width: 992px) {
    .timer.individual {
        padding: 130px 25px 95px !important;
    }

    .test .evaluation.right-container {
        padding: 66px 25px 78px !important;
    }
}

@media (max-width: 991px) {
    .timer.individual {
        padding: 103px 25px 95px !important;
    }

    .test .evaluation.right-container {
        padding: 66px 25px 78px !important;
    }
}
.modal-countdown-number::after {
    content: 'Redirecting';
    width: 1ch;
    animation: countdown 3s step-end;
}

@keyframes countdown {
    /*0% {
    content: "10s";
  }
  10% {
    content: "9s";
  }
  20% {
    content: "8s";
  }
  30% {
    content: "7s";
  }
  40% {
    content: "6s";
  }
  0% {
    content: "5s";
  }
  20% {
    content: "4s";
  }*/
    0% {
        content: 'Redirecting in 3s';
    }
    33% {
        content: 'Redirecting in 2s';
    }
    67% {
        content: 'Redirecting in 1s';
    }
    100% {
        content: '0';
    }
}
