
.profile_image.marginRight5 {
    margin-right: 5px;
}
.exTab .nav-pills > li > a {
    width: 250px;
    border-radius: 0;
    padding: 20px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom: 1px solid #fff;
    border: solid 1px #d8d8d8;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    color: #222;
}

.exTab .nav-pills > li.active > a {
    border: 1px solid #1991eb;
    background-image: none;
    background: #fff;
    border-bottom: none;
    border-top: 3px solid #1991eb;
}

.exTab .tab-content {
    padding: 20px;
    border: 1px solid #1991eb;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background: #fff;
}

.panel-heading.collapsed .fa-chevron-down,
.panel-heading .fa-chevron-right {
    display: none;
}

.panel-heading.collapsed .fa-chevron-right,
.panel-heading .fa-chevron-down {
    display: inline-block;
}

.greyColor {
    color: grey;
}

.badge_profile {
    font-size: 12px;
}
