
.questionFilterColumn > div {
    margin-bottom: 20px;
}

.questionFilterColumn > div:last-child {
    margin-bottom: 0;
}

.questionFilterColumn .btn {
    margin-left: 0;
    width: 100%;
}

.questionFilterColumn .btn:first-child {
    margin-bottom: 10px;
}

.questionIndexPanel .paginationCons {
    margin-top: 30px;
}

.flexSpaceBetween > button {
    border: none;
    padding: none;
}

.flexSpaceBetween > button .fa {
    color: #767676;
}

@media (min-width: 992px) {
    .questionIndexPanel {
        padding-left: 20px;
    }

    .flexSpaceBetween > button {
        border: 1px solid #d8d8d8;
        text-align: center;
        padding: 10px 20px;
        line-height: 1;
        border-radius: 3px;
    }

    .flexSpaceBetween > button .fa {
        font-size: 12px;
    }

    .table-responsive.creation {
        margin-bottom: 0;
    }
}

.questionList {
    margin-bottom: 30px;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
}

.questionList:last-child {
    margin-bottom: 0;
}

.questionList .header {
    padding: 15px;
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #d8d8d8;
}

.questionList .content {
    padding: 20px 25px;
}

.managementQns .lefts {
    background-color: #f6f8fa;
}

.managementQns .center,
.managementQns .right {
    background-color: #fff;
}

.managementQns .center .header {
    background: none;
    text-align: left;
    color: #767676;
    border-bottom: 0;
    font-size: 12px;
    padding: 0;
}

.managementQns .center .content {
    padding: 10px 0 15px;
    line-height: 1.29;
}

.managementQns .center .footer {
    color: #767676;
    font-size: 12px;
}

@media (min-width: 992px) {
    .managementQns .lefts:first-child {
        border: 1px solid #d8d8d8;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
        padding: 15px;
    }

    .managementQns .lefts {
        width: 30%;
        max-width: 150px;
        padding: 15px;
        border-top: 1px solid #d8d8d8;
        border-bottom: 1px solid #d8d8d8;
    }

    .managementQns .lefts .form-dropdown {
        min-width: auto;
    }

    .managementQns .center {
        min-width: 288px;
        width: 100%;
        border: 1px solid #d8d8d8;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
        padding: 15px;
    }

    .managementQns .right {
        border: 1px solid #d8d8d8;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
        padding: 15px;
    }

    .managementQns .center .footer div {
        margin-left: 20px;
    }

    .managementQns .center .footer div:first-child {
        margin-left: 0;
    }

    .managementQns {
        margin-top: 20px;
    }

    .managementQns:first-child {
        margin-top: 0;
    }
}

@media (max-width: 991px) {
    .managementQns .lefts {
        padding: 10px 20px;
    }

    .managementQns .center {
        padding: 20px;
    }

    .managementQns .right {
        padding: 0 20px 20px;
    }

    .managementQns .center .footer div {
        margin-top: 10px;
    }

    .managementQns .center .footer div:first-child {
        margin-top: 0;
    }
}
.selected-top {
    border-top: 2px solid blue;
}
.selected-bottom {
    border-bottom: 2px solid blue;
}

.qnsOptions {
    margin-top: 20px;
}

.qnsOptions.correctOption {
    margin-top: 15px;
}

.qnsOptions:first-child,
.qnsOptions.correctOption:first-child {
    margin-top: 0;
}

.optionKey {
    font-weight: bold;
}

.correctOption {
    padding: 10px;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #f3fffd;
    border: 1px solid #0f9b81;
}

.correctOption i {
    color: #0f9b81;
}

.height550px {
    max-height: 550px;
    overflow: auto;
}
