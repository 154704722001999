
// a.disabled {
//   opacity: 0.3;
// }

.newPagination {
    a {
        padding: 12px 16.45px;
        height: auto;
        width: auto;
        line-height: 1;
        border: none;
        background: none;

        &.link {
            color: #0071eb;
        }

        &:hover {
            line-height: 1;
            color: #0000ff;
            font-weight: bold;
            text-decoration: underline;
            background: #e7dcf9;
        }

        &:active {
            color: #ff00ff;
            background: none;
        }

        // &:visited {
        //   text-decoration-color: #9331C4;
        //   background: none;
        // }

        &.disabled {
            color: #8b8b8f;
        }
    }

    li {
        &.first.last {
            a {
                border-radius: 8px;
            }
        }
    }

    .active {
        a {
            box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.24);
            border: none;
            background: #fff;

            &.disabled {
                color: #3c3c43;
            }
        }

        &.first {
            a {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &.last {
            a {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }
        }
    }
}

.borderRadius8 {
    border-radius: 8px;
}
