
.dropdown-menu {
    margin-bottom: 0;
}
#eGalleryResetModal .modal-body .row:last-child {
    margin-top: 30px;
}

#eGalleryResetModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#eGalleryResetModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}

.responseFont {
    margin-top: 8px;
    margin-right: 10px;
    font-weight: bold;
}

.discussion-buttons .btn {
    margin-left: 0;
    margin-right: 20px;
}

.with-border {
    border: 1px solid #d8d8d8;
    padding: 10px 25px;
    border-radius: 5px;
}

.with-border .flexRadio {
    margin-left: 15px;
    margin-top: 10px;
}

.with-border .flexRadio:first-child {
    margin-top: 0;
}

.steps-circle {
    background: #cfe2f3;
    font-weight: bold;
    border-radius: 50%;
    min-width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px;
    margin-right: 20px;
}

.steps-circle:after {
    content: '';
    position: absolute;
    width: 2px;
    height: calc(100% - 30rem);
    left: 3.4rem;
    top: 17rem;
    background: #495060;
}

.presentation-mode-cr .eGalleryQuestionGroup .panel-heading {
    background: #fff;
}

.cr-dropdowns {
    position: relative;
    display: inline-flex;
    align-items: center;

    .dropdown-menu {
        padding: 0;
        background: #495060;
        border: none;
        left: inherit;
        right: 0;
        text-transform: none;
    }

    .btn-primary,
    .btn-primary:visited {
        background: none;
        color: #1991eb;
        box-shadow: none;
    }

    .dropdown-submenu {
        position: relative;
    }

    .dropdown-submenu > .dropdown-menu {
        top: 0;
        right: 100%;
        border-radius: 6px;
        min-width: 172px;
    }

    .dropdown-submenu:hover > .dropdown-menu {
        display: block;
    }

    .dropdown-submenu > a:after {
        display: block;
        content: ' ';
        float: right;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 5px 0 5px 5px;
        border-left-color: #ccc;
        margin-top: 5px;
        margin-right: -10px;
    }

    .dropdown-submenu:hover > a:after {
        border-left-color: #fff;
    }

    .dropdown-submenu.pull-left {
        float: none;
    }

    .dropdown-submenu.pull-left > .dropdown-menu {
        left: -100%;
        margin-left: 10px;
        -webkit-border-radius: 6px 0 6px 6px;
        -moz-border-radius: 6px 0 6px 6px;
        border-radius: 6px 0 6px 6px;
    }

    .checkbox {
        margin: inherit;
        // padding-top: 10px;
        // padding-bottom: 10px;

        input[type='checkbox'] {
            margin: 0;
            position: inherit;

            & ~ label {
                i:first-child {
                    color: transparent;
                }

                div:first-child {
                    width: calc(100% - 16px);
                }
            }

            &:checked ~ label i:first-child {
                color: #fff;
            }
        }

        label {
            min-height: inherit;
            padding: 10px;
            margin-left: 0;
            white-space: nowrap;
        }
    }

    li > div:hover {
        background: rgba(255, 163, 0, 0.5);
    }

    .dropdown-submenu input[type='checkbox'] ~ label div:last-child {
        width: 16px;
    }

    .dropdown-submenu input[type='checkbox'] ~ label div:last-child i {
        color: #fff;
    }

    // .dropdown-submenu .dropdown-menu label {
    //   width: 128px;
    // }

    .dropdown-submenu > div > label {
        min-width: 180px;
        justify-content: space-between;
        padding-right: 10px;
    }

    .selection-dropdown-checked {
        margin-left: 10px;
        font-size: 1rem;
        color: #fff;
        width: 100%;
    }

    .dropdown-menu .divider {
        margin: 0;
    }
}

/* .cr-dropdowns .dropdown-menu.multi-level {
  padding: 5px 0;
} */

.margin-left-25px {
    margin-left: 25px;
}

@media (min-width: 992px) {
    .eGalleryQuestionGroup .panel {
        box-shadow: none;
    }
}

.night-mode .panel-body {
    background: #273142;
    color: #fff;
}

.numberInput {
    width: 58px;
    position: relative;

    .quantity-nav {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 10px;
        color: #222;
        z-index: 4;
        height: 100%;
        max-height: 100%;
    }

    .quantity-nav .disabled {
        color: #dfe3e9;
        cursor: not-allowed;
    }

    .quantity-button {
        min-height: 18px;
        height: 50%;
        display: flex;
        align-items: center;
        width: 22px;
        justify-content: center;
        background-color: white !important;
        // border: 1px solid #dfe3e9 !important;
        border: 1px solid #767676 !important;
    }

    .quantity-button:last-child {
        // border: 1px solid #dfe3e9 !important;
        border-top: 1px solid #767676 !important;
    }
}

.timer_Div {
    .time .number-div {
        background: #fff;
        padding: 6px;
        border-radius: 3px;
        font-size: 28px;
        font-weight: bold;
        line-height: 0.8;
    }

    .time .number-div:last-child {
        margin-left: 5px;
    }

    .colon {
        color: #fff;
        padding: 0 10px;
    }

    .timeText {
        font-size: 1rem;
        margin-bottom: 5px;
    }
}

.radioField {
    label {
        width: calc(100% - 20px);
        line-height: 1.2;
    }

    input {
        display: block;
        margin: 0;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        position: relative;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        box-shadow: inset 0 0 0 2px #fff;
        border: solid 2px #222;
        background: #fff;
        margin-right: 5px;

        &:hover {
            border: 2px solid #1991eb;
        }

        &:checked {
            border-color: #1991eb;
            box-shadow: inset 0 0 0 3px #fff;
            background: #1991eb;

            &:before {
                content: inherit;
            }
        }
    }
}
