
.LMSRow {
    padding: 20px;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    border: 1px solid #d8d8d8;
    border-bottom: none;

    &:last-child {
        border-bottom: 1px solid #d8d8d8;
    }

    &:first-of-type {
        border: 1px solid #d8d8d8;
    }
}
.LMSTitle {
    font-weight: bold;
}

@media (min-width: 992px) {
    .LMSSubCon {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .LMSContainer {
        display: inline-flex;
        width: 73%;
    }

    .LMSTitle {
        // width: 150px;
        min-width: 9em;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }

    .LMSContent {
        margin-left: 20px;
        width: 22.5em;
        word-wrap: break-word;
    }

    .LMSBtn {
        width: 27%;
        text-align: right;
        padding-left: 10px;
    }
}

@media (max-width: 991px) {
    .LMSSubCon {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: relative;
    }

    .LMSContainer {
        width: 100%;
        overflow: hidden;
    }

    .LMSBtn {
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        background-image: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0.5));
        width: 100px;
        justify-content: flex-end;
        height: 100%;
    }

    .LMSBtn .hidden-md.hidden-lg {
        display: flex;
    }

    .LMSBtn .hidden-md.hidden-lg .mainBtn {
        margin-left: 0;
    }
}
.enrollmentSetting {
    // width: 75%;
    display: flex;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        width: 100%;

        & > div:last-child {
            width: calc(100% - 70px);
        }
    }

    &:not(first-child) {
        margin-top: 20px;
    }

    p {
        margin-bottom: 8px;
    }

    .LMSTitle {
        label {
            // font-size: 14px;
            font-size: 0.875em;
            margin: 0;
        }
    }

    .LMSContent {
        p {
            margin: 0;
        }
    }
}

.w-auto {
    width: auto;
}
