
.removeFlex > div {
    display: contents;
}

.removeFlex,
.removeFlex ~ div {
    margin-left: 20px;
}

.LMSRow {
    padding: 20px;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    // border: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;

    &:last-child {
        // border-bottom: 1px solid #d8d8d8;
        border-bottom: none;
    }

    // &:first-of-type {
    //   border: 1px solid #d8d8d8;
    // }
}

#signin {
    .LMSRow {
        border-left: 1px solid #d8d8d8;
        border-right: 1px solid #d8d8d8;
        border-bottom: 1px solid #d8d8d8;

        &:only-child,
        &:first-child {
            border-top: 1px solid #d8d8d8;
        }
    }
}

.LMSTitle {
    font-weight: bold;
}

// #viewDropdownMenuButton {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }

// .viewDropdown {
//   width: 100%;
//   display: flex;
//   justify-content: flex-end;
// }

.card-container {
    padding: 0 20px;
}

.card-body {
    padding-bottom: 20px;
}

.card-container .btn {
    margin-left: 0;
}

.card-body > .col-xs-12:last-child > .form-control-static:last-child {
    margin-bottom: 0;
}

.card-container .mainModulesBtn,
.card-container .mainActivityBtn,
#courseOverview .x_content .mainCourseBtn {
    margin-bottom: 20px;
}

.card-body .btn.subBtn {
    margin-bottom: 20px;
}

#courseOverview .x_content .btn-custom {
    background-color: #f6f8fa;
    border: solid 1px #d8d8d8;
    border-radius: 3px;
    box-shadow: 2px 2px 2px 0 rgba(202, 202, 202, 0.5);
    color: #767676;
    font-weight: bold;
}

#courseOverview .x_content .btn-custom .fa-stack {
    // font-size: 8px;
    font-size: 0.5em;
}

#courseOverview .x_content .btn-custom .fa-circle {
    color: #d8d8d8;
}

#courseOverview .x_content .btn-custom:active,
#courseOverview .x_content .btn-custom:hover,
#courseOverview .x_content .btn-custom:visited,
#courseOverview .x_content .btn-custom:focus,
#courseOverview .x_content .btn-custom:active:hover,
#courseOverview .x_content .btn-custom:active:focus {
    border: solid 1px #1d83c7;
    background-color: rgba(255, 255, 255, 0.05);
    color: #222222;
    font-weight: bold;
}

#courseOverview .x_content .btn-custom:active .fa,
#courseOverview .x_content .btn-custom:hover .fa,
#courseOverview .x_content .btn-custom:visited .fa,
#courseOverview .x_content .btn-custom:focus .fa,
#courseOverview .x_content .btn-custom:active:hover .fa,
#courseOverview .x_content .btn-custom:active:focus .fa,
#courseOverview .x_content .btn-custom:active .far,
#courseOverview .x_content .btn-custom:hover .far,
#courseOverview .x_content .btn-custom:visited .far,
#courseOverview .x_content .btn-custom:focus .far,
#courseOverview .x_content .btn-custom:active:hover .far,
#courseOverview .x_content .btn-custom:active:focus .far,
#courseOverview .x_content .btn-custom:active .fas,
#courseOverview .x_content .btn-custom:hover .fas,
#courseOverview .x_content .btn-custom:visited .fas,
#courseOverview .x_content .btn-custom:focus .fas,
#courseOverview .x_content .btn-custom:active:hover .fas,
#courseOverview .x_content .btn-custom:active:focus .fas {
    color: #1991eb;
}

@media (min-width: 992px) {
    .LMSSubCon {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .LMSContainer {
        display: inline-flex;
    }

    .LMSTitle {
        width: 150px;
        display: flex;
        justify-content: space-between;
    }

    .LMSContent {
        margin-left: 20px;
    }
}

@media (max-width: 991px) {
    .LMSSubCon {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: relative;
    }

    .LMSContainer {
        width: 100%;
        overflow: hidden;
    }

    .LMSBtn {
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        background-image: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0.5));
        width: 100px;
        justify-content: flex-end;
        height: 100%;
    }

    .LMSBtn .hidden-md.hidden-lg {
        display: flex;
    }

    .LMSBtn .hidden-md.hidden-lg .mainBtn {
        margin-left: 0;
    }
}
.subTable {
    margin-top: 20px;

    &:first-child {
        margin-top: 0;
    }

    th {
        position: relative;

        button {
            right: 20px;
            padding: 0;
            font-size: 0.6rem;
            border-radius: 2px;

            .fa.fa-sort {
                margin-left: 0;
            }
        }
    }
}
.btn-grey {
    border-radius: 3px;
    border: 1px solid #d8d8d8;
    background: #fff;
    color: #767676;
    display: inline-block;
    padding: 6px 12px;
    // font-size: 14px;
    font-size: 0.875em;
    line-height: 1.2;
}

.subTable .fa-ellipsis-v {
    color: #767676;
    // font-size: 12px;
    font-size: 0.75em;
}

.blueTitle {
    margin-left: 20px;
    color: #1991eb;
    display: inline-block;
    // font-size: 14px;
    font-size: 0.875em;
    text-transform: capitalize;
}

.activityHeaders th {
    padding-top: 6px;
    padding-bottom: 4px;
    // font-size: 10px;
    font-size: 0.7rem;
    color: #767676;

    i {
        color: #767676;
    }
}

.subContentDetail {
    border-radius: 3px;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    border: solid 1px #d8d8d8;
    background-color: #ffffff;
    margin-bottom: 20px;
}

.subContentDetail:last-child {
    margin: 0;
}

.subContentDetail .header {
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
}

.subContentHeader {
    padding: 20px;
    font-weight: bold;
    background: #fbfbfb;
    border-bottom: 1px solid #d8d8d8;
    text-transform: uppercase;
}

.subContentDetails {
    padding: 20px;
}

.table .table.marginBottom20 {
    margin-bottom: 20px;
}

#deleteActivityModal .modal-body .row:last-child {
    margin-top: 30px;
}

#deleteActivityModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#deleteActivityModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}
#unpublishTestModal .modal-body .row:last-child {
    margin-top: 30px;
}

#unpublishTestModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#unpublishTestModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}

#copyActivityModal .modal-body .row:last-child {
    margin-top: 30px;
}

#copyActivityModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#copyActivityModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}

.subContent {
    padding: 0;
    border: 0;
    background: none;
}

.table .subContentTable .table {
    margin-bottom: 20px;
}

.subContentTable:last-child .table {
    margin-bottom: 0;
}

.table .subContentTable tbody .table {
    margin-bottom: 0;
}

ul.nav-pills {
    width: 100%;
    display: flex;
    justify-content: center;
}

ul.nav-pills li {
    background-color: #f2f2f2;
    border: solid 1px #d8d8d8;
    margin-left: 0px;
    border-radius: 4px;
}

ul.nav-pills li a {
    padding: 6px 15px 6px;
}

ul.nav-pills > li:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

ul.nav-pills > li:not(:first-child):not(:last-child) {
    border-radius: 0;
}

ul.nav-pills > li:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

ul.nav-pills > li.active {
    border: solid 1px #1991eb;
    box-shadow: 2px 2px 2px 0 rgba(202, 202, 202, 0.5);
}

ul.nav-pills > li.active,
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
    color: black;
    background-color: white;
}

#emailGradesConfirmationModal .modal-body .row:last-child {
    margin-top: 30px;
}
#emailGradesConfirmationModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#emailGradesConfirmationModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}

#deleteReportModal .modal-body .row:last-child {
    margin-top: 30px;
}
#deleteReportModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}

#deleteReportModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}

// .checkboxField input[type="checkbox"]:disabled {
//   opacity: 0.3;
// }

.new-panel {
    .x_content {
        .x_side_bar {
            width: 10%;
            height: auto;
            background: #eff2f6;
            border-right: 1px solid #ddd;
            padding: 10px;

            label {
                padding: 4px 9px;
            }

            .list-group {
                margin-bottom: 0;

                .list-group-item {
                    padding: 8px;
                    line-height: 0.8;
                    background: none;
                    border: none;
                    border-left: 2px solid transparent;
                    color: #333;

                    &:first-child,
                    &:last-child {
                        border-radius: 0;
                    }

                    &.active,
                    &:hover,
                    &:active,
                    &:focus {
                        border-left: 2px solid #3399ff;
                        // background: rgba(51, 153, 255, 0.3);
                        background: #fff;
                        color: #3399ff;
                    }
                }
            }
        }

        .x_with_side_bar {
            width: calc(100% - 10%);
            padding: 1.5rem;

            &.col-12 {
                width: 100%;
            }
        }
    }
}
.activityTable {
    border-top: 0;
}

.selected-top {
    border-top: 2px solid blue;
}

.selected-bottom {
    border-bottom: 2px solid blue;
}

.moduleActionHeader {
    width: 70px;
}

.modal-body {
    .row {
        &:not(:first-child) {
            margin-top: 20px;
        }

        .col-md-6 {
            padding-right: 10px;

            &:last-child {
                padding-right: 0;
                padding-left: 10px;
            }
        }
    }
}
.enrollmentSetting {
    //   width: 75%;
    display: flex;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        width: 100%;

        & > div:last-child {
            width: calc(100% - 70px);
        }
    }

    &:not(first-child) {
        margin-top: 20px;
    }

    label {
        font-size: 24px;
        margin-bottom: 8px;
    }

    p {
        margin-bottom: 8px;
    }

    .LMSTitle {
        label {
            // font-size: 14px;
            font-size: 0.875em;
            margin: 0;
        }
    }

    .LMSContent {
        p {
            margin: 0;
        }
    }
}
