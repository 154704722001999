
.tablewDiv .table {
    margin-bottom: 0;
    position: relative;
}

.tablewDiv .tableDiv {
    overflow-x: auto;
    height: max-content;
}
.tablewDiv .tableDiv .table {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
}

.tablewDiv .tableDiv1 .table {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 2px 0 3px 0 rgba(174, 174, 174, 0.5);
}

.tablewDiv .table650px .table {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: -2px 0 3px 0 rgba(174, 174, 174, 0.5);
}

.tagCorrect {
    background-color: #0f9b81;
    border-radius: 3px;
    color: #fff;
}

.statusTag.tagCorrect i {
    font-size: 12px;
}

.tableDiv1 {
    width: 235px;
}

.table650px {
    width: 650px;
}

.tablewDiv .tableDiv {
    width: calc(100% - 885px);
    min-width: 200px;
}

.tableDiv1 .table > thead > tr > th,
.tableDiv1 .table > thead > tr > td,
.tableDiv1 .table > tbody > tr > th,
.tableDiv1 .table > tbody > tr > td,
.tableDiv1 .table > tfoot > tr > th,
.tableDiv1 .table > tfoot > tr > th,
.table650px .table > thead > tr > th,
.table650px .table > thead > tr > td,
.table650px .table > tbody > tr > th,
.table650px .table > tbody > tr > td,
.table650px .table > tfoot > tr > th,
.table650px .table > tfoot > tr > th,
.tableDiv .table > thead > tr > th,
.tableDiv .table > thead > tr > td,
.tableDiv .table > tbody > tr > th,
.tableDiv .table > tbody > tr > td,
.tableDiv .table > tfoot > tr > th,
.tableDiv .table > tfoot > tr > th {
    padding: 15px;
}
thead tr.sticky th {
    position: sticky;
    top: 0;
    z-index: 30;
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
}
table {
    border-top: 0;
}
#header-fixed {
    position: relative;
    top: 0px;
    display: none;
    background-color: white;
    z-index: 31;
    overflow-x: auto;
    border: none;
}
#tableDivOG::-webkit-scrollbar {
    width: 0px;
    height: 0;
    background: transparent;
}

.tablewDiv .tableDiv1 .table {
    border-left: 0;
    box-shadow: none;
    border-bottom: 0;
}

.tablewDiv .tableDiv .table {
    box-shadow: none;
    border-bottom: 0;
}

.tablewDiv .table650px .table {
    box-shadow: none;
    border-bottom: 0;
}

#itemAnalysisIRAT {
    border: 1px solid #ddd;
    border-radius: 3px;
}
/*
.mobile-table-overflowHidden {
	overflow:hidden;
	overflow-x:auto;
}*/

/* new table */

.new-table-container {
    table {
        border: 0;

        thead {
            position: sticky;
            top: 0;

            th {
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
            }

            &:first-child {
                & > tr:first-child th {
                    border-top: inherit;
                }
            }
        }

        tbody {
            tr:first-child {
                td {
                    border-top: 0;
                }
            }
        }
    }

    .new-left-table {
        min-width: 240px;
        width: 240px;
        max-height: 500px;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .new-center-table {
        min-width: calc(100% - 240px - 570px);
        width: calc(100% - 240px - 570px);
        max-height: 500px;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .new-right-table {
        width: 570px;
        max-height: 500px;
        scrollbar-width: none;

        // &::-webkit-scrollbar {
        //     height: 0px;
        //     width: 16px;
        //     background-color: #fff;
        // }

        // &::-webkit-scrollbar-track {
        //     border-radius: 0;
        //     background-color: #fff;
        // }

        // &::-webkit-scrollbar-thumb {
        //     background-color: #babac0;
        //     border-radius: 16px;
        //     border: 4px solid #fff;
        // }

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            height: 0;
            width: 5px;
            background: #d8d8d8;
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background-color: #989b9c;
            border-radius: 2px;
        }
    }

    .new-left-table-scrollbar {
        width: 240px;
    }

    .new-center-table-scrollbar {
        width: calc(100% - 240px - 570px);
    }

    .new-right-table-scrollbar {
        width: 570px;
    }

    .scrollable-table-wrapper {
        border-radius: 3px;
        overflow: hidden;

        &:first-child,
        &:last-child {
            .new-left-table-scrollbar,
            .new-center-table-scrollbar,
            .new-right-table-scrollbar {
                // border-bottom: 0;
                border: 0;
            }
        }

        // &:last-child {
        //   .new-left-table-scrollbar,
        //   .new-center-table-scrollbar,
        //   .new-right-table-scrollbar {
        //     border-top: 0;
        //   }
        // }

        & > div {
            border: 1px solid #ddd;
            overflow: auto;

            &:not(:first-child) {
                border-left: 0;
            }
        }
    }
}
