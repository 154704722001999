/*Default*/
.vue-notification {
    padding: 10px;
    margin: 0 5px 5px;

    font-size: 15px;

    color: #ffffff;
    background: #44a4fc;
    border-left: 5px solid #187fe7;

    &.warn {
        background: #ffb648;
        border-left-color: #f48a06;
    }

    &.error {
        background: #e54d42;
        border-left-color: #b82e24;
    }

    &.success {
        background: #ffffff;
        border-left-color: #42a85f;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            padding: 0;
        }
    }
}
/*Profile Notification*/
.profile-update-notification {
    // Style of the notification itself
    position: relative;
    padding: 10px;
    margin: 0 5px 5px;

    font-size: 16px;

    color: #222222;
    background: #ffffff;
    border-left: 5px solid #2192dd;
    -webkit-box-shadow: -5px 5px 5px 0px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: -5px 5px 5px 0px rgba(0, 0, 0, 0.18);
    box-shadow: -5px 5px 5px 0px rgba(0, 0, 0, 0.18);

    .notification-img {
        color: #ffffff;
        width: 24px;
        margin-right: 5px;
    }
    .notification-title {
    }

    .notification-content {
    }
    a.close {
        position: absolute;
        top: 0px;
        right: 0px;
        color: #767676;
    }
}
