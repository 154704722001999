
.test-listing-row.selected {
    background-color: rgba(0, 255, 0, 0.3);
}
.btn.selected {
    background-color: yellow !important;
    color: black !important;
}

.new-studentTest-index-table-container {
    min-height: calc(100vh - 100px);
    .new-studentTest-index-table {
        margin-bottom: 30px;
        overflow: auto;
    }
}

// @media (min-width: 992px) {
//   .btn {
//     margin-left: 0;
//   }
// }

.studentIndexGrid {
    // display: grid;
    // grid-template-columns: 317px 133px;
    // grid-template-columns: 100px 217px 133px;
    // text-align: right;
    display: flex;
    // justify-content: end;
    align-items: center;

    .btn {
        flex: auto;
    }
}

// @media (max-width: 992px) {
//   .studentIndexGrid {
//     grid-template-columns: 121px 130px;
//   }
// }

.marginLeft32px {
    margin-left: 32px;
}
.marginRight32px {
    margin-right: 32px;
}
