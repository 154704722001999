
.input-group.date {
    margin-bottom: 0;
}

.width117px {
    width: 117px;
}

// .dropdown {
//   .dropdown-menu {
//     box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
//     border-radius: 3px;
//     margin-top: 10px;
//   }
// }

// .dropup {
//   .dropdown-menu {
//     box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
//     border-radius: 3px;
//     margin-bottom: 10px;
//     margin-top: 0;
//   }
// }

#earlyStartDurationInMinutes-div {
    width: fit-content;
}

.modifyNumberGroup {
    display: inline-flex;

    &:focus-within {
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
        border-radius: 3px;
    }

    .numberInput {
        position: relative;
        display: inline-block;
    }

    .form-control {
        outline: 0;
        outline-offset: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        width: 64px;

        &.disabled {
            border: none;
            &.hasTooltip {
                pointer-events: inherit;
            }
        }
    }

    .quantity-nav {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 10px;
        color: #222;
        z-index: 4;
        height: 100%;
        max-height: 100%;

        .quantity-button {
            min-height: 18px;
            height: 50%;
            display: flex;
            align-items: center;
            width: 22px;
            justify-content: center;
            border: 1px solid #767676;
            background-image: linear-gradient(to bottom, #ffffff, #eff2f6);

            &:last-child {
                border-top: 1px solid #767676;
            }

            &.disabled {
                background: #efefef;
                color: #8b8b8f;
                cursor: not-allowed;
            }
        }
    }
}
