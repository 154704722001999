
.tagCorrect {
    background-color: #0f9b81;
    border-radius: 3px;
    color: #fff;
}
.tagIncorrect {
    background-color: #dc3c42;
    border-radius: 3px;
    color: #fff;
}
.tagPrimary {
    background-color: #546ea9;
    border-radius: 3px;
    color: #fff;
}
.tagWarning {
    background-color: #e69b23;
    border-radius: 3px;
    color: #fff;
}

.statusTag.tagCorrect i {
    font-size: 12px;
}

.tagCorrectOutline {
    border: 1px solid #0f9b81;
    color: #0f9b81;
}

i.fa-trophy {
    display: inline-block;
    border-radius: 60px;
    padding: 0.5em;
    color: #fff;
    font-size: 8px;
}

.studentAnalysis .first {
    background-color: #fdfbf4;
}

.first i.fa-trophy {
    box-shadow: 0px 0px 2px #dab025;
    background: #facd36;
    border: 1px solid #dab025;
}

.studentAnalysis .second {
    background-color: #fbfbfb;
}

.second i.fa-trophy {
    box-shadow: 0px 0px 2px #a3a3a3;
    background: #dadada;
    border: 1px solid #a3a3a3;
}

.studentAnalysis .third {
    background-color: #fdf8f3;
}

.third i.fa-trophy {
    box-shadow: 0px 0px 2px #9c4317;
    background: #c6937a;
    border: 1px solid #9c4317;
}

.legend {
    padding: 15px;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
}

.legend div:first-child {
    // text-transform: uppercase;
    font-weight: bold;
}

.legend div:last-child div {
    display: flex;
    align-items: center;
    line-height: 0.8;
}

.legend .fas {
    font-size: 15px;
}

.fixedHeight69px {
    height: 69px;
}

.openended-space {
    width: 250px;
    height: 50px;
    overflow: hidden;
}

.tablewDiv .table {
    margin-bottom: 0;
    position: relative;
}

.tablewDiv .tableDiv-app {
    overflow-x: auto;
    height: max-content;
}
.tablewDiv .tableDiv-app .table {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
}

.tablewDiv .tableDiv-app1 .table {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 2px 0 3px 0 rgba(174, 174, 174, 0.5);
}

.tagCorrect {
    background-color: #0f9b81;
    border-radius: 3px;
    color: #fff;
}

.statusTag.tagCorrect i {
    font-size: 12px;
}

.tableDiv-app1 {
    min-width: 618px;
    width: 618px;
}

.tableDiv-app {
    width: calc(100% - 618px);
}

.tableDiv-app1 .table > thead > tr > th,
.tableDiv-app1 .table > thead > tr > td,
.tableDiv-app1 .table > tbody > tr > th,
.tableDiv-app1 .table > tbody > tr > td,
.tableDiv-app1 .table > tfoot > tr > th,
.tableDiv-app1 .table > tfoot > tr > th,
.tableDiv-app .table > thead > tr > th,
.tableDiv-app .table > thead > tr > td,
.tableDiv-app .table > tbody > tr > th,
.tableDiv-app .table > tbody > tr > td,
.tableDiv-app .table > tfoot > tr > th,
.tableDiv-app .table > tfoot > tr > th {
    padding: 15px;
}

thead tr.sticky th {
    position: sticky;
    top: 0;
    z-index: 30;
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
}

table {
    border-top: 0;
}

#header-fixed,
#teamAnalysisApp-clone-left-fixed {
    position: relative;
    top: 0px;
    display: none;
    background-color: white;
    z-index: 31;
    overflow-x: auto;
    border: none;
}

#teamAnalysisApp-clone-left-fixed {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-left: 0;
}

#teamAnalysisApp-clone-left {
    border-top-left-radius: 0;
    border-left: 0;
    border-bottom: 0;
    box-shadow: none;
}

#teamAnalysisApp-clone-right {
    border-right: 0;
    border-bottom: 0;
    box-shadow: none;
}

#teamAnalysisAppOverall {
    border: 1px solid #ddd;
    border-radius: 3px;
}

#teamAnalysisApp-tableOG::-webkit-scrollbar {
    width: 0px;
    height: 0;
    background: transparent;
}

.width112px {
    width: 112px;
}

.nameColumn {
    word-wrap: break-word;
    max-width: 112px;
}

/* new table */

.new-table-container-analysis-team {
    table {
        border: 0;

        thead {
            position: sticky;
            top: 52.84px;

            th {
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
            }

            &:first-child {
                & > tr:first-child th {
                    border-top: inherit;
                }
            }
        }

        tbody {
            tr:first-child {
                td {
                    border-top: 0;
                }
            }
        }
    }

    .sticky-top-header {
        position: sticky;
        top: 0;
        left: 0;
        padding: 15px;
        font-size: 16px;
        font-weight: bold;
        background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
        // text-transform: uppercase;
    }

    .new-left-table-application-team-analysis {
        min-width: 630px;
        width: 630px;
        max-height: 500px;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .new-right-table-application-team-analysis {
        width: calc(100% - 630px);
        max-height: 500px;
        scrollbar-width: none;

        .sticky-top-header {
            left: 0;
        }

        // &::-webkit-scrollbar {
        //     height: 0px;
        //     width: 16px;
        //     background-color: #fff;
        // }

        // &::-webkit-scrollbar-track {
        //     border-radius: 0;
        //     background-color: #fff;
        // }

        // &::-webkit-scrollbar-thumb {
        //     background-color: #babac0;
        //     border-radius: 16px;
        //     border: 4px solid #fff;
        // }
    }

    .new-left-table-scrollbar-application-team-analysis {
        width: 630px;
    }

    .new-right-table-scrollbar-application-team-analysis {
        width: calc(100% - 630px);
    }

    .scrollable-table-wrapper {
        border-radius: 3px;
        overflow: hidden;

        &:first-child,
        &:last-child {
            .new-left-table-scrollbar-application-team-analysis,
            .new-right-table-scrollbar-application-team-analysis {
                // border-bottom: 0;
                border: 0;
            }
        }

        // &:last-child {
        //   .new-left-table-scrollbar-application-team-analysis,
        //   .new-right-table-scrollbar-application-team-analysis {
        //     border-top: 0;
        //   }
        // }

        & > div {
            border: 1px solid #ddd;
            overflow: auto;

            &:not(:first-child) {
                border-left: 0;
            }
        }
    }
}

// .dropdown {
//   &.open {
//     #viewDropdownMenuButton {
//       margin-bottom: 0;
//     }
//   }

//   .dropdown-menu {
//     margin-top: 10px;
//   }
// }

select {
    width: 80px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    select {
        padding-right: 0;
    }
}
