
@media (min-width: 992px) {
    .mdFlex {
        display: flex;
    }

    .mdFlex .col-md-6:first-child {
        padding-right: 10px;
    }

    .mdFlex .col-md-6:last-child {
        padding-left: 10px;
    }
}
@media (max-width: 991px) {
    .mdFlex .col-md-6:last-child {
        margin-top: 30px;
    }
}

@media (min-width: 351px) {
    .payment-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .payment-section {
        width: 670px;
    }
}

.payment-container {
    max-width: 670px;
    background: #fff;
    border: solid 1px #dfdfdf;
    margin: 100px auto;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: -1px 1px 5px 0 rgba(202, 202, 202, 0.5);
}

.payment-logo-container {
    background-color: #495060;
    color: white;
    height: 95px;
    padding: 15px;
    text-align: center;
}

.payment-logo {
    width: 270px;
    height: 68px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    display: inline-block;
}

.payment-body-wrapper {
    width: 100%;
    padding: 30px 20px;
}

.paypalLogo {
    width: 100px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    display: inline-block;
}

.colsGap .col-xs-6:first-child {
    padding-right: 10px;
}

.colsGap .col-xs-6:last-child {
    padding-left: 10px;
}

.colsGap .col-md-8:first-child {
    padding-right: 10px;
}

.colsGap .col-md-4:last-child {
    padding-left: 10px;
}

.height60px {
    height: 60px;
}

.colsGap .btn.height60px {
    font-size: 20px;
}

.colsGap .btn.height60px i {
    font-size: 15px;
}

.colsGap .btn-default:active,
.colsGap .btn-default:hover,
.colsGap .btn-default:focus,
.colsGap .btn-default.active {
    border: 1px solid #1991eb;
}

.totalAmount {
    font-size: 24px;
    font-weight: bold;
}

.new-stripe-design {
    border: 1px solid #d8d8d8;
    border-radius: 8px;
    padding: 10px;

    .flexSpaceBetween {
        align-items: center;
        div {
            &:first-child {
                font-size: 16px;
                font-weight: bold;
                text-transform: uppercase;
            }

            &:last-child {
                font-size: 28px;
                font-weight: bold;
                color: #1991eb;
            }
        }
    }

    i {
        font-size: 66px;
        color: #d8d8d8;
    }

    button {
        background: #ededed;
        border-radius: 20px;
        padding: 15px 20px;
        text-transform: uppercase;
    }

    &:hover,
    &.active {
        border: 1px solid #1991eb;

        i {
            background: -webkit-linear-gradient(#40d5e6, #5577ff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        button {
            background: #1991eb;
            color: #fff;
        }
    }
}
