

// #evaIndividual table td:last-child,
// #evaOnTeam table td:last-child, 
// #evaOnInstructor table td:last-child,
// #michelsenMethod table td:last-child {
// 	border-right: solid 1px #d8d8d8;
// }

// #evaIndividual table .thisRow td:last-child,
// #evaOnTeam table .thisRow td:last-child,
// #evaOnInstructor table .thisRow td:last-child,
// #michelsenMethod table .thisRow td:last-child {
// 	border-right: none;
// }

#evaIndividual,
#evaOnTeam,
#evaOnInstructor,
#michelsenMethod {
	table {
		td:last-child {
			border-right: solid 1px #d8d8d8;	
		}

		.thisRow {
			td:last-child {
				border-right: none;
			}	
		}
	}
}

.width150px {
	width:150px;
	min-width:150px;
	max-width:150px;
}

td.disabled {
	background-color:#c5d0de;
}

.newEvaluationTable {
	border: solid 1px #d8d8d8;
    border-radius: 3px;
	box-shadow: 0 0 2px 0 rgb(202 202 202 / 50%);

	table {
		border:none;
		border-collapse: collapse;

		thead {
			tr {
				border:none;
				box-shadow:none;

				&:first-child {
					th:last-child {
						border-right:none;
					}
				}
			}
			
			th {
				border: solid 1px #d8d8d8;
				box-shadow:none;
				border-left:none;
				font-size: 12px;
				padding-top:6px;
				padding-bottom:6px;
			}

			th[rowspan] {
				background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
			}

			tr {
				&:nth-child(2) {
					text-transform:none;
					background: #fff;
				}

				&:first-child {
					text-transform: uppercase;
				}
			}
		}

		th, td {
			white-space:normal;

			&.whiteSpaceNowrap {
				white-space: nowrap;
			}
		}

		td:last-child {
			border-right:none;
		}

		.notFirstRow {
			td:last-child {
				border-right: solid 1px #d8d8d8;
			}
		}
	}
}

