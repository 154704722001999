
#test_confirm_modal .modal-body .message {
    text-align: center;
}
#test_confirm_modal .modal-body .message .fa {
    font-size: 40px;
    color: #e69618;
}
.individual {
    padding: 91px 25px 95px !important;
}
.group {
    padding: 91px 25px 95px !important;
}

@media (min-width: 992px) {
    .timer.individual,
    .timer.group {
        padding: 130px 25px 95px !important;
    }
}

@media (max-width: 991px) {
    .timer.individual,
    .timer.group {
        padding: 103px 25px 95px !important;
    }

    .test .right-container {
        padding: 105px 25px 73px 25px !important;
    }
}
#previewChooseTestTypeModal .modal-body .row:last-child {
    margin-top: 30px;
}

#previewChooseTestTypeModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#previewChooseTestTypeModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}

.print {
    display: none;
}
@media print {
    .print {
        display: block;
    }
}

.modal-countdown-number::after {
    content: 'Redirecting';
    width: 1ch;
    animation: countdown 3s step-end;
}
@keyframes countdown {
    /*0% {
    content: "10s";
  }
  10% {
    content: "9s";
  }
  20% {
    content: "8s";
  }
  30% {
    content: "7s";
  }
  40% {
    content: "6s";
  }
  0% {
    content: "5s";
  }
  20% {
    content: "4s";
  }*/
    0% {
        content: 'Redirecting in 3s';
    }
    33% {
        content: 'Redirecting in 2s';
    }
    67% {
        content: 'Redirecting in 1s';
    }
    100% {
        content: '0';
    }
}

.new-side-bar {
    display: flex;

    .main {
        -moz-transition: margin-left 0.55s ease-out;
        -o-transition: margin-left 0.55s ease-out;
        -webkit-transition: margin-left 0.55s ease-out;
        transition: margin-left 0.55s ease-out;
    }

    .main--slide {
        -moz-transition: margin-left 0.6s ease-out;
        -o-transition: margin-left 0.6s ease-out;
        -webkit-transition: margin-left 0.6s ease-out;
        transition: margin-left 0.6s ease-out;
    }
}

@media (max-width: 991px) {
    .new-side-bar {
        .main,
        .main--slide {
            margin-left: 0;
            width: 100%;
        }
    }
}
