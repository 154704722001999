
body {
    &.widget_open {
        height: 100vh;
        overflow-y: hidden;
    }
}

// Support manual button
.support_widget {
    position: fixed;
    bottom: 1%;
    right: 2%;
    z-index: 1000;

    .main_button {
        display: block;
        background: #1f73b7;
        border-radius: 100px;
        position: absolute;
        z-index: 1;
        padding: 6px 12px;
        line-height: 1;
        position: fixed;
        bottom: 1%;
        right: 2%;
        width: 66px;
        height: 66px;
    }

    .widget_button {
        &:hover,
        &:focus {
            background: #1991eb;
        }
    }

    .widget_body {
        display: none;
        width: 62px;
        height: 62px;
        border-radius: 100px;
        background-color: #fff;
        box-shadow: rgb(0 0 0 / 20%) 0px 0px 0.428571rem 0px;
        overflow: hidden;
        transform: translateY(0);
        transform-origin: bottom right;
        transition: transform 250ms ease-out;

        header {
            display: flex;
            align-content: stretch;
            -webkit-box-align: center;
            align-items: center;
            min-height: 2rem;
            padding: 0.571429rem 1rem;
            background: rgb(31, 115, 183);
            color: rgb(255, 255, 255);

            h2 {
                font-weight: 700;
                text-align: center;
                letter-spacing: 0.0214286rem;
                line-height: 1.2;
                font-size: 1.07143rem;
                flex-grow: 1;
                margin: 0;
            }
        }

        .widget_content {
            padding: 1.07143rem 0.714286rem;
            margin-left: 0.714286rem;
            margin-right: 0.357143rem;
            overflow: hidden auto;
            height: 100%;
        }

        .sub_button {
            padding: 3px 5px;
        }
    }
}

.open_widget {
    .main_button {
        display: none;
    }

    .widget_body {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 0.571429rem;
        transform: translateY(0);
    }
}

@media (max-width: 900px) {
    .main_button {
        svg {
            width: 36px;
            height: 36px;
        }
    }

    .support_widget.open_widget {
        width: 100%;
        height: 100%;
        bottom: 0;
        right: 0;

        .widget_body {
            height: 100%;
            border-radius: 0;
        }
    }
}
