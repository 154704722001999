
.menu-logo {
    background-position: center center;
    background-repeat: no-repeat;
    margin: 0 auto;
    width: 25px;
    height: 52px;
    text-align: center;
    display: flex;
    align-items: center;
}
.menu_section {
    background-color: #495060;
}

.menu_section > ul {
    text-align: center;
    margin: 0;
    list-style: none;
}

.menu-img-wrapper {
    display: block;
}

.menu-img {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    width: 36px;
    height: 36px;
}

span.menu-text {
    display: block;
    font-size: 1rem;
    line-height: 1;
    margin-top: 2px;
}

.nav.side-menu {
    background: #495060;
}

.nav.side-menu > li,
.nav.side-menu > a {
    // padding: 6px;
    padding: 12px 8px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 64px;
    // border-left: 6px solid transparent;
}

.nav.side-menu > li > a {
    color: #ffffff;
    background: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-shadow: none;
}

.nav.side-menu > li:hover,
.nav.side-menu > li.current-page:hover,
.nav.side-menu > li.active:hover,
.nav.side-menu > li:hover > a,
.nav.side-menu > li.current-page:hover > a,
.nav.side-menu > li.active:hover > a .nav.side-menu > li > a:hover,
.nav.side-menu > li.current-page > a :hover,
.nav.side-menu > li.active > a:hover,
.nav.side-menu > a:hover,
.nav.side-menu > a.current-page:hover,
.nav.side-menu > a.active:hover {
    background: #ffffff;
    color: #1991eb;
    cursor: pointer;
    border-left: 6px solid #1991eb30;
}

.nav.side-menu > li.current-page,
.nav.side-menu > li.active,
.nav-sm .nav.child_menu li.active,
.nav-sm .nav.side-menu li.active-sm,
.nav.side-menu > a.current-page,
.nav.side-menu > a.active {
    margin-left: 0;
    border-left: 6px solid #1991eb;
    border-right: none;
    background: #ffffff;
    color: #1991eb;
    // padding-left: 0;
}

.main_menu .fa,
.main_menu .fas {
    font-size: 26px;
    width: auto;
}

.menu-logo-wrapper {
    background: #273142;
    padding: 10px;
}

@media (max-width: 991px) {
    .menu-logo-wrapper {
        padding: 10px 25px 10px;
        color: #fff;
        font-size: 24px;
        line-height: 1;
        text-align: center;
    }
}

.help-center {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.isStudent {
    .left_col {
        background: #1991eb;
        height: 100%;
    }
    .menu_section {
        background: none;
    }
    .nav {
        &.side-menu {
            background: none;
        }
    }

    .menu-logo-wrapper {
        background: #1991eb;
    }
}
