
.discussionOption div:first-child {
    font-weight: bold;
}

.discussionOption div:last-child {
    margin-left: 10px;
}

.discussionOption {
    margin-top: 20px;
}

.discussionOption:first-child {
    margin-top: 0;
}

body.test .mini-panel .panel-heading {
    font-size: 14px;
}

body.test .mini-panel .panel-heading .link {
    // color: #1991eb;
    text-transform: capitalize;
}

.mini-panel .btnSections {
    width: 30.6%;
    margin-right: 4%;
}

.mini-panel .btnSections:nth-child(3n) {
    margin-right: 0;
}

.btnSections {
    margin-top: 10px;
}

.btnSections:nth-child(-n + 3) {
    margin-top: 0;
}

body.test .discussion .panel,
body.test .mini-panel.panel {
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
}

body.test .mini-panel.panel {
    margin-bottom: 0;
}

.commentTeams {
    white-space: normal;
}

.goodAnswerDiv .btn,
.goodAnswerDiv .statusTag {
    margin-left: 10px;
}

.goodAnswerDiv .btn:first-child {
    margin-left: 0;
}
.btnDiscussionTeams {
    margin-top: 10px;
}

.btnDiscussionTeams:first-child {
    margin-top: 0;
}

.numberInput {
    position: relative;
    width: 60px;
}

.quantity-nav {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
    color: #222;
    z-index: 4;
    height: 100%;
    max-height: 100%;
}

.quantity-nav .disabled {
    color: #dfe3e9;
    cursor: not-allowed;
}

.quantity-button {
    min-height: 18px;
    height: 50%;
    display: flex;
    align-items: center;
    width: 22px;
    justify-content: center;
    background-color: white !important;
    // border: 1px solid #dfe3e9 !important;
    border: 1px solid #767676 !important;
}

.quantity-button:last-child {
    // border: 1px solid #dfe3e9 !important;
    border-top: 1px solid #767676 !important;
}

.discussionComment textarea {
    height: auto;
    box-shadow: none;
    padding: 0;
    min-height: 32px;
    max-height: 300px;
    padding: 10px;
    border-radius: 8px;

    &.danger {
        border: 1px solid red;
    }
}

.rankDiv {
    margin-left: 20px;
}

.rankDiv:first-child {
    margin-left: 0;
}

.width156px {
    width: 156px;
}

.width280px {
    width: 280px;
}

@media (min-width: 992px) {
    .commentTeams div {
        margin-top: 15px;
    }

    .commentTeams div:first-child {
        margin-top: 0;
    }
}
.discussionTableRows:last-child .marginBottom20 {
    margin-bottom: 0;
}

body.test .panel-body.padding5px15px {
    padding: 5px 15px;
}

.new-discussion {
    margin-top: 20px;
    line-height: 1.2;
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    padding: 10px;
}

.new-discussion-dotted {
    border: 1px dotted #d8d8d8;
}

.new-discussion-ans > div {
    border: 1px solid #d8d8d8;
    padding: 10px;
}

.border-black {
    border: 1px solid #222;
}

.solid-warning {
    border: 1px solid #f59d00;
}

.minusWidth179px {
    width: calc(100% - 179px);
}

.width179px {
    width: 179px;
}

.disabled-background .new-discussion-ans > div,
.background-warning .new-discussion-ans > div {
    border: 1px solid #fff;
}

.big-bold-text {
    font-size: 28px;
    font-weight: bold;
}

.comment-comment div:not(:first-child) {
    margin-top: 10px;
}

.discussion-buttons .btn {
    margin-left: 0;
    margin-right: 20px;
}

.responseFont {
    margin-top: 8px;
    margin-right: 10px;
    font-weight: bold;
}

.background-warning {
    background-color: rgba(230, 155, 35, 0.5);
}
