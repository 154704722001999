
.withScrollBar {
    .scroll-container {
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            height: 0;
            width: 5px;
            background: #d8d8d8;
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background-color: #989b9c;
            border-radius: 2px;
        }
    }
}

.noScrollBar {
    .scroll-container {
        &::-webkit-scrollbar {
            display: none;
        }
    }
}
