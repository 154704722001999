
.difficultyLvl .lightGreen {
    background: #0f9b81;
    color: #fff;
    padding: 2px 10px;
    line-height: 1;
    font-size: 10px;
}

.difficultyLvl .green {
    background: #869b0f;
    color: #fff;
    padding: 2px 10px;
    line-height: 1;
    font-size: 10px;
}

.difficultyLvl .yellow {
    background: #e6bd18;
    color: #fff;
    padding: 2px 10px;
    line-height: 1;
    font-size: 10px;
}

.difficultyLvl .orange {
    background: #c27c23;
    color: #fff;
    padding: 2px 10px;
    line-height: 1;
    font-size: 10px;
}

.difficultyLvl .red {
    background: #cb325c;
    color: #fff;
    padding: 2px 10px;
    line-height: 1;
    font-size: 10px;
}

.difficultyLvl .maroon {
    background: #ed1c24;
    color: #fff;
    padding: 2px 10px;
    line-height: 1;
    font-size: 10px;
}

.difficultyLvl .margin2 div {
    margin: 0 2px;
}

.difficultyLvl .margin2 div:first-child {
    margin-left: 0;
}

.lvlBreak {
    height: 3px;
    background: #000;
    min-width: 100px;
}

.has-error .mce-panel {
    border: 1px solid red;
}

.radio-custom.has-error input[type='checkbox'],
.checkbox-custom.has-error input[type='checkbox'] {
    border: 2px solid red;
}

input[type='range'] {
    -webkit-appearance: none;
}

input[type='range']:focus {
    outline: none;
}

.sliders {
    position: inherit;
    width: 100%;
    height: 10px;
    background: #c5d0de;
    outline: none;
    border-radius: 20px !important;
}

.sliders::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 36px;
    height: 36px;
    border-radius: 3px;
    box-shadow: 2px 2px 2px 0 rgba(202, 202, 202, 0.5);
    border: solid 1px #d8d8d8;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    cursor: pointer;
}

.sliders::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 36px;
    height: 36px;
    border-radius: 3px;
    box-shadow: 2px 2px 2px 0 rgba(202, 202, 202, 0.5);
    border: solid 1px #d8d8d8;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    cursor: pointer;
}

/** FF*/
.sliders::-moz-range-progress {
    position: inherit;
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    outline: none;
    border-radius: 20px !important;
    background-color: #1991eb;
}

.sliders::-ms-fill-lower {
    position: inherit;
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    outline: none;
    border-radius: 20px !important;
    background-color: #1991eb;
}

.sliders::-moz-range-track {
    position: inherit;
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    background: #c5d0de;
    outline: none;
    border-radius: 20px !important;
}

.sliders::-ms-fill-upper {
    position: inherit;
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    background: #c5d0de;
    outline: none;
    border-radius: 20px !important;
}

input[type='range']::-moz-focus-outer {
    border: 0;
}

.ratingScaleWidth {
    width: calc(100% - 95px);
    padding-right: 50px;
}

.noError .help-block {
    display: none;
}

.range-slider-minimum {
    margin: auto;
    text-align: center;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;

    .range-slides {
        position: relative;
        width: calc(100% - 120px);
        height: 8px;
    }

    input[type='range'] {
        position: absolute;
        left: 0;
        bottom: 0;
    }

    input[type='number'],
    input[type='number']:active,
    input[type='number']:focus,
    input[type='text']:focus {
        border: 1px solid #cde;
        text-align: center;
        line-height: 1;
        width: 55px;
        height: 36px;
        padding: 10px;
        -moz-appearance: textfield;

        &:first-child {
            margin-right: 5px;
        }

        &:last-child {
            margin-left: 5px;
        }
    }

    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    input[type='number']:invalid,
    input[type='number']:out-of-range {
        border: 2px solid #ff6347;
    }

    input[type='range'] {
        // -webkit-appearance: none;
        width: 100%;
    }

    input[type='range']:focus {
        outline: none;
    }

    input[type='range']:focus::-webkit-slider-runnable-track {
        // background: #2497e3;
    }

    input[type='range'] {
        &:first-child {
            &:before {
                background: #fff;
            }
        }
    }

    input[type='range']:focus::-ms-fill-lower {
        background: #2497e3;
    }

    input[type='range']:focus::-ms-fill-upper {
        background: #2497e3;
    }

    input[type='range']::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        width: 100%;
        height: 5px;
        cursor: pointer;
        animate: 0.2s;
        // background: #2497e3;
        border-radius: 1px;
        box-shadow: none;
        border: 0;
    }

    input[type='range']::-webkit-slider-thumb {
        z-index: 2;
        position: relative;
        -webkit-box-shadow: 0px 0px 0px #000;
        box-shadow: 0px 0px 0px #000;
        border: 1px solid #cacaca;
        height: 30px;
        width: 30px;
        border-radius: 4px;
        background: #fff;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -14px;
    }

    input[type='range']::-moz-range-thumb {
        z-index: 2;
        position: relative;
        -webkit-box-shadow: 0px 0px 0px #000;
        box-shadow: 0px 0px 0px #000;
        border: 1px solid #cacaca;
        height: 30px;
        width: 30px;
        border-radius: 4px;
        background: #fff;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -14px;
    }

    .range-color {
        position: absolute;
        height: 5px;
        background: #1991eb;
        z-index: 1;
        top: 2px;
    }
}

.radioField {
    label {
        width: inherit;
    }
}
