
.colorPicker {
    input[type='radio'] {
        width: 42px;
        height: 42px;
        border-radius: 8px;

        &:checked {
            border: 3px solid #761fbb;
            box-shadow: none;
        }

        &:focus-visible {
            outline-offset: 3px;
            outline: 4px solid #1941a6;
            box-shadow: none;
        }
    }
}
