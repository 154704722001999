// .dropdown-backdrop {
//   z-index: 0;
// }

// .daterangepicker.dropdown-menu {
//   margin-bottom: 0;
// }

// .dropdown-disucssion {
//   width: 500px;
// }

//   .drop-down {
//     height: inherit;
//   }

//   .dropdown-menu {
//     button,
//     .buttons,
//     .btn,
//     router-link,
//     .statusTag {
//       font-size: 1.2em;
//     }
//   }

// .night-mode {
//   .dropdown-disucssion {
//     color: #222;
//     width: inherit;
//     min-width: 132px;
//   }
// }

// .dropdown-menu {
//   padding: 15px;
//   z-index: 899;
//   // margin-bottom:65px;
// }

// .dropdown-menu .btn {
//   width: 100%;
//   margin-left: 0;
//   margin-top: 10px;
// }

// // .dropup .dropdown-menu {
// // 	margin: 0;
// // 	margin-bottom: 10px;
// // }

// .dropdown-menu .btn:first-child {
//   margin-top: 0;
// }

// .dropdown {
//   .dropdown-disucssion {
//     margin-top: 10px;
//   }
// }

// .drop-down {
//   width: 100%;
//   height: 36px;
//   border-radius: 3px;
//   background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
//   border: 1px solid #d8d8d8;
//   padding: 6px 12px;
// }

// .viewDropdown .dropdown .dropdown-menu {
//   border-radius: 3px;
//   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
//   width: 100%;
//   position: relative;
//   top: 0;
// }

// .viewDropdown .dropdown-menu {
//   right: 0;
//   left: auto;
//   // top: 120%;
//   margin-top: 10px;
//   border-radius: 3px;
//   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
//   z-index: 10;
// }

// .setting .dropdown-menu {
//   right: 0;
//   left: auto;
//   top: 120%;
//   border-radius: 3px;
//   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
// }

// .dropdown-new {

//   .btn.drop-down {
//     margin-bottom: 0 !important;
//   }

// }

// .dropup {
//   .dropdown-menu-new {
//     margin-bottom: 5px;
//   }
// }

// .setting .dropdown-menu-new,
// .viewDropdown .dropdown-menu-new {
//   padding: 0;
//   top: inherit;
//   min-width:100px;
//   margin-top:5px;

//   ul {
//     list-style: none;
//     padding: 0;
//     margin: 0;

//     hr {
//       margin:0;
//       border-top: 1px solid #d8d8d8;
//         padding-bottom: 10px;
//     }

//     li {
//       font-size: 14px;
//       padding: 10px;
//       white-space:nowrap;

//       // &:not(:last-child) {
//       //   border-bottom:1px solid #d8d8d8;
//       // }

//       &:not(:first-child) {
//         padding-top: 0;
//       }

//       a {
//         display: block;
//         // color: #5a5a5a;
//         line-height: 0.8;
//         padding: 5px 10px;
//         // border: 1px solid #fff;
//         font-weight: normal;

//         &.default-state,
//         &.default-state span {
//           background: none;
//           color: #5a5a5a;
//           border: 1px solid #fff;
//         }

//         &.danger-state,
//         &.danger-state span {
//           background: none;
//           color: #dc3c42;
//           border: 1px solid #fff;
//         }

//         &:hover {
//           background: none;
//           // border: 1px solid #5a5a5a;

//           &.default-state {
//             // background:#1991eb;
//             // color:#fff;
//             color: #1991eb;
//             border: 1px solid #1991eb;
//             border-radius: 5px;

//           }

//           &.default-state span {
//             color: #1991eb;

//           }

//           &.danger-state {
//             // background: #dc3c42;
//             // color: #fff;
//             border: 1px solid #dc3c42;
//             border-radius: 5px;
//           }
//         }

//         i {
//           margin-right: 5px;
//         }

//         span {
//           line-height: 0.8;
//         }

//         span:last-child:not(:only-child) {
//           opacity: 0.6;
//         }
//       }
//     }

//   }
// }

// @media (max-width: 991px) {

//   .viewDropdown .dropdown-menu {
//     width: 100%;
//   }
// }

// button[disabled].drop-down {
//   background-image: none;
//   background: #c5d0de;
// }

// .drop-down {
//   width: 250px;
//   height: 36px;
//   border-radius: 3px;
//   background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
//   border: 1px solid #d8d8d8;
// }

// .dropdown .dropdown-menu {
//   // top: 0;
//   top: inherit;
//   border-radius: 3px;
//   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
//   width: 250px;
// }

// .dropup {
//   .dropdown-menu {
//     top: inherit;
//   }
// }

// .copyMoreAction .dropdown-menu {
//   top: 120%;
//   width: inherit;
// }

// .dropdown-menu {
//   border: medium none;
//   box-shadow: none;
//   display: none;
//   float: left;
//   font-size: 12px;
//   left: 0;
//   list-style: none outside none;
//   padding: 0;
//   position: absolute;
//   text-shadow: none;
//   top: 100%;
//   z-index: 9998;
//   border: 1px solid #d9dee4;
//   border-top-left-radius: 0;
//   border-top-right-radius: 0;
// }

// .dropdown.open #viewDropdownMenuButton {
//   margin-bottom: 10px;
// }

// .dropdown.margin0.open #viewDropdownMenuButton {
//   margin-bottom: 0;
// }

// #viewDropdownMenuButton {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }

#viewDropdownMenuButton,
#viewDropdownMenuButton:active {
    color: #222;
    background-color: none; // White
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    border: solid 1px #d8d8d8;
    box-shadow: 2px 2px 2px 0 rgba(202, 202, 202, 0.5);
    min-width: 100px;
    white-space: nowrap;

    &:disabled {
        background-color: #c5d0de;
        box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
        border: 1px solid #c5d0de;
        color: #fff;
        background-image: none;
        cursor: not-allowed;
        pointer-events: none;
    }
}

.dropdown {
    .dropdown-menu-new {
        margin-top: 8px;
        margin-bottom: 0px;
    }
}

.dropup {
    .dropdown-menu-new {
        margin-top: 0;
        margin-bottom: 8px;
    }
}

.dropdown-menu-new {
    padding: 0;
    //   top: inherit;
    min-width: 152px;

    &.withBetaBadge {
        min-width: 215px;
        width: max-content;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        hr {
            margin: 0;
            border-top: 1px solid #d8d8d8;
            padding-bottom: 10px;
        }

        li {
            font-size: 1rem;
            padding: 10px;
            white-space: nowrap;

            &.paddingBottom10 {
                padding-bottom: 10px;
            }

            // &:not(:last-child) {
            //   border-bottom:1px solid #d8d8d8;
            // }

            &.withHover {
                &:hover {
                    background: #d8d8d8;
                }
            }

            button {
                width: 100%;
            }

            &:not(:first-child) {
                padding-top: 0;

                &.paddingTop10 {
                    padding-top: 10px;
                }
            }

            a {
                display: block;
                // color: #5a5a5a;
                line-height: 0.8;
                padding: 5px 10px;
                // border: 1px solid #fff;
                font-weight: normal;
                text-align: left;

                &.default-state,
                &.default-state span,
                &.success-state,
                &.success-state span,
                &.primary-state,
                &.primary-state span {
                    background: none;
                    color: #5a5a5a;
                    border: 1px solid #fff;
                }

                &.danger-state,
                &.danger-state span {
                    background: none;
                    color: #dc3c42;
                    border: 1px solid #fff;
                }

                &.disabled,
                &.disabled span {
                    color: #c8c8c8;
                    pointer-events: none;
                    cursor: not-allowed;
                }

                &.disabled,
                &.disabled span {
                    color: #c8c8c8;
                    pointer-events: none;
                    cursor: not-allowed;
                }

                &:hover {
                    background: none;
                    // border: 1px solid #5a5a5a;

                    &.primary-state {
                        // background:#1991eb;
                        // color:#fff;
                        color: #0071eb;
                        border: 1px solid #0071eb;
                        border-radius: 5px;
                    }

                    &.primary-state span {
                        color: #0071eb;
                    }

                    &.default-state {
                        // background:#1991eb;
                        // color:#fff;
                        color: #3c3c43;
                        border: 1px solid #3c3c43;
                        border-radius: 5px;
                    }

                    &.default-state span {
                        color: #3c3c43;
                    }

                    &.success-state {
                        color: #39b54a;
                        border: 1px solid #39b54a;
                        border-radius: 5px;
                    }

                    &.success-state span {
                        color: #39b54a;
                    }

                    &.danger-state {
                        // background: #dc3c42;
                        // color: #fff;
                        border: 1px solid #dc3c42;
                        border-radius: 5px;
                    }

                    &.disabled,
                    &.disabled span {
                        color: #c8c8c8;
                        cursor: not-allowed;
                        pointer-events: none;
                    }
                }

                i {
                    margin-right: 5px;
                }

                span {
                    line-height: 0.8;
                }

                span:last-child:not(:only-child) {
                    opacity: 0.6;
                }
            }

            button.buttonRole {
                // color: #5a5a5a;
                line-height: 0.8;
                padding: 5px 10px;
                // border: 1px solid #fff;
                font-weight: normal;
                text-align: left;
                justify-content: left;
                border: 1px solid transparent;

                &.default-state,
                &.default-state span,
                &.success-state,
                &.success-state span,
                &.primary-state,
                &.primary-state span {
                    background: none;
                    color: #5a5a5a;
                    border: 1px solid #fff;
                }

                &.danger-state,
                &.danger-state span {
                    background: none;
                    color: #dc3c42;
                    border: 1px solid #fff;
                }

                &.disabled,
                &.disabled span,
                &:disabled,
                &:disabled span {
                    color: #c8c8c8;
                    pointer-events: none;
                    cursor: not-allowed;

                    &.hasToolTip {
                        pointer-events: inherit;
                    }
                }

                &:hover {
                    // border: 1px solid #5a5a5a;

                    &.primary-state {
                        // background:#1991eb;
                        // color:#fff;
                        color: #0071eb;
                        border: 1px solid #0071eb;
                        border-radius: 5px;
                    }

                    &.primary-state span {
                        color: #0071eb;
                    }

                    &.default-state {
                        // background:#1991eb;
                        // color:#fff;
                        color: #3c3c43;
                        border: 1px solid #3c3c43;
                        border-radius: 5px;
                    }

                    &.default-state span {
                        color: #3c3c43;
                    }

                    &.success-state {
                        color: #39b54a;
                        border: 1px solid #39b54a;
                        border-radius: 5px;
                    }

                    &.success-state span {
                        color: #39b54a;
                    }

                    &.danger-state {
                        // background: #dc3c42;
                        // color: #fff;
                        border: 1px solid #dc3c42;
                        border-radius: 5px;
                    }

                    &.disabled,
                    &.disabled span,
                    &:disabled,
                    &:disabled span {
                        color: #c8c8c8;
                        cursor: not-allowed;
                        pointer-events: none;
                        border-color: transparent;

                        &.hasToolTip {
                            pointer-events: inherit;
                        }
                    }

                    span {
                        &.betaBadge {
                            background: #777777;
                            color: #fff;
                        }
                    }
                }

                i {
                    margin-right: 5px;
                    min-width: 24px;
                    text-align: center;
                }

                span {
                    &.betaBadge {
                        background: #777777;
                        color: #fff;
                    }
                }

                span:last-child:not(:only-child) {
                    opacity: 0.6;
                }

                &.flexSpaceBetween {
                    justify-content: space-between;
                }
            }
        }
    }

    .fontSize-buttons {
        padding: 10px;
        padding-top: 0;

        .primary-state {
            margin-top: 0;
            border: 1px solid transparent;

            &:hover,
            &.active {
                color: #0071eb;
                border: 1px solid #0071eb;
                border-radius: 5px;
            }
        }

        .btn-default {
            &:hover,
            &.active {
                background: #0071eb;
                color: #fff;
                border: 1px solid #0071eb;
            }
        }
    }
}

.new-custom-search-select {
    #viewDropdownMenuButton,
    #viewDropdownMenuButton:active {
        background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#f5f7f9));
        background: linear-gradient(to bottom, #fff 0%, #f5f7f9 100%);
        -webkit-box-shadow: none;
        box-shadow: none;
        border: solid 1px #767676;
        min-width: 150px;
        font-weight: normal;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 3px;
        height: auto;

        span:first-child {
            text-align: left;
            width: 93%;
            overflow: hidden;
        }

        span:last-child {
            margin-left: 15px;
            color: #3c3c43;
        }
    }

    .dropdown-menu-new {
        top: 50px;
        margin-top: 0;
        z-index: 999;
        min-width: 204px;
    }

    .form-group {
        padding: 10px;

        i {
            line-height: 1;
            font-size: 1em;
            margin: 0;
        }

        .form-control-feedback {
            right: 25px;
            top: 38%;
            font-size: 1em;
        }
    }

    ul {
        li {
            button {
                padding: 0;
                justify-content: left;
                font-weight: normal;
                line-height: 1.428571429;
            }

            &:first-child {
                padding-top: 0;

                &.paddingTop10 {
                    padding-top: 10px;
                }
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}

@media (min-width: 992px) {
    .col-xs-12,
    .col-md-6 {
        .new-custom-search-select {
            width: 100%;

            #viewDropdownMenuButton,
            #viewDropdownMenuButton:active {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.filterBox {
    #viewDropdownMenuButton,
    .dropdown-menu {
        border-radius: 0;
        width: 179px;
    }
}
