:root {
    --button-bg: #fff;
    --button-c: #fff;
    --button-border: #fff;
}

button,
.btn {
    font-size: 16px;
    line-height: 1.3;

    &:active {
        &:focus {
            outline: 0;
            outline-offset: 0;
        }
    }

    &:focus {
        outline: 0;
        outline-offset: 0;
    }

    &-large {
        padding: 16px 32px;
    }
}

button,
.buttons,
.btn,
router-link,
.statusTag {
    margin: 0;
    font-size: 1rem;
    border: none;
    background: none;
    font-weight: bold;
    box-shadow: none;
    border-radius: 8px;
    // line-height: 0.8;
    color: #222;
    padding: 6px 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.statusTag {
    outline: none;
    color: inherit;
    // line-height: 1.2;
    text-align: center;
    // display: flex;
    // justify-content: center;
    // align-items: center;
}

.buttonGroup {
    display: flex;
    align-items: flex-start;
}

.buttonGroupInline {
    display: inline-flex;
    align-items: flex-start;
}

.buttonGroup div {
    width: 100%;
    display: flex;
}

.btn:active,
.btn.active {
    box-shadow: none;
}

@media (max-width: 991px) {
    .btn {
        margin-left: 10px;
        flex: 1;
    }
}

@media (min-width: 992px) {
    .btn {
        margin-left: 20px;
        flex: 1;

        // i {
        //   font-size: 13px;
        //   height: 13px;
        //   overflow: hidden;
        //   vertical-align: bottom;
        // }

        // span {
        //   line-height: 0.8;
        //   vertical-align: text-bottom;
        // }
    }

    .btn.col-xs-12 {
        margin-left: 0;
        flex: 1;
    }
}

.btn:first-child {
    margin-left: 0;
}

.btn-discussionTeams,
.btn-discussionTeams:active,
.btn-discussionTeams:hover,
.btn-discussionTeams:visited,
.btn-discussionTeams:focus,
.btn-discussionTeams:active:hover,
.btn-discussionTeams:active:focus,
.btn-discussionTeams.active,
.btn-discussionTeams.active:hover,
.btn-discussionTeams.active:focus,
.btn-discussionTeams.active.focus {
    border: solid 1px #d8d8d8;
    box-shadow: 2px 2px 2px 0 rgba(202, 202, 202, 0.5);
    color: #fff;
}

.btn-default,
.btn-default:active,
.btn-default:hover,
.btn-default:visited,
.btn-default:focus,
.btn-default:active:hover,
.btn-default:active:focus,
.btn-default.active,
.btn-default.active:hover,
.btn-default.active:focus,
.btn-default.active.focus {
    color: #222;
    background-color: #ffffff; // White
    border: solid 1px #d8d8d8;
    box-shadow: 2px 2px 2px 0 rgba(202, 202, 202, 0.5);
}

.gradient.btn-default,
.gradient.btn-default:active,
.gradient.btn-default:hover,
.gradient.btn-default:visited,
.gradient.btn-default:focus,
.gradient.btn-default:active:hover,
.gradient.btn-default:active:focus,
.gradient.btn-default.active,
.gradient.btn-default.active:hover,
.gradient.btn-default.active:focus,
.gradient.btn-default.active.focus {
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9); // off white to grey
    border: solid 1px #d8d8d8;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    // line-height: 0.8;
    font-weight: bold;
}

.gradient.btn-default-border-primary,
.gradient.btn-default-border-primary:active,
.gradient.btn-default-border-primary:hover,
.gradient.btn-default-border-primary:visited,
.gradient.btn-default-border-primary:focus,
.gradient.btn-default-border-primary:active:hover,
.gradient.btn-default-border-primary:active:focus,
.gradient.btn-default-border-primary.active,
.gradient.btn-default-border-primary.active:hover,
.gradient.btn-default-border-primary.active:focus,
.gradient.btn-default-border-primary.active.focus {
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9); // off white to grey
    border: solid 1px #1991eb;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    line-height: 0.8;
    color: #1991eb;
    font-weight: bold;
}

.gradient.btn-default-border-warning,
.gradient.btn-default-border-warning:active,
.gradient.btn-default-border-warning:hover,
.gradient.btn-default-border-warning:visited,
.gradient.btn-default-border-warning:focus,
.gradient.btn-default-border-warning:active:hover,
.gradient.btn-default-border-warning:active:focus,
.gradient.btn-default-border-warning.active,
.gradient.btn-default-border-warning.active:hover,
.gradient.btn-default-border-warning.active:focus,
.gradient.btn-default-border-warning.active.focus {
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9); // off white to grey
    border: solid 1px #f59d00;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    line-height: 0.8;
    color: #f59d00;
    font-weight: bold;
}

.gradient.btn-default i {
    // font-size: 12px;
    color: #767676;
}

.gradient.btn-default i.fontBlack {
    color: #222;
}

.gradient.btn-default.faded,
.gradient.btn-default.faded:active,
.gradient.btn-default.faded:hover,
.gradient.btn-default.faded:visited,
.gradient.btn-default.faded:focus,
.gradient.btn-default.faded:active:hover,
.gradient.btn-default.faded:active:focus,
.gradient.btn-default.faded.active,
.gradient.btn-default.faded.active:hover,
.gradient.btn-default.faded.active:focus,
.gradient.btn-default.faded.active.focus {
    color: #767676;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9); // off white to grey
    border: solid 1px #d8d8d8;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    line-height: 0.8;
    font-weight: bold;
}

.btn-default:hover.resetBtn {
    background-color: #f59d00;
    color: #fff;
}

.btn-default:hover.deleteBtn {
    background: #eb1919;
    color: #fff;
}

.btn-default:hover.primaryHover {
    background: #1991eb;
    color: #fff;
}

@media (max-width: 991px) {
    .btn-default:focus.resetBtn {
        background-color: #f59d00;
        color: #fff;
    }

    .btn-default:focus.deleteBtn {
        background: #eb1919;
        color: #fff;
    }
    .btn-default:focus.primaryHover {
        background: #1991eb;
        color: #fff;
    }
}

.btn-primary,
.btn-secondary {
    --button-bg: #0071eb;
    --button-border: #0071eb;
    --button-c: #fff;

    background-color: var(--button-bg);
    border: 2px solid var(--button-border);
    color: #fff;

    &:hover,
    &:focus:hover {
        background-image: none;
        background-color: #fff;
        border-color: #1941a6;
        color: #3c3c43;
    }

    &.in-progress {
        background-color: #93c4f9;
        border-color: #93c4f9;
        color: #ffffff;
    }

    &:focus-visible,
    &:focus {
        background-color: var(--button-bg);
        border-color: var(--button-border);
        color: #ffffff;
    }

    &:active,
    &:active:focus,
    &.active,
    &.active:focus {
        background-color: #1941a6;
        border: 2px solid #1941a6;
        color: #ffffff;
    }
}

.btn-success {
    --button-bg: #2d8659;
    --button-border: #2d8659;
    --button-c: #fff;

    background-color: var(--button-bg);
    border: 2px solid var(--button-border);
    color: #fff;

    &:hover,
    &:focus:hover {
        background-image: none;
        background-color: #fff;
        border-color: #00582f;
        color: #3c3c43;
    }

    &.in-progress {
        background-color: #a5c9b7;
        border-color: #a5c9b7;
        color: #ffffff;
    }

    &:focus-visible,
    &:focus {
        background-color: var(--button-bg);
        border-color: var(--button-border);
        color: #ffffff;
    }

    &:active,
    &:active:focus {
        background-color: #00582f;
        border: 2px solid #00582f;
        color: #ffffff;
    }
}

.btn-danger {
    --button-bg: #d04343;
    --button-border: #d04343;
    --button-c: #fff;

    background-color: var(--button-bg);
    border: 2px solid var(--button-border);
    color: #fff;

    &:hover,
    &:focus:hover {
        background-image: none;
        background-color: #fff;
        border-color: #99001c;
        color: #3c3c43;
    }

    &.in-progress {
        background-color: #e6aeae;
        border-color: #e6aeae;
        color: #ffffff;
    }

    &:focus-visible,
    &:focus {
        background-color: var(--button-bg);
        border-color: var(--button-border);
        color: #ffffff;
    }

    &:active,
    &:active:focus {
        background-color: #99001c;
        border: 2px solid #99001c;
        color: #ffffff;
    }
}

.btn-warning {
    --button-bg: #c97420;
    --button-border: #c97420;
    --button-c: #fff;

    background-color: var(--button-bg);
    border: 2px solid var(--button-border);
    color: #fff;

    &:hover,
    &:focus:hover {
        background-image: none;
        background-color: #fff;
        border-color: #e3c1a0;
        color: #3c3c43;
    }

    &.in-progress {
        background-color: #e6aeae;
        border-color: #e6aeae;
        color: #ffffff;
    }

    &:focus-visible,
    &:focus {
        background-color: var(--button-bg);
        border-color: var(--button-border);
        color: #ffffff;
    }

    &:active,
    &:active:focus {
        background-color: #934800;
        border: 2px solid #934800;
        color: #ffffff;
    }
}

.btn-outline-primary {
    --button-bg: #ffffff;
    --button-border: #0071eb;
    --button-c: #0071eb;

    background-color: #ffffff;
    border: 2px solid var(--button-border);
    color: var(--button-c);

    &:hover,
    &:focus:hover {
        background-image: none;
        background-color: var(--button-c);
        border-color: var(--button-border);
        color: #fff;
    }

    &.in-progress {
        background-color: #efefef;
        border-color: var(--button-border);
        color: #ffffff;
    }

    &:focus-visible,
    &:focus {
        background-color: #ffffff;
        border-color: var(--button-border);
        color: var(--button-c);
    }

    &:active,
    &:active:focus {
        background-color: #1941a6;
        border: 2px solid #1941a6;
        color: #ffffff;
    }
}

.btn,
fieldset {
    &.disabled,
    &[disabled] {
        background-color: #c5d0de;
        box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
        opacity: 1;
        border: 1px solid #c5d0de;
        color: #fff;
        pointer-events: none;
        cursor: not-allowed;
        background-image: none;

        i {
            color: #fff;

            &.fa-eye-slash.disabledIcon {
                color: #c5d0de;
            }
        }

        &.hasToolTip {
            pointer-events: inherit;
        }

        &:hover,
        &:focus {
            background-color: #c5d0de;
            box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
            opacity: 1;
            border: 1px solid #c5d0de;
            color: #fff;
            pointer-events: none;
            cursor: not-allowed;
            background-image: none;

            i {
                color: #fff;

                &.fa-eye-slash.disabledIcon {
                    color: #c5d0de;
                }
            }

            &.hasToolTip {
                pointer-events: inherit;
            }
        }

        .btn {
            background-color: #c5d0de;
            box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
            opacity: 1;
            border: 1px solid #c5d0de;
            color: #fff;
            pointer-events: none;
            cursor: not-allowed;
            background-image: none;

            i {
                color: #fff;

                &.fa-eye-slash.disabledIcon {
                    color: #c5d0de;
                }
            }

            &.hasToolTip {
                pointer-events: inherit;
            }

            &:hover,
            &:focus {
                background-color: #c5d0de;
                box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
                opacity: 1;
                border: 1px solid #c5d0de;
                color: #fff;
                pointer-events: none;
                cursor: not-allowed;
                background-image: none;

                i {
                    color: #fff;

                    &.fa-eye-slash.disabledIcon {
                        color: #c5d0de;
                    }
                }

                &.hasToolTip {
                    pointer-events: inherit;
                }
            }
        }
    }
}

.btn-bs-secondary,
.btn-bs-secondary:focus,
.btn-bs-secondary:active,
.btn-bs-secondary:visited,
.btn-bs-secondary:hover,
.btn-bs-secondary:active:hover,
.btn-bs-secondary:active:focus {
    background: #6c757d;
    border: 1px solid #6c757d;
    box-shadow: 2px 2px 2px 0 rgba(202, 202, 202, 0.5);
    color: #fff;
}

// outline button

// div.statusTag {
//   display:inline-block;
// }

.statusTagDanger,
.statusTagDanger:hover,
.statusTagDanger:focus,
.statusTagDanger.focus {
    background: #fff;
    border: 1px solid #ff0000;
    color: #ff0000;
    border-radius: 3px;
}

.statusTagDarkDanger,
.statusTagDarkDanger:hover,
.statusTagDarkDanger:focus,
.statusTagDarkDanger.focus {
    background: #fff;
    border: 1px solid #cc0000;
    color: #cc0000;
    border-radius: 3px;
}

.statusTagPrimary,
.statusTagPrimary:hover,
.statusTagPrimary:focus,
.statusTagPrimary.focus {
    background: #fff;
    border: 1px solid #1991eb;
    color: #1991eb;
    border-radius: 3px;
}

.statusTagWarning,
.statusTagWarning:hover,
.statusTagWarning:focus,
.statusTagWarning.focus {
    background: #fff;
    border: 1px solid #ff9900;
    color: #ff9900;
    border-radius: 3px;
}

.statusTagLightWarning,
.statusTagLightWarning:hover,
.statusTagLightWarning:focus,
.statusTagLightWarning.focus {
    background: #fff;
    border: 1px solid #e69b23;
    color: #e69b23;
    border-radius: 3px;
}

.statusTagLightWarning i {
    // font-size: 12px;
    font-size: 0.75em;
    color: #767676;
}

.statusTagSuccess,
.statusTagSuccess:hover,
.statusTagSuccess:focus,
.statusTagSuccess.focus {
    background: #fff;
    border: 1px solid #29cb97;
    color: #29cb97;
    border-radius: 3px;
}

.statusTagDarkSuccess,
.statusTagDarkSuccess:hover,
.statusTagDarkSuccess:focus,
.statusTagDarkSuccess.focus {
    background: #fff;
    border: 1px solid #0f9b81;
    color: #0f9b81;
    border-radius: 3px;
    justify-content: center;
}

.statusTag-bs-dark,
.statusTag-bs-dark:hover,
.statusTag-bs-dark:focus,
.statusTag-bs-dark.focus {
    background: #fff;
    border: 1px solid #343a40;
    color: #343a40;
    border-radius: 3px;
}

.statusTag-bs-secondary,
.statusTag-bs-secondary:hover,
.statusTag-bs-secondary:focus,
.statusTag-bs-secondary.focus {
    background: #fff;
    border: 1px solid #6c757d;
    color: #6c757d;
    border-radius: 3px;
}

.gradient-default,
.gradient-default:hover,
.gradient-default:focus {
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
}

// .goodAnswers {
//   // font-size: 12px;
//   font-size: 0.75em;
// }

.label-default {
    background-color: #f2f2f2;
    color: #767676;
    font-weight: normal;
}

.label-success {
    background: rgba(15, 155, 129, 0.1);
    color: #0f9b81;
    font-weight: normal;
}

// @media (max-width: 991px)
// {
//   .hidden-md.hidden-lg button.btn.mainBtn {
//     width: 36px;
//     padding: 0;
//     min-width: 36px;
//   }
// }

.btn-outline-default {
    --button-bg: #ffffff;
    --button-border: #3c3c43;
    --button-c: #3c3c43;

    background-color: #ffffff;
    border: 2px solid #3c3c43;
    color: #3c3c43;

    &:hover,
    &:focus:hover {
        background-image: none;
        background-color: #3c3c43;
        border-color: #3c3c43;
        color: #fff;
    }

    &.in-progress {
        background-color: #efefef;
        border-color: #3c3c43;
        color: #fff;
    }

    &:focus-visible,
    &:focus {
        background-color: #ffffff;
        border-color: #3c3c43;
        color: #3c3c43;
    }

    &:active,
    &:active:focus {
        background-color: #4b6a94;
        border: 2px solid #4b6a94;
        color: #fff;
    }
}

.btn-outline-success {
    border: 2px solid;
    background-color: #fff;
    color: #2d8659;
    border-color: #2d8659;

    &.instructor {
        color: #3c3c4c;
        border-color: #39b54a;

        i {
            color: #3c3c4c;
        }
    }

    &:hover,
    &:active,
    &:focus {
        background-color: #2d8659;
        color: #fff;

        &.instructor {
            background-color: #39b54a;
        }

        i {
            color: #fff;
        }
    }
}

.btn-outline-info {
    background-color: transparent;
    color: $btn-info-bg !important;
    border-color: $btn-info-bg;
    &:hover,
    &:active,
    &:focus {
        background-color: $btn-info-bg;
        border-color: $btn-info-border;
        color: #fff !important;
    }
}

.btn-outline-warning {
    border: 1px solid;
    background-color: transparent;
    color: $btn-warning-bg !important;
    border-color: $btn-warning-bg;
    &:hover,
    &:active,
    &:focus {
        background-color: $btn-warning-bg;
        border-color: $btn-warning-border;
        color: #fff !important;
    }
}

.btn-outline-danger {
    border: 1px solid;
    background-color: transparent;
    color: $btn-danger-bg !important;
    border-color: $btn-danger-bg;
    &:hover,
    &:active,
    &:focus {
        background-color: $btn-danger-bg;
        border-color: $btn-danger-border;
        color: #fff !important;
    }
}

@media (max-width: 991px) {
    .col-xs-12.btn {
        margin-left: 0;
        margin-top: 20px;
    }

    .col-xs-12.btn:first-child {
        margin-top: 0;
    }
}

// Creation page buttons

.creation .flex .btn {
    padding: 25px;
    width: 275px;
    // font-size: 16px;
    font-size: 1em;
}

.creation,
.creationMethod {
    .flex {
        i {
            color: #767676;
            // font-size: 24px;
            font-size: 1.5em;

            &.important {
                color: #c1254a;
            }
        }

        .fontSize16 {
            font-size: 0.88rem;
        }
    }
}

.creation .flex i.fa-check-circle,
.creationMethod .flex i.fa-check-circle {
    // font-size: 18px;
    font-size: 1.125em;
}

.creation .flex .btn div:nth-child(2),
.creationMethod .flex .btn div:nth-child(2) {
    margin-top: 10px;
}

.creation .buttonGroup {
    margin-top: 30px;
}

.creation div:first-child > .btn.btn-default:hover,
.creation div:first-child > .btn.btn-default:active,
.creation div:first-child > .btn.btn-default:focus,
.creation div:first-child > .btn.btn-default.activated,
.creationMethod div:first-child > .btn.btn-default:hover,
.creationMethod div:first-child > .btn.btn-default:active,
.creationMethod div:first-child > .btn.btn-default:focus {
    color: #1991eb;
    border: 1px solid #1991eb;
}

.creation div:first-child > .btn.btn-default:hover i,
.creation div:first-child > .btn.btn-default:active i,
.creation div:first-child > .btn.btn-default:focus i,
.creation div:first-child > .btn.btn-default.activated i,
.creation div:first-child > .btn .fa-check-circle,
.creationMethod div:first-child > .btn.btn-default:hover i,
.creationMethod div:first-child > .btn.btn-default:active i,
.creationMethod div:first-child > .btn.btn-default:focus i,
.creationMethod div:first-child > .btn .fa-check-circle {
    color: #1991eb;
}

@media (min-width: 992px) {
    .creation div:first-child > .btn i:nth-child(2),
    .creationMethod div:first-child > .btn i:nth-child(2) {
        // font-size: 16px;
        font-size: 1em;
    }
}

@media (max-width: 991px) {
    .creation .btn,
    .creationMethod .btn {
        width: 100%;
        margin-left: 0;
    }

    .creation .buttonGroup,
    .creationMethod .buttonGroup {
        display: block;
    }

    .creation .btn {
        margin-top: 20px;
    }

    .creation .btn:first-child,
    .creationMethod .btn:first-child {
        margin-top: 0;
    }

    .creation div:first-child > .btn,
    .creationMethod div:first-child > .btn {
        text-align: left;
        justify-content: left;
    }

    .creation div:first-child > .btn i:first-child,
    .creationMethod div:first-child > .btn i:first-child {
        // font-size: 18px;
        font-size: 1.125em;
    }

    .creation div:first-child > .btn .fa-circle-o,
    .creationMethod div:first-child > .btn .fa-circle-o {
        color: #d8d8d8;
    }
}
.swal-custom-cancel-button {
    background-color: white !important;
    color: #222 !important;
}

.mce-btn button {
    padding: 2px !important;
}

.btn-empty,
.btn-empty:active,
.btn-empty:hover,
.btn-empty:visited,
.btn-empty:focus,
.btn-empty:active:hover,
.btn-empty:active:focus,
.btn-empty.active,
.btn-empty.active:hover,
.btn-empty.active:focus,
.btn-empty.active.focus {
    box-shadow: none;
    background: #fff;
    color: #222;
    padding: 0 !important;
}

// button:focus-visible,
// .buttons:focus-visible,
// .btn:focus-visible,
// router-link:focus-visible,
// a:focus-visible,
// li:focus-visible,
// .btn.active:focus-visible {
//   outline: 3px solid #0d26fd;
//   outline-offset: 0;
//   border-radius: 3px;
// }

button,
router-link,
a,
li,
.buttons,
.btn {
    // &:focus,
    // &:active:focus,
    // &.active:focus {
    //   outline: none;
    //   box-shadow: none;
    // }

    &:focus-visible,
    &:active:focus-visible,
    &.active:focus-visible {
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255 255 255);
    }
}

.btn-empty-border-none,
.btn-empty-border-none:active,
.btn-empty-border-none:hover,
.btn-empty-border-none:visited,
.btn-empty-border-none:focus,
.btn-empty-border-none:active:hover,
.btn-empty-border-none:active:focus,
.btn-empty-border-none.active,
.btn-empty-border-none.active:hover,
.btn-empty-border-none.active:focus,
.btn-empty-border-none.active.focus,
.btn-empty-border-none:disabled,
.btn-empty-border-none.disabled,
.btn-empty-border-none.disabled:hover,
.btn-empty-border-none.disabled:focus,
.btn-empty-border-none[disabled],
.btn-empty-border-none[disabled]:hover {
    box-shadow: none;
    background: #fff;
    color: #222;
    padding: 0 !important;
    border: none;
}

// .mainBtn {
//   height: 36px;
// }

// .subBtn {
//   height: 28px;
// }

.tab-buttons {
    display: flex;
    line-height: 1;

    .button {
        border: 1px solid #d8d8d8;
        background-color: #f6f8fa;
        color: #767676;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        padding: 10px 0;

        &:hover {
            cursor: pointer;
        }

        &.active {
            border: 1px solid #1991eb;
            background-color: #ffffff;
            color: #222222;

            &:hover {
                cursor: default;
            }
        }
    }

    div:first-child {
        border-radius: 3px 0 0 3px;
    }

    div:last-child {
        border-radius: 0 3px 3px 0;
    }

    div:only-child {
        border-radius: 3px;
    }
}

@media (min-width: 992px) {
    .tab-buttons {
        .button {
            width: 125px;
        }
    }
}

@media (max-width: 991px) {
    .tab-buttons {
        margin-top: 20px;
        .button {
            width: 50%;
        }
    }
}

.btn-link {
    color: #1071bc;
}

// 14 Mar 2023
.isStudent,
.test {
    button {
        // padding: 8px 32px;
        padding: 5.21px 12px;
        border-radius: 8px;
        height: inherit;
        line-height: 1.2 !important;

        &.paginationLeft,
        &.paginationRight {
            padding: 8px;
        }

        &.isEnabled {
            background-size: 500px;
            background-repeat: no-repeat;

            color: var(--button-c);
            border-color: var(--button-border);
            background-position: 0%;
            background-image: -webkit-linear-gradient(30deg, var(--button-bg) 50%, var(--button-bg) 50%);
            background-image: linear-gradient(30deg, var(--button-bg) 50%, var(--button-bg) 50%);
            transition: background 300ms ease-in-out;
            animation: enabledEffect 0.8s ease-in-out;
        }
    }

    .btn-outline-default {
        --button-bg: #ffffff;
        --button-border: #3c3c43;
        --button-c: #3c3c43;

        background-color: #ffffff;
        border: 2px solid #3c3c43;
        color: #3c3c43;

        &:hover,
        &:focus:hover {
            background-image: none;
            background-color: #3c3c43;
            border-color: #3c3c43;
            color: #fff;
        }

        &.in-progress {
            background-color: #efefef;
            border-color: #3c3c43;
            color: #fff;
        }

        &:focus-visible,
        &:focus {
            background-color: #ffffff;
            border-color: #3c3c43;
            color: #3c3c43;
        }

        &:active,
        &:active:focus {
            background-color: #4b6a94;
            border: 2px solid #4b6a94;
            color: #fff;
        }
    }

    .btn-primary,
    .btn-secondary {
        --button-bg: #0071eb;
        --button-border: #0071eb;
        --button-c: #fff;

        background-color: var(--button-bg);
        border: 2px solid var(--button-border);
        color: #fff;

        &:hover,
        &:focus:hover {
            background-image: none;
            background-color: #fff;
            border-color: #1941a6;
            color: #3c3c43;
        }

        &.in-progress {
            background-color: #93c4f9;
            border-color: #93c4f9;
            color: #ffffff;
        }

        &:focus-visible,
        &:focus {
            background-color: var(--button-bg);
            border-color: var(--button-border);
            color: #ffffff;
        }

        &:active,
        &:active:focus {
            background-color: #1941a6;
            border: 2px solid #1941a6;
            color: #ffffff;
        }
    }

    .btn-success {
        --button-bg: #2d8659;
        --button-border: #2d8659;
        --button-c: #fff;

        background-color: var(--button-bg);
        border: 2px solid var(--button-border);
        color: #fff;

        &:hover,
        &:focus:hover {
            background-image: none;
            background-color: #fff;
            border-color: #00582f;
            color: #3c3c43;
        }

        &.in-progress {
            background-color: #a5c9b7;
            border-color: #a5c9b7;
            color: #ffffff;
        }

        &:focus-visible,
        &:focus {
            background-color: var(--button-bg);
            border-color: var(--button-border);
            color: #ffffff;
        }

        &:active,
        &:active:focus {
            background-color: #00582f;
            border: 2px solid #00582f;
            color: #ffffff;
        }
    }

    .btn-danger {
        --button-bg: #d04343;
        --button-border: #d04343;
        --button-c: #fff;

        background-color: var(--button-bg);
        border: 2px solid var(--button-border);
        color: #fff;

        &:hover,
        &:focus:hover {
            background-image: none;
            background-color: #fff;
            border-color: #99001c;
            color: #3c3c43;
        }

        &.in-progress {
            background-color: #e6aeae;
            border-color: #e6aeae;
            color: #ffffff;
        }

        &:focus-visible,
        &:focus {
            background-color: var(--button-bg);
            border-color: var(--button-border);
            color: #ffffff;
        }

        &:active,
        &:active:focus {
            background-color: #99001c;
            border: 2px solid #99001c;
            color: #ffffff;
        }
    }

    .btn-warning {
        --button-bg: #c97420;
        --button-border: #c97420;
        --button-c: #fff;

        background-color: var(--button-bg);
        border: 2px solid var(--button-border);
        color: #fff;

        &:hover,
        &:focus:hover {
            background-image: none;
            background-color: #fff;
            border-color: #e3c1a0;
            color: #3c3c43;
        }

        &.in-progress {
            background-color: #e6aeae;
            border-color: #e6aeae;
            color: #ffffff;
        }

        &:focus-visible,
        &:focus {
            background-color: var(--button-bg);
            border-color: var(--button-border);
            color: #ffffff;
        }

        &:active,
        &:active:focus {
            background-color: #934800;
            border: 2px solid #934800;
            color: #ffffff;
        }
    }

    .btn-outline-primary {
        --button-bg: #ffffff;
        --button-border: #0071eb;
        --button-c: #0071eb;

        background-color: #ffffff;
        border: 2px solid var(--button-border);
        color: var(--button-c);

        &:hover,
        &:focus:hover {
            background-image: none;
            background-color: var(--button-c);
            border-color: var(--button-border);
            color: #fff;
        }

        &.in-progress {
            background-color: #efefef;
            border-color: var(--button-border);
            color: #ffffff;
        }

        &:focus-visible,
        &:focus {
            background-color: #ffffff;
            border-color: var(--button-border);
            color: var(--button-c);
        }

        &:active,
        &:active:focus {
            background-color: #1941a6;
            border: 2px solid #1941a6;
            color: #ffffff;
        }
    }

    button {
        transition: all 300ms ease-in-out;

        &:disabled,
        &.disabled {
            background-color: #efefef;
            border-color: #efefef;
            color: #8b8b8f;
            box-shadow: none;

            i {
                color: inherit;
            }

            &.isLoading {
                background-image: none;
                background-color: #a5c9b7;
                border-color: #a5c9b7;
                color: #fff;
                box-shadow: 0px 4px 8px rgba(60, 60, 67, 0.8);
            }

            &.isSaved {
                background-color: #2d8659;
                border-color: #2d8659;
                color: #fff;
                box-shadow: 0px 4px 8px rgba(60, 60, 67, 0.8);
                border-radius: 100px;
            }
        }

        &:focus-visible,
        &:focus,
        &:hover,
        &:focus:hover {
            &:disabled,
            &.disabled {
                background-color: #efefef;
                border-color: #efefef;
                color: #8b8b8f;
                box-shadow: none;

                i {
                    color: inherit;
                }

                &.isLoading {
                    background-image: none;
                    background-color: #a5c9b7;
                    border-color: #a5c9b7;
                    color: #fff;
                    box-shadow: 0px 4px 8px rgba(60, 60, 67, 0.8);
                }

                &.isSaved {
                    background-color: #2d8659;
                    border-color: #2d8659;
                    color: #fff;
                    box-shadow: 0px 4px 8px rgba(60, 60, 67, 0.8);
                    border-radius: 100px;
                }
            }
        }
    }

    @media (min-width: 992px) {
        table {
            button {
                min-width: 100px;

                &.minWidthAuto {
                    min-width: auto;
                }
            }
        }
    }
}

button {
    &.leftContent {
        &:disabled,
        &.disabled {
            background-color: transparent;
            border-color: transparent;
        }
    }
}

@keyframes enabledEffect {
    0% {
        color: #8b8b8f;
        border-color: #efefef;
        background-position: 0%;
        background-image: -webkit-linear-gradient(30deg, #efefef 50%, var(--button-bg) 50%);
        background-image: linear-gradient(30deg, #efefef 50%, var(--button-bg) 50%);
    }

    50% {
        background-image: -webkit-linear-gradient(30deg, #efefef 20%, var(--button-bg) 80%);
        background-image: linear-gradient(30deg, #efefef 20%, var(--button-bg) 80%);
    }

    100% {
        background-position: 100%;
        color: var(--button-c);
        border-color: var(--button-border);
    }
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(1px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-2px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(2px, 0, 0);
    }
}

// 2023
.fa,
.fab,
.fal,
.far,
.fas {
    vertical-align: middle;
    line-height: 1.2;
}

button,
.buttons,
.btn,
router-link,
.statusTag {
    i {
        font-size: 1rem;
        line-height: 1.2;

        &.fs-14px {
            font-size: 14px;
        }

        &.fs-22px {
            font-size: 22px;
        }
    }
}

.fa-stack-2x {
    line-height: 1;
}

.btn-default {
    &.with-accommodations-icons {
        .fa-universal-access {
            color: #761fbb;
        }

        &:hover {
            .fa-universal-access {
                color: #fff;
            }
        }
    }
}

.btn-default {
    &.modalSelections {
        // .fa-play {
        //     visibility: hidden;
        // }

        &.activatedPrimary,
        &:hover {
            color: #1991eb;
            border: 1px solid #1991eb;

            .fa-play {
                visibility: visible;
            }
        }

        label {
            font-size: 1.333rem;
        }
    }
}

.primary-text {
    color: #1991eb;
}
