
.create .flexRadio,
.qnsBuilderQnsOptions .flexRadio {
    margin-left: 0;
    margin-right: 20px;
}

.activityType .flexRadio {
    margin-bottom: 10px;
}

.radioField {
    label {
        width: calc(100% - 20px);
        line-height: 1.2;
    }

    input[type='radio'] {
        display: block;
        margin: 0;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        position: relative;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        box-shadow: inset 0 0 0 2px #fff;
        border: solid 2px #222;
        background: #fff;
        margin-right: 5px;
        margin-top: 2px;

        &:hover {
            border: 2px solid #1991eb;

            & + label {
                color: #1991eb;
            }
        }

        &:checked {
            border-color: #1991eb;
            box-shadow: inset 0 0 0 3px #fff;
            background: #1991eb;

            &:before {
                content: inherit;
            }

            & + label {
                color: #1991eb;
            }
        }
    }
}

.activityType {
    .radioField {
        & > div {
            align-items: baseline;
        }

        label {
            line-height: 0.9;
        }
    }
}

.demoForm {
    .demonFormBlock {
        display: block;
    }

    .radioField {
        .flex {
            flex-wrap: inherit;
            align-items: center;
        }

        .active {
            background: purple;
            border-radius: 8px;
        }

        label {
            font-size: 1rem;
        }

        input[type='radio'] {
            width: 1.662rem;
            height: 1.5rem;
            background-color: #ffffff;
            border: 1px solid #767676;
            box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
            background-size: 0 0;
            // transition: background-size 0.28s cubic-bezier(0.4, 0, 0.2, 1);

            &:checked {
                border-color: none;
                border: 1px solid #767676;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23761FBB'/%3e%3c/svg%3e");
                box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
                background-size: 100% 100%;

                &:before {
                    display: none;
                }

                &:disabled {
                    box-shadow: inherit;
                }

                & + label {
                    color: #3c3c43;
                }
            }

            &:hover {
                border: 1px solid #767676;
                box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
                outline: 10px solid #e7dcf9;

                & + label {
                    color: #3c3c43;
                }
            }

            &:not(:only-child) {
                margin-right: 10px;
            }

            &:disabled {
                background: #efefef;
                border: 1px solid #efefef;
                box-shadow: inherit;

                &:hover {
                    background: #efefef;
                    box-shadow: none;
                    outline: none;
                }

                &:checked {
                    background: #efefef;
                    border: 1px solid #efefef;
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238B8B8F'/%3e%3c/svg%3e");
                    background-size: 100% 100%;
                }
            }
        }

        // .demonForm-radio-focus {
        //   &:focus-within {
        //     outline: 4px solid #1941a6;
        //     outline-offset: 3px;
        //     box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
        //     border-radius: 8px;
        //   }

        //   input[type="radio"] {
        //     &:focus-visible {
        //       outline: 0;
        //       box-shadow: none;
        //     }
        //   }
        // }

        label {
            line-height: 1;
        }
    }
}
