@font-face {
    font-family: 'Calibri';
    src: url('/fonts/Calibri.ttf');
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}
@font-face {
    font-family: 'Calibri';
    src: url('/fonts/Calibrib.ttf');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Calibri';
    src: url('/fonts/calibrii.ttf');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Calibri';
    src: url('/fonts/calibriz.ttf');
    font-weight: bold;
    font-style: italic;
}
/*FROM OLD ACCOUNT CENTER*/
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
.glyphicon-spin {
    -webkit-animation: spin 1000ms infinite linear;
    animation: spin 1000ms infinite linear;
}

body {
    font-family: Calibri;
    background: #495060;
    //background:#f5f7f9;
    color: #222;
    padding-right: 0 !important;
}

// html,
// body,
// .right-container {
//   font-size: 16px;
// }

html,
body,
#app,
.container,
.container > div,
.container.body,
.col-md-3.left_col {
    height: 100%;
}

@media (min-width: 992px) {
    .container > div.main_container,
    .right_col {
        min-height: calc(100% - 72px);
    }

    .dashboard.right-container.timerStart {
        box-sizing: border-box;
        padding: 10px 25px 61px 25px;
    }
}

body,
.main_container,
.container.body,
.right_col > div,
.right-container {
    background: #f7f8fa;
}

.right-container {
    transition: 0.5;
}

input[type='file'],
input[type='radio'],
input[type='checkbox'] {
    &:focus {
        outline-offset: 0;
        outline: 0;
    }
}

// .right-container {
//   margin-bottom:25px;
// }

div {
    white-space: normal;
}

p {
    margin: 0;
}

a,
a:hover,
a:focus,
a:active,
a:visited {
    color: #767676;
}

.question a,
.question a:hover,
.question a:focus,
.question a:active,
.question a:visited,
.blueLink a,
.blueLink a:hover,
.blueLink a:focus,
.blueLink a:active,
.blueLink a:visited {
    color: #337ab7;
}

a.whiteText,
a.whiteText:hover,
a.whiteText:focus,
a.whiteText:active,
a.whiteText:visited {
    color: #fff;
}

a.link,
a.link:hover,
a.link:focus,
a.link:active,
a.link:visited,
.textLink,
.textLink:hover,
.textLink:focus,
.textLink:active,
.textLink:visited,
.btn-default.textLink:active:focus,
.editHover:hover {
    // color: #1991eb;
    color: #1071bc;
}

.link,
.textLink,
.editHover {
    &:hover {
        cursor: pointer;
    }
}

.content img,
.panel-body img,
.modal-body img,
p img {
    // max-width: 100%;
    // max-height: 100%;
    max-width: 100%;
    height: auto;
}

.testPanel,
.eGallery-panel {
    p {
        img {
            // max-width: 400px;
            // max-height: 200px;
            max-width: 600px;
            max-height: 400px;
            object-fit: contain;
            cursor: pointer;
        }
    }

    .imgMax100 {
        img {
            max-width: 100%;
        }
    }
}

/* sidebar */
.nav-sm .container.body .col-md-3.left_col {
    width: 95px;
    padding: 0;
    z-index: 999;
    position: fixed;
}

/* end of sidebar */

/* top bar fixed */

.defaultTopBar {
    position: fixed;
    width: 100%;
    z-index: 100;
    min-height: 72px;
}

/* end of top bar fixed */

/* border title */

.borderGrey {
    border-radius: 3px;
    border: 1px solid #d8d8d8;
    background: #fff;
    color: #767676;
    display: inline-block;
    padding: 6px 12px;
    font-size: 0.88rem;
    line-height: 1.2;
}

.borderBlack {
    border-radius: 3px;
    border: 1px solid #222;
    background: #fff;
    color: #222;
    display: inline-block;
    padding: 6px 12px;
    font-size: 0.88rem;
    line-height: 1.2;
}

/* end of border title */

/*Modal CSS*/

.modal {
    text-align: center;
    overflow-y: auto;
}

.modal-backdrop.fade.in {
    z-index: 50001 !important;
}

.modal-open {
    overflow: auto;
    padding-right: 0 !important;
}

.modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
}
.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

.modal-content {
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px #ededed;
    border: none;
}

.modal-header {
    padding: 20px;
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
    border-bottom: solid 1px #ededed;
}

.modal-title {
    color: #222;
    // font-size: 16px;
    // font-size: 24px;
    font-size: 0.88rem;
    line-height: 1;
    font-weight: bold;
}

.modal-header .close {
    margin-top: 0;
    height: 16px;
    margin-right: 0;
    color: #222;
    opacity: 1;
    font-size: 16px;
    padding: 0 !important;
    text-shadow: none;

    i {
        font-size: 25px;
    }
}

.close {
    opacity: 1;
    color: #222;

    i {
        font-size: 25px;
    }

    &:hover,
    &:focus {
        opacity: 1;
    }
}

.modal-body {
    padding: 20px;
    display: inline-block;
    width: 100%;
    background: #fff;
}

.modal-footer {
    background-color: #495060;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 5px 15px;
}

.modal-dialog textarea {
    resize: none;
    height: 150px;
    border: solid 1px #767676;
    outline: none;

    &:focus,
    &:focus-visible {
        border: 1px solid #1991eb;
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
    }
}

#suspendModal .modal-body .row:last-child {
    margin-top: 30px;
}

#suspendModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#suspendModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}
/* end of Modal */
.flex {
    display: flex;
    flex-wrap: wrap;
}

.inline-flex-wrap {
    display: inline-flex;
    flex-wrap: wrap;
}

.flexOnly {
    display: flex;
}

.flex1 {
    flex: 1;
}

.flexWrap {
    flex-wrap: wrap;
}

.overFlowAuto {
    overflow: auto;
}

.align-items {
    align-items: center;
}

.alignItemsNormal {
    align-items: normal;
}

.alignFlexStart {
    align-items: flex-start;
}

.alignFlexEnd {
    align-items: flex-end;
}

.alignBaseline {
    align-items: baseLine;
}

.alignRight {
    text-align: right;
}

.textAlignLeft {
    text-align: left;
}

.justifyCenter {
    justify-content: center;
}

.justifyEnd {
    justify-content: end;
}

.justifyLeft {
    justify-content: left;
}

.flexRight {
    display: flex;
    justify-content: flex-end;
}

.flexRight div {
    justify-content: flex-end;
}

.flexSpaceBetween {
    display: flex;
    justify-content: space-between;
}

.inlineFlex {
    display: inline-flex;
}

.displayContent {
    display: contents;
}

.d-none {
    display: none;
}

.d-block {
    display: block;
}

.capitalize {
    text-transform: capitalize;
}

.uppercase {
    text-transform: uppercase;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
    float: none;
    padding: 0;
}

/* Alert */

.alert {
    padding: 5px 10px;
    line-height: 1.2;
    margin-bottom: 0;
}

.alert-primary {
    background: #f6f8fa;
    border: none;
    color: #222;
    border-radius: 3px;
    display: flex;

    i {
        color: #1991eb;
        // font-size: 12px;
        font-size: 1rem;
    }

    i.greyText {
        color: #767676;
    }

    .btn {
        i {
            color: #fff;
        }
    }
}

.alert-primary-bg {
    background: rgba(15, 155, 129, 0.1);
    border: 1px solid rgba(15, 155, 129, 0.1);
    color: #222;
    border-radius: 3px;
    display: flex;
}

.alert-warning {
    border-radius: 3px;
    background-color: #fff8ed;
    // color: #222;
    color: #8a6d3b;
    border: 0;
    border-radius: 3px;
    border: 1px solid #e69b23;
    display: flex;

    i {
        color: #fab762;
        font-size: 12px;
        // font-size: 0.75em;
        margin-top: 2px;

        &.fontSize18 {
            font-size: 1rem;
            margin-top: 0;
        }

        &.marginTop0 {
            margin-top: 0;
        }
    }

    button {
        &.close {
            opacity: 1;
        }

        i {
            color: inherit;

            &.fontBlack {
                color: #e69b23;
            }
        }
    }

    i.darkGreen {
        //dark green
        color: #0f9b81;
    }

    i.greyText {
        color: #767676;
    }

    &.d-block {
        display: block;
    }
}

.alert-warning.alignItemsNormal {
    align-items: normal;
}

.alert-warning-bg {
    background: rgba(194, 124, 35, 0.1);
    border: 1px solid rgba(194, 124, 35, 0.1);
    color: #222;
    border-radius: 3px;
    display: flex;
    i {
        color: #fab762;
    }
}

.alert-success {
    border-radius: 3px;
    border: solid 1px #0f9b81;
    background-color: rgba(15, 155, 129, 0.1);
    color: #222;
    display: flex;

    i {
        color: #0f9b81;
        font-size: 12px;
        // font-size: 0.75em;
    }
}

.alert-danger {
    color: #fff;
    display: flex;

    i {
        color: #fff;
        font-size: 12px;
        // font-size: 0.75em;
        margin-right: 5px;
    }
}

.alert-info {
    color: #fff;
    display: flex;

    i {
        color: #fff;
        font-size: 12px;
        // font-size: 0.75em;
        margin-right: 5px;
    }
    span {
        line-height: 1;
    }
}

.alert .btn {
    margin-left: 0;
    padding: 2px 12px;
}

@media (max-width: 991px) {
    .creation .content .alert .btn {
        width: auto;
        margin-top: 0;
    }
}
.alert-black {
    color: #fff;
    background-color: #434e5e;
    border-color: #434e5e;
    position: relative;
    font-size: 16px;
    // font-size: 1em;
    padding: 14px;
}
.alert-black.alert-dismissible {
    padding-right: 35px;
}
.alert-black.alert-dismissible .close,
.alert-dismissible .close {
    position: absolute;
    top: 5px;
    right: 10px;
    color: inherit;
}
.alert-black a {
    color: #bbddfb;
}

/* End of Alert */

// ----- circle ----- //

.suspendCircle {
    color: #f85359;
}

.ownerCircle::before,
.activeCircle::before {
    //cyan
    background-image: linear-gradient(135deg, #188e9b, #6dd7c7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.fullAccessCircle::before {
    //purple
    background-image: linear-gradient(153deg, #3023ae, #c86dd7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.readOnlyCircle::before {
    // orange
    background-image: linear-gradient(to top, #f7981c, #ffb555);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.dateCircle {
    // dark green
    color: #0f9b81;
}

.redGradientCircle::before {
    // red
    background-image: linear-gradient(225deg, #f1272f, #fc6469);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.blueCircle::before {
    background-image: linear-gradient(to top, #1991eb, #1991eb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

// --- star -- //
.star {
    color: #767676;
}

.starred {
    color: #ffb555;
}

.important,
.errorColor,
.trashHover:hover {
    //red
    // color: #eb1919;
    color: #c1254a;
}

.darkGreen {
    //dark green
    color: #0f9b81;
}

// .links {
//   width: 100%;
//   overflow-x: scroll;
//   border-bottom: solid 3px #d8d8d8;
//   margin-bottom: 30px;

//   ul {
//     padding: 0px;
//     margin: 0px;
//     white-space: nowrap;
//     width: 100%;
//     // height: 25px;
//     // height: 1.5625em;
//     // display: inline-table;

//     li {
//       list-style-type: none;
//       // display: inline-block;
//       border: none;
//       margin-bottom: 0;
//       margin-left: 23px;
//       background: none;
//       // height: 1.5625em;
//       display: block;
//       position: relative;
//       float: left;
//       margin-bottom: -3px;
//       border-bottom: solid 3px transparent;

//       &:first-child {
//         margin-left: 0;
//       }

//       a {
//         text-transform: uppercase;
//         // font-size: 14px;
//         font-size: 0.875em;
//         font-weight: bold;
//         line-height: 1;
//         color: #989b9c;
//         padding-bottom: 5px;
//         background: none;
//       }

//       &.active {
//         border-bottom: solid 3px #1991eb;
//         color: #222;
//       }
//     }
//   }
// }

.links {
    white-space: normal;
    overflow-x: inherit !important;
    margin-bottom: 30px;
    border-bottom: 0.1875em solid #ddd;

    &:before,
    &:after {
        display: table;
        content: ' ';
    }

    &:after {
        clear: both;
    }

    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;

        li {
            float: left;
            margin-bottom: -0.1875em;
            margin-left: 23px;

            &:first-child {
                margin-left: 0;
            }

            a {
                padding: 0;
                text-transform: uppercase;
                // font-size: 14px;
                font-size: 0.9rem;
                font-weight: bold;
            }

            &.active {
                border-bottom: 0.1875em solid #1991eb;

                a,
                a:hover,
                a:focus {
                    color: #222;
                    border: none;
                    border-color: none;
                    background: none;
                }
            }
        }
    }
}

ul.modal-points {
    display: inline-block;
}

ul.modal-points li {
    list-style-type: none;
}

ul.modal-points li > div {
    display: flex;
    align-items: center;
}

.modal-points i {
    font-size: 6px;
    height: 10px;
    margin-right: 10px;
}

.links {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.links::-webkit-scrollbar {
    display: none;
}

.main_container .top_nav {
    display: flex;
    background: #f7f7f7;
}

.create div.flex {
    display: flex;
    flex-wrap: wrap;
}

.create div .col-xs-12.col-md-6:first-child,
.flex .col-xs-12.col-md-6:first-child {
    padding-right: 10px;
}

.create div .col-xs-12.col-md-6:last-child,
.flex .col-xs-12.col-md-6:last-child {
    padding-left: 10px;
}

.create div .col-xs-12.col-md-6:only-child,
.flex .col-xs-12.col-md-6:only-child {
    padding-left: 0;
}

.flex .col-xs-12.col-md-6.paddingLeft0:last-child {
    padding-left: 0;
}

div.createDropdown {
    display: block;
    margin-bottom: 30px;
    width: 100%;
}

.displayNo {
    text-align: right;
}

.viewDropdown {
    width: 100%;
    display: flex;
    // justify-content: flex-end;
}

.pagination {
    margin: 0;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}

.pagination > li > a,
.pagination > li > span {
    padding: 12px 0;
    line-height: 1;
    border: 1px solid #d8d8d8;
    color: #222;
    line-height: 0.8;
    width: 36px;
    height: 36px;
    text-align: center;
}

.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
    background: #f6f8fa;
    color: #767676;
    font-weight: bold;
    border-left: 1px solid #989b9c;
    border-right: 1px solid #989b9c;
    border-top: 2px solid #989b9c;
    border-bottom: 2px solid #989b9c;
    line-height: 0.7;
}

.pagination > .active > a.disabled {
    opacity: 1;
}

.pagination > .active.first > a {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-left: 2px solid #989b9c;
}

.pagination > .active.last > a {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-right: 2px solid #989b9c;
}

.nav-sm footer {
    margin-left: 0;
}

footer {
    padding: 12px 25px;
    font-size: 12px;
    color: #767676;
    line-height: 1;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 900;
}

@media (min-width: 992px) {
    footer {
        left: 95px;
        width: calc(100% - 95px);
    }

    body.test footer {
        left: 0;
    }

    .test footer {
        width: 100%;
    }
}

/* Question Builder Toggle */

// comment on 14 apr 23
// .switch {
//   position: relative;
//   display: inline-block;
//   min-width: 41px;
//   width: 42px;
//   height: 20px;
// }
// end comment on 14 apr 23

// .slider {
//   position: absolute;
//   cursor: pointer;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: #ccc;
//   -webkit-transition: 0.4s;
//   transition: 0.4s;
//   width: 36px;
// }

// .switch .slider:after {
//   position: absolute;
//   left: 41px;
//   content: "No";
//   line-height: 0.8;
//   top: 6px;
// }

// comment on 14 apr 23
// .switch .slider:before {
//   position: absolute;
//   content: "";
//   height: 16px;
//   width: 16px;
//   left: 2px;
//   bottom: 2px;
//   background-color: white;
//   -webkit-transition: 0.4s;
//   transition: 0.4s;
// }

// .switch {
//   .slider {
//     transition: 0s;
//     -webkit-transition: 0s;
//   }

//   input {
//     &[type="checkbox"]:focus {
//       outline: 0;
//       outline-offset: 0;
//     }

//     &[type="checkbox"]:focus-visible {
//       outline: 0;
//       outline-offset: 0;

//       & + .slider {
//         outline: 3px solid #1941a6;
//         outline-offset: 0;
//       }
//     }
//   }
// }
// end comment on 14 apr 23

// input:checked + .slider:after {
//   position: absolute;
//   left: 41px;
//   content: "Yes";
//   line-height: 0.8;
//   top: 6px;
// }

.switch-toggle-label {
    font-weight: bold;
    line-height: 0.8;
    // margin-top: 3px;
    width: 1.4em;
    margin-left: 10px;
}

// comment on 14 apr 23
// input:checked + .slider:before {
//   transform: translateX(16px);
//   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z' fill='%23fff' /%3E%3Cpath d='M9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z' fill='%231B83C7'/%3E%3C/svg%3E");
// }

// .switchToggle .control-label {
//   margin-bottom: 0;
//   margin-right: 10px;
//   line-height: 0.8;
// }
// end comment on 14 apr 23

// .requiredSwitch {
//   position: relative;
//   display: inline-block;
//   width: 30px;
//   height: 18px;
// }

// .requiredSwitch .slider:before {
//   position: absolute;
//   content: "";
//   height: 14px;
//   width: 14px;
//   left: 2px;
//   bottom: 2px;
//   background-color: white;
//   -webkit-transition: .4s;
//   transition: .4s;
// }

// comment on 14 apr 23
// .slider.round {
//   border-radius: 34px;
// }

// .slider.round:before {
//   border-radius: 50%;
// }
// end comment on 14 apr 23

// .openEndedPoints #minimum {
//   width:130px !important;
// }

// .ratingScaleWidth .form-group {
//   width:94%;
// }

/* End of Question Builder Toggle */

/* Tooltip */

.tooltip {
    display: block !important;
    z-index: 99999 !important;

    .tooltip-inner {
        background: black;
        color: white;
        border-radius: 8px;
        padding: 5px 10px 4px;
        max-width: 250px;
    }

    .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 5px;
        border-color: black;
        z-index: 1;
    }

    &[x-placement^='top'] {
        margin-bottom: 5px;

        .tooltip-arrow {
            border-width: 5px 5px 0 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            bottom: -5px;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^='bottom'] {
        margin-top: 5px;

        .tooltip-arrow {
            border-width: 0 5px 5px 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
            top: -5px;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^='right'] {
        margin-left: 5px;

        .tooltip-arrow {
            border-width: 5px 5px 5px 0;
            border-left-color: transparent !important;
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
            left: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[x-placement^='left'] {
        margin-right: 5px;

        .tooltip-arrow {
            border-width: 5px 0 5px 5px;
            border-top-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            right: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[aria-hidden='true'] {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.15s, visibility 0.15s;
    }

    &[aria-hidden='false'] {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.15s;
    }
}

.tooltip.difficultyLvl {
    .tooltip-inner {
        background: #fff;
        color: #000;
        min-width: 170px;
        border-radius: 3px;
        padding: 5px;
    }
    .tooltip-arrow {
        border-color: #fff;
    }
}

/* End of Tooltip */

/* Test */
.question {
    font-size: 1rem;
    font-weight: normal;
    word-break: break-word;
}

input[type='radio'],
input[type='checkbox'] {
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    outline: none;

    &:focus-visible {
        outline: 3px solid #1941a6;
        outline-offset: 0;
    }
}

.radioField label {
    display: flex;
    font-size: 1rem;
    font-weight: 400;
    margin-left: 20px;

    &:first-of-type {
        margin-left: 0;
    }
}

.flexRadio {
    margin-left: 20px;
    height: 15px;
}

.flexRadio:first-child {
    margin-left: 0;
}

.radioField input[type='radio'] {
    display: block;
    margin: 0;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px #fff;
    border: solid 2px #222;
    background: #fff;
    margin-right: 5px;

    &:hover {
        border: solid 2px #1991eb;
    }

    &:disabled {
        border-color: #d8d8d8;
        box-shadow: inset 0 0 0 15px #d8d8d8;
    }

    // &:checked {
    //   &:before {
    //     font-family: "Font Awesome 5 Free";
    //     display: inline-block;
    //     content: "";
    //     color: #0f9b81;
    //     font-size: 12px;
    //     // line-height: 0.9;
    //     font-weight: 900;
    //     position: absolute;
    //     top: -4%;
    //     left: -2%;
    //   }
    // }

    &:checked {
        border-color: #0f9b81;
        box-shadow: inset 0 0 0 3px #0f9b81;

        &:before {
            content: inherit;
        }

        &:disabled {
            box-shadow: inset 0 0 0 3px #d8d8d8;
        }
    }
}

.activitySetup .radioField input[type='radio'] {
    display: block;
    margin: 0;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 50% !important;
    box-shadow: inset 0 0 0 2px #fff;
    border: solid 2px #222;
    background: #fff;
    margin-right: 5px;

    :checked {
        &:before {
            font-family: FontAwesome;
            display: inline-block;
            content: '\f192';
            // letter-spacing: 5px;
            color: #1d83c7;
            font-size: 17px;
            // line-height: 0.8;
            line-height: 1;
            width: 15px;
            border: none;
            background-color: none;
            border-radius: none;
        }

        label {
            color: #1d83c7;
        }
    }
}

.vote-radio label {
    display: inline-block;
    background-color: #0f9b81;
    border-radius: 5px;
    line-height: 1;
    color: #fff;
    padding: 15px;
    font-size: 16px;
}

.voted-radio label {
    display: inline-block;
    background-color: #e69b23;
    border-radius: 5px;
    line-height: 1;
    color: #fff;
    padding: 15px;
    font-size: 16px;
}

.vote-radio-disabled label {
    display: inline-block;
    background-color: #c5d0de;
    border-radius: 5px;
    line-height: 1;
    color: #fff;
    padding: 15px;
    font-size: 16px;
}

/* End of Test */

textarea {
    width: 100%;

    &:focus {
        border-color: #1991eb;
    }
}

.tox-tinymce {
    border: 1px solid #767676 !important;

    &--disabled {
        border: 1px solid #eeeeee !important;
    }
}

textarea:disabled {
    background: #dfe3e9;
    cursor: not-allowed;
    color: #767676;
}

/* Steps Navi */

.stepsNaviFixed {
    background: #f7f8fa;
    /*position: fixed;*/
    z-index: 10;
    /*width: calc(100% - 50px);*/
}

@media (min-width: 992px) {
    .stepsNaviFixed {
        /*width: calc(100% - 144.5px);*/
    }
}

// .stepsNavi {
//   padding-bottom: 7px;
// }

// .stepsNavi > div {
//   padding: 0 10px;
// }

// .stepsNavi > div:first-child {
//   padding-left: 0;
// }

// .stepsNavi > div:last-child {
//   padding-right: 0;
// }

// .stepsNavi > div.viewed {
//   color: #1991eb;
//   opacity: 0.5;
// }

// .stepsNavi > div.currentStep {
//   opacity: 1;
// }

// .stepsNavi > div {
//   color: #d8d8d8;
// }

.stepsNavi {
    padding-top: 7px;
    padding-bottom: 7px;

    & > a,
    & > div {
        padding: 0 10px;
        color: #d8d8d8;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        &.viewed {
            color: #1991eb;
            opacity: 0.5;
        }

        &.currentStep {
            opacity: 1;
        }
    }

    .fa-long-arrow-alt-right {
        color: #d8d8d8;
        margin: 0 10px;
    }
}

/* End of Steps Navi */

/* Creation */
.creation .header {
    background-color: #fbfbfb;
    padding: 15px 20px;
    font-weight: bold;
    // text-transform: uppercase;
}

.creation .body {
    padding: 20px;
}

.creation .body .col-xs-12 .col-xs-6 {
    padding: 0;
}

.creation .body .col-xs-12 .col-xs-6:first-child {
    padding-right: 10px;
}

.creation .body .col-xs-12 .col-xs-6:last-child {
    padding-left: 10px;
}

.genericUsers .col-xs-12.col-md-6:nth-child(3) {
    margin-bottom: 30px;
}
/* End of Creation */

/* dashboard status */

.statusProgression {
    margin-bottom: 20px;
}

.statusProgression:last-child {
    margin-bottom: 0;
}

.statusProgression .bar {
    flex: 1 1 auto;
    border-radius: 15px;
    // box-shadow: inset 0 1px 1px 0 #989b9c;
    background-color: #e5e9f2;
    border: none;
    display: flex;
    margin-top: 5px;
}

.statusProgression .barProgress {
    height: 30px;
    border-radius: 15px;
    background-color: #0f9b81;
}

.statusProgression .bar div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
}

/* end of dashboard status */

/* Test */

.testProgression .bar {
    flex: 1 1 auto;
    height: 16px;
    border-radius: 100px;
    // box-shadow: inset 0 1px 1px 0 #989b9c;
    background-color: #e5e9f2;
    border: none;
}

.testProgression .barProgress {
    height: 16px;
    border-radius: 100px;
    // background-color: #0f9b81;
    background-color: #f5821e;
}

.test-progression.testProgression .bar {
    flex: 1 1 auto;
    height: 30px;
    border-radius: 15px;
    background-color: #e5e9f2;
    border: none;
    display: flex;
}

.test-progression.testProgression .barProgress {
    height: 30px;
    border-radius: 15px;
    background-color: #0f9b81;
}

.test-progression.testProgression .bar div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    // font-size: 1.25em;
}

.test-progression.testProgression .barProgress.bgSolitude {
    background-color: #e5e9f2;
}

/* discussion */

.discussion-bar {
    flex: 1 1 auto;
    height: 30px;
    border-radius: 15px;
    background-color: #e5e9f2;
    border: none;
    display: flex;
    border: 1px solid #d8d8d8;
}

.discussion-bar .barProgress {
    height: 30px;
    border-radius: 15px;
    color: #fff;
    background-color: #1991eb;
}

.discussion-bar div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    // font-size: 1.25em;
}
/* end of discussion */

/* question team */

.question-div-bar {
    border: none !important;
    background: none !important;
    padding: 0 !important;
}

.question-bar {
    flex: 1 1 auto;
    height: 30px;
    border-radius: 15px;
    background-color: #1991eb;
    border: none;
    display: flex;
    border: 1px solid #d8d8d8;
}

.question-bar .barProgress {
    height: 30px;
    border-radius: 15px;
    color: #fff;
    background-color: #eb6219;
    border: 1px solid #d8d8d8;
    border-right: 1px solid #fff;
}

.question-bar div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    // font-size: 1.25em;
}

/* end of question team */

@media (min-width: 992px) {
    .profileImg {
        width: 140px;
        padding: 0 20px;
    }

    .profileBtns {
        width: 50%;
        margin-left: 140px;
    }

    .nav-sm .container.body .col-md-3.left_col {
        display: flex;
    }

    .nav-sm .container.body .right_col {
        display: block;
        margin-left: 95px;
        padding: 0;
    }

    /* question builder */
    .ratingTextDiv {
        width: 33.4%;
    }
    .ratingWeightDiv {
        width: 10%;
    }
}

@media (max-width: 991px) {
    .right_col {
        padding: 0px !important;
    }

    // .menu-container {
    //   padding-top: 5px;
    // }

    .nav-sm .container.body .col-md-3.left_col {
        // display: flex;
        // width: 70px;
        min-width: 70px;
        // height: 100%;
        height: calc(100% - 53.59px);
        position: fixed;
        left: 0;
        top: 53.59px;
    }

    .nav-sm .container.body .right_col {
        display: block;
        margin-left: 0px;
    }

    .nav-sm .container.body .right_col {
        padding: 0px;
        margin-left: 0px;
        z-index: 2;
    }

    .profileImg {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
    }

    .profileBtns {
        width: 100%;
        margin-left: 0;
    }
    #suspendModal .modal-body .row div {
        margin-top: 20px;
    }

    #suspendModal .modal-body .row div:first-child {
        margin-top: 0;
    }

    .modal-dialog {
        width: 90%;
    }

    .resetPasswordDropDown {
        margin-top: 20px;
    }
}

@media (min-width: 768px) {
    .modal-dialog {
        //width:auto;
        //max-width:832px;
        margin: 30px auto;
        min-width: 600px;
    }

    footer {
        margin-left: 0;
    }
}

@media (max-width: 450px) {
    .modal-dialog {
        transform: none;
        margin: 0;
        width: 100%;
        position: inherit;
        left: 0;
        height: 100%;

        .modal-content {
            height: 100%;
            overflow: auto;
        }
    }
}

/* panel */

.gradient.panel {
    border-radius: 3px;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
}

.gradient.panel .panel-heading {
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
    border-bottom: 1px solid #d8d8d8;
    align-items: center;
    padding: 20px;
    font-size: 16px;
    // font-size: 1em;
    font-weight: bold;
}

.gradient.panel .panelFooter {
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    align-items: center;
    padding: 20px;
    border-top: 1px solid #d8d8d8;
}

// .gradient-panel-body {
//   background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
// }

// .gradient-panel-body .question{
//   background:#fff;
//   border-top:1px solid #d8d8d8;
//   border-radius:3px;
//   padding:25px;
// }

// .gradient.panel .panel-body {
//   padding:30px 20px;
// }

.panel .mini-panel .panel-heading {
    background-image: none;
    background: #f6f8fa;
}

.gradient.panel:last-child,
.x_panel:last-child {
    margin-bottom: 0;
}

/* End of Panel */

/* Additional CSS */
.primaryColor {
    color: #546ea9;
}

.primaryBackground {
    background-color: #546ea9;
    color: #fff;
}

.disabledColor {
    color: #767676;
}

.successColor {
    color: #0f9b81;
}

.successBackground {
    background-color: #0f9b81;
    color: #fff;
}

.warningColor {
    color: #e69b23;
}

.warningBackground {
    background-color: #e69b23;
    color: #fff;
}

.dangerColor {
    color: #dc3c42;
}

.dangerBackground {
    background-color: #dc3c42;
    color: #fff;
}
.censorBackground {
    background-color: #fdecec;
    color: #ba2929;
}
.widthAuto {
    width: auto;
}

.minWidthAuto {
    min-width: auto;
}

.minWidth0 {
    min-width: 0;
}

.maxWidth100 {
    max-width: 100%;
}

.maxWidth50px {
    max-width: 50px;
}

.minWidth100px {
    min-width: 100px;
}

.maxWidth100px {
    max-width: 100px;
}

.minWidth110px {
    min-width: 110px;
}

.maxWidth110px {
    max-width: 110px;
}

.minWidth120px {
    min-width: 120px;
}

.maxWidth120px {
    max-width: 120px;
}

.minWidth130px {
    min-width: 130px;
}

.maxWidth130px {
    max-width: 130px;
}

.maxWidth132px {
    max-width: 132px;
}

.maxWidth142px {
    max-width: 142px;
}

.minWidth150px {
    min-width: 150px;
}

.maxWidth150px {
    max-width: 150px;
}

.maxWidth160px {
    max-width: 160px;
}

.maxWidth200px {
    max-width: 200px;
}

.maxWidth560px {
    max-width: 560px;
}
.maxWidth600px {
    max-width: 600px;
}

.maxWidth800px {
    max-width: 800px;
}

.maxWidth2000px {
    max-width: 2000px;
}

.maxHeight100px {
    max-height: 100px;
    overflow: hidden;
}

.maxHeight500pxAuto {
    max-height: 500px;
    overflow: auto;
}

.width130px {
    width: 130px;
}

.width132px {
    width: 132px;
}

.width190px {
    width: 190px;
}

.width500px {
    width: 500px;
}

.minWidth364px {
    min-width: 364px;
}

.maxWidth82 {
    max-width: 82px;
}

.maxWidth5em {
    max-width: 5em;
}

.marginLeft0 {
    margin-left: 0;
}

.marginRight0 {
    margin-right: 0px;
}

.marginBottom0 {
    margin-bottom: 0;
}

.marginTop0 {
    margin-top: 0;
}

.marginTop2 {
    margin-top: 2px;
}

.marginLeft2 {
    margin-left: 2px;
}

.marginLeft5 {
    margin-left: 5px;
}

.marginLeft8 {
    margin-left: 8px;
}

.marginTop5 {
    margin-top: 5px;
}

.marginBottom5 {
    margin-bottom: 5px;
}

.marginRight5 {
    margin-right: 5px;
}

.marginRight8 {
    margin-right: 8px;
}

.paddingBottom0 {
    padding-bottom: 0;
}

.marginTop8 {
    margin-top: 8px;
}

.marginBottom8 {
    margin-bottom: 8px;
}

.margin0 {
    margin: 0px;
}

.margin10 {
    margin: 10px;
}

.padding10 {
    padding: 10px;
}

.marginTop10 {
    margin-top: 10px;
}

.marginBottom10 {
    margin-bottom: 10px;
}

.marginLeft10 {
    margin-left: 10px;
}

.marginRight10 {
    margin-right: 10px;
}

.marginTop15 {
    margin-top: 15px;
}

.marginBottom15 {
    margin-bottom: 15px;
}

.marginRight15 {
    margin-right: 15px;
}

.padding15 {
    padding: 15px;
}

.paddingBottom16 {
    padding-bottom: 16px;
}

.paddingBottom20 {
    padding-bottom: 20px;
}

.marginTop20 {
    margin-top: 20px;
}

.marginBottom20 {
    margin-bottom: 20px;
}

.marginLeft20 {
    margin-left: 20px;
}

.marginRight20 {
    margin-right: 20px;
}

.marginTop25 {
    margin-top: 25px;
}

.marginBottom25 {
    margin-bottom: 25px;
}

.marginTop30 {
    margin-top: 30px;
}

.marginLeft30 {
    margin-left: 30px;
}

.marginBottom30 {
    margin-bottom: 30px;
}

.marginTop40 {
    margin-top: 40px;
}

.marginBottom40 {
    margin-bottom: 40px;
}

.marginLeft50 {
    margin-left: 50px;
}

.marginTop55 {
    margin-top: 55px;
}

.marginBottom60 {
    margin-bottom: 60px;
}

.padding0 {
    padding: 0 !important;
}

.paddingTop0 {
    padding-top: 0;
}

.paddingLeft0 {
    padding-left: 0px;
}

.paddingTop2 {
    padding-top: 2px;
}

.paddingTop3 {
    padding-top: 3px;
}

.paddingBottom3 {
    padding-bottom: 3px;
}

.padding5 {
    padding: 5px;
}

.paddingTop5 {
    padding-top: 5px;
}

.paddingBottom5 {
    padding-bottom: 5px;
}

.paddingLeft5 {
    padding-left: 5px;
}

.paddingRight5 {
    padding-right: 5px;
}

.padding6 {
    padding: 6px;
}

.paddingTop10 {
    padding-top: 10px;
}

.paddingLeft10 {
    padding-left: 10px;
}

.paddingRight10 {
    padding-right: 10px;
}

.paddingLeft15 {
    padding-left: 15px;
}

.paddingRight15 {
    padding-right: 15px;
}

.padding20 {
    padding: 20px;
}

.paddingTop20 {
    padding-top: 20px;
}

.paddingTop25 {
    padding-top: 25px;
}

.paddingLeft20 {
    padding-left: 20px;
}

.paddingRight20 {
    padding-right: 20px;
}

.paddingLeft32 {
    padding-left: 32px;
}

.padding10px20px {
    padding: 10px 20px;
}

.padding70px25px {
    padding: 70px 25px;
}

.activityName {
    // font-size: 16px;
    font-size: 0.889rem;
    color: #222;
}

.size14 {
    // font-size: 14px;
    font-size: 0.778rem;
    line-height: 1.2;
}

.greyText {
    color: #767676;
}

.whiteText {
    color: #fff;
}

.fontBold {
    font-weight: bold;
}

.fontNormal {
    font-weight: normal;
}

.textSuccess {
    color: #39b54a;
}

.textAlignLeft {
    text-align: left;
}

.textAlignCenter,
.textCenteralize {
    text-align: center;
}

.textAlignRight {
    text-align: right;
}

.height15px {
    height: 15px;
}

.height100 {
    height: 100%;
}

.height100px {
    height: 100px;
}

.heightAuto {
    height: auto;
}

.heightMax100px {
    height: auto;
    max-height: 100px;
    overflow: auto;
}

.heightMax200px {
    height: auto;
    max-height: 200px;
    overflow: auto;
}

.maxHeight420px {
    max-height: 420px;
}

.width10px {
    width: 10px;
}

.width18px {
    width: 18px;
}

.width20 {
    width: 20%;
}

.width24px {
    width: 24px;
}

.width30px {
    width: 30px;
}

.width36px {
    width: 36px;
}

.width38px {
    width: 38px;
}

.widthMax36px {
    max-width: 36px;
}

.width50px {
    width: 50px;
}

.width65px {
    width: 65px;
}

.width65pxImp {
    width: 65px !important;
}

.width80px {
    width: 80px;
}

.width82 {
    width: 81%;
}

.minWidth95px {
    min-width: 95px;
}

.width95px {
    width: 95px;
}

.maxWidth95px {
    max-width: 95px;
}

.width98px {
    width: 98px;
}

.width100px {
    width: 100px;
}

.width100 {
    width: 100%;
}

.width103px {
    width: 103px;
}

.width105px {
    width: 105px;
}

.width108px {
    width: 108px;
}

.minWidth108px {
    min-width: 108px;
}

.minWidth110px {
    min-width: 110px;
}

.width110px {
    width: 110px;
}

.maxWidth110px {
    max-width: 110px;
}

.width113px {
    width: 113px;
    max-width: 115px;
}

.minWidth115px {
    min-width: 115px;
}

.width115px {
    width: 115px;
}

.maxWidth115px {
    max-width: 115px;
}

.width118px {
    width: 118px;
}

.minWidth118px {
    min-width: 118px;
}

.maxWidth118px {
    max-width: 118px;
}

.width120px {
    width: 120px;
}

.minWidth123px {
    min-width: 123px;
}

.width123px {
    width: 123px;
}

.maxWidth123px {
    max-width: 123px;
}

.minWidth125px {
    min-width: 125px;
}

.width125px {
    width: 125px;
}

.maxWidth125px {
    max-width: 125px;
}

.width130px {
    width: 130px;
}

.width132px {
    width: 132px;
}

.minWidth132px {
    min-width: 132px;
}

.minWidth140px {
    min-width: 140px;
}

.width140px {
    width: 140px;
}

.maxWidth140px {
    max-width: 140px;
}

.width150px {
    width: 150px;
}

.width200px {
    width: 200px;
}

.width160px {
    width: 160px;
}

.width166px {
    width: 166px;
}

.width192px {
    width: 192px;
}

.minWidth194px {
    min-width: 194px;
}

.minWidth250px {
    min-width: 250px;
}

.width250px {
    width: 250px;
}

.maxHeight255px {
    max-height: 255px;
}

.width266px {
    width: 266px;
}

.width180px {
    width: 180px;
}

.width200px {
    width: 200px;
}

.minWidth350px {
    min-width: 350px;
}

.width350px {
    width: 350px;
}

.maxWidth350px {
    max-width: 350px;
}

.width370px {
    width: 370px;
}

.width400px {
    width: 400px;
}

.width600px {
    width: 600px;
}

.fs-10px {
    font-size: 10px;
}

.fontSize12 {
    font-size: 12px;
}

.fontSize14,
.fs-14px {
    font-size: 0.778rem;
}

.fontSize16 {
    font-size: 16px;
}

.fontSize18,
.fs-18px {
    // font-size: 18px;
    font-size: 1rem;
}

.fontSize20,
.font20 {
    font-size: 20px;
}

.fontSize22,
.fs-22px {
    font-size: 1.25rem;
}

.fontSize24 {
    font-size: 24px;
}

.font30 {
    font-size: 30px;
}

.fontSize26 {
    font-size: 26px;
}

.fontSize32 {
    font-size: 32px;
}

.displayInlineTable {
    display: inline-table;
}

.fontBlack,
.fa-sort-amount-asc,
.fa-sort-amount-desc,
.fa-sort {
    color: #222;
}

.fontRed {
    color: #dc3c42;
}

.fontGrey {
    color: #767676;
}

.lineHeight1 {
    line-height: 1;
}

.lineHeight1_2 {
    line-height: 1.2;
}

textarea,
textarea:focus {
    // border: 1px solid #dfe3e9;
    border: 1px solid #767676;
    background: #fff;
    color: #222;
    resize: none;
    // outline: none !important;
}

textarea::-webkit-input-placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder,
textarea::placeholder {
    color: #767676;
}

textarea::-webkit-input-placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder,
textarea::placeholder,
::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
    font-style: italic;
}

.floatRight {
    float: right;
}

.floatLeft {
    float: left;
}

.borderLeft0 {
    border-left: 0;
}

.wordBreakAll {
    word-break: break-all;
}

.whiteSpacePreWrap {
    white-space: pre-wrap;
}

.whiteSpacePreLine {
    white-space: pre-line;
}

.wordBreakWord {
    overflow-wrap: break-word;
    word-break: break-word;
}

.lineHeight08 {
    line-height: 0.8;
}

/* End of additional css */

.highlight {
    background-color: rgba(167, 255, 239, 0.5);
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 14px;
    // font-size: 0.875em;
}

.blue {
    color: #1991eb;
}

.displayNone {
    display: none;
}

// .actual-image {
//   width:100%;
//   height:100%;
// }

.collapseText {
    font-size: 14px;
    // font-size: 0.875em;
    text-transform: capitalize;
    line-height: 0.5;
}

.collapseText i {
    font-size: 10px;
    // font-size: 0.625em;
    vertical-align: middle;
}

.input-group {
    margin: 0;
}

.displayInlineBlock {
    display: inline-block;
}

.fontItalic {
    font-style: italic;
}

.pointer {
    cursor: pointer;
}

.no-error .help-block {
    display: none;
}
.no-error.has-error .help-block {
    display: block;
}

/* background color */

.colorRedBg,
.barProgress.colorRedBg {
    //background red
    background-color: #ff000a;
}

.colorGreenBg,
.barProgress.colorGreenBg {
    // background greeen
    // background-color: #29cb97;
    background-color: #1b8360;
}

.colorYellowBg,
.barProgress.colorYellowBg {
    // background yellow
    background-color: #ffca17;
}

.colorBlueBg,
.barProgress.colorBlueBg {
    // background blue
    background-color: #4072ee;
}

/* end of background color */

.panel-div {
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
}

.border1pxGrey {
    border: 1px solid #d8d8d8;
}

.borderTop1pxGrey {
    border-top: 1px solid #d8d8d8;
}

.borderBottom1pxGrey {
    border-bottom: 1px solid #d8d8d8;
}

.borderBottom2pxGrey {
    border-bottom: 2px solid #d8d8d8;
}

.boxShadow {
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
}

.borderRadius3px {
    border-radius: 3px;
}

.br-0 {
    border-radius: 0;
}

.backgroundGradient {
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
}

.clear {
    clear: both;
}

.padding6px12px {
    padding: 6px 12px;
}

// daterangepicker

.daterangepicker.ltr .calendar.left {
    margin: 0 !important;
}

.daterangepicker {
    .glyphicon-chevron-right:before {
        font-family: 'Font Awesome 5 Free';
        content: '\f054';
        font-weight: 900;
    }

    .glyphicon-chevron-left:before {
        font-family: 'Font Awesome 5 Free';
        content: '\f053';
        font-weight: 900;
    }

    .table-condensed {
        border: none;
        box-shadow: none;
    }

    .calendar-table {
        padding: 0 !important;
    }
}

.toggleArrow {
    &:before {
        content: 'Less ';
        text-transform: capitalize;
        font-size: 12px;
    }
    &:after {
        font-family: 'Font Awesome 5 Free';
        content: '\f0d8';
        font-size: 12px;
        // font-size: 0.75em;
        font-weight: 900;
    }
}

.collapsed .toggleArrow {
    &:before {
        content: 'More ';
        text-transform: capitalize;
        font-size: 12px;
        // font-size: 0.75em;
    }
    &:after {
        font-family: 'Font Awesome 5 Free';
        content: '\f0d7';
        font-size: 12px;
        // font-size: 0.75em;
        font-weight: 900;
    }
}

.vcenter {
    vertical-align: middle !important;
}

.cursor-norm {
    cursor: inherit;
}

.previewModelTeam ul {
    list-style-type: none;
    padding: 0;
}

.minWidth82px {
    min-width: 82px;
}

.with-top-gaps {
    margin-top: 10px;
}

.with-top-gaps:first-child {
    margin-top: 0;
}

.disabled-background {
    background: #d8d8d8 !important;
}

.right-container {
    box-sizing: border-box;
    padding: 20px 20px 60px 20px;
}

.payment-alert {
    padding-left: 25px;
    padding-right: 25px;
}

.dashboard.right-container {
    box-sizing: border-box;
    padding-top: 20px;
}

.right-container.timer {
    padding: 130px 25px 61px 25px;
}

.test .timer ~ .right-container {
    // padding: 130px 25px 73px 25px !important;
    padding: 91px 25px 73px 25px !important;
}

.test .timer ~ .right-container.group {
    // padding: 130px 25px 73px 25px !important;
    padding: 91px 25px 73px 25px !important;
}

.test .right-container {
    padding: 91px 25px 73px 25px !important;
}

.test .right-container.group {
    padding: 91px 25px 73px 25px !important;
}

@media (max-width: 991.98px) {
    .right-container {
        // padding-top: 60px;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .payment-alert {
        padding-left: 20px;
        padding-right: 20px;
    }

    .dashboard.right-container {
        box-sizing: border-box;
        padding-top: 80px;
    }
}

.p-0-i {
    padding: 0 !important;
}

.presentation-mode-cr {
    .nav-tabsContainer {
        margin-top: 0;
    }

    .links {
        border-bottom: 0.1875em solid transparent;
    }
}

.night-mode .modal-header {
    background: #273142;
    color: #fff;
}

.night-mode .modal-header button {
    color: #fff;
}

.night-mode .modal-title {
    color: #fff;
}

.night-mode .modal-body {
    background: #273142;
    color: #fff;
}

.night-mode .with-border,
.night-mode .steps-circle {
    background: #fff;
    color: #222;
}

.night-mode .panel-heading {
    background: #273142 !important;
    color: #fff;
}

.night-mode .panel-body {
    background: #273142;
    color: #fff;
}

.panel-body-content {
    border: none !important;
    padding: 0 !important;
    background: none !important;
}

.night-mode .panel-body-content {
    background: #273142;
    border: none;
    padding: 0;
}

.cr-display-columns {
    &.row {
        display: flex;
        flex-wrap: wrap;
    }

    .new-discussion-ans {
        > div {
            width: 100%;
        }
    }
    .new-discussion {
        width: 100%;
        margin-top: 0;
        padding: 0;

        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        vertical-align: top;

        & > div:first-child {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 10px;
            align-items: center;
            min-height: 48px;
            height: auto;

            .thumb-up-toggle input[type='checkbox']:before {
                font-size: 24px;
                // font-size: 1.5em;
            }

            .top-right-votes {
                font-weight: bold;
                color: #1a91eb;
                font-size: 18px;
                // font-size: 1.125em;
            }
        }

        .new-discussion-ans-content {
            flex: 1 1 auto;
        }

        &.twoColumns {
            @media (max-width: 992px) {
                &:not(:first-child) {
                    margin-top: 20px;
                }
            }

            @media (min-width: 992px) {
                width: 49%;

                &:nth-of-type(odd) {
                    margin-right: 1%;
                }

                &:nth-of-type(even) {
                    margin-left: 1%;
                }

                .new-discussion-answer {
                    height: 130px;
                }
            }
        }

        &.threeColumns {
            @media (max-width: 1200px) {
                &:nth-child(n + 3) {
                    margin-top: 20px;
                }
            }

            @media (max-width: 992px) {
                &:not(:first-child) {
                    margin-top: 20px;
                }
            }

            @media (min-width: 992px) {
                width: 49%;

                &:nth-of-type(odd) {
                    margin-right: 1%;
                }

                &:nth-of-type(even) {
                    margin-left: 1px;
                }

                .new-discussion-answer {
                    height: 130px;
                }
            }

            @media (min-width: 1200px) {
                width: 32.6%;

                &:nth-of-type(odd) {
                    margin-right: 1%;
                }

                &:nth-of-type(even) {
                    margin-right: 1%;
                }

                &:nth-child(n + 4) {
                    margin-top: 20px;
                }

                &:nth-child(3n + 3) {
                    margin-right: 0;
                }

                .new-discussion-answer {
                    height: 157px;
                }
            }
        }

        &.fourColumns {
            @media (max-width: 1200px) {
                &:nth-child(n + 4) {
                    margin-top: 20px;
                }
            }

            @media (max-width: 992px) {
                &:not(:first-child) {
                    margin-top: 20px;
                }
            }

            @media (min-width: 992px) {
                width: 49%;

                &:nth-of-type(odd) {
                    margin-right: 1%;
                }

                &:nth-of-type(even) {
                    margin-left: 1%;
                }

                .new-discussion-answer {
                    height: 130px;
                }
            }

            @media (min-width: 1200px) {
                width: 23.7%;

                &:nth-of-type(odd) {
                    margin-right: 1%;
                }

                &:nth-of-type(even) {
                    margin-right: 1%;
                }

                &:nth-child(n + 5) {
                    margin-top: 20px;
                }

                &:nth-child(4n + 4) {
                    margin-right: 0;
                }

                .new-discussion-answer {
                    height: 204px;
                }
            }
        }
    }
}

.displayAnswers {
    color: #fff;

    .modal-header {
        background: #273142;
        display: flex;
        width: 100%;
        align-items: center;

        .modal-title {
            color: #fff;
            width: 90%;
        }
    }

    .modal-body {
        background: #273142;

        & > div.inline-flex:not(:first-child) {
            margin-top: 20px;
        }

        .inline-flex {
            display: inline-flex;
            align-items: center;
            width: 100%;

            button {
                max-width: 70px;
                margin-right: 10px;
            }

            span {
                width: calc(100% - 105px);
            }

            .checkboxField input[type='checkbox'] {
                min-width: 20px;
                height: 20px;
                background: #273142;
                box-shadow: none;
                border: solid 2px #d4d4d4;

                &:checked {
                    border: none;

                    &:before {
                        font-size: 26px;
                        // font-size: 1.625em;
                        width: 20px;
                    }
                }
            }
        }
    }
}

.displayInherit {
    display: inherit;
}
.course-thumbnail,
.institution-thumbnail {
    width: 400px;
    height: 200px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .preview-image,
    .actual-image {
        margin: 0;
    }
}

.pageTitle {
    margin-bottom: 20px;

    h1 {
        margin-top: 0;
        // font-size: 24px;
        font-size: 1.333rem;
        font-weight: bold;
        margin-bottom: 4px;
    }

    p {
        color: #767676;
        // font-size: 14px;
        font-size: 1rem;
        line-height: 1.5;
    }

    a {
        // color: #1991eb;
        color: #1071bc;

        .fa-info-circle {
            font-size: 12px;
            // font-size: 0.75em;
        }
    }
}

.panelTitle {
    margin-bottom: 20px;
    h3 {
        margin-top: 0;
        font-size: 20px;
        // font-size: 1.5em;
        font-weight: bold;
        margin-bottom: 4px;
        color: #767676;
    }

    p {
        color: #767676;
        font-size: 14px;
        // font-size: 0.875em;
        line-height: 1.5;
    }

    a {
        color: #1991eb;

        .fa-info-circle {
            font-size: 12px;
            // font-size: 0.75em;
        }
    }
}

.opacity-6 {
    opacity: 0.6;
}

.lh-1-2 {
    line-height: 1.2;
}

.lh-0-8 {
    line-height: 0.8;
}

.border-collapse {
    border-collapse: collapse;
}

.btn-group {
    display: inline-flex;
}

.minWidth5em {
    min-width: 5em;
}

.test-customize-header {
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
    border: 1px solid #d8d8d8;
    border-bottom: 0;
}

.bt-0 {
    border-top: 0;
}

.td-underline {
    text-decoration: underline;
}

.panel-content-subHeader {
    font-weight: bold;
    text-transform: uppercase;
    // color: #989b9c;
    margin-top: 0;
    margin-bottom: 15px;
    line-height: 1;
    font-size: 20px;
}

.table-keyword-highlight {
    background-color: #ffff00;
}

.whiteSpaceNowrap {
    white-space: nowrap;
}

a.link.noBg {
    &:hover {
        background: none;
    }
}

a.disabled,
a.link.disabled {
    color: #c6c6c6;
}

.itemAnalysisTable {
    table {
        border: solid 1px #d8d8d8;

        th {
            background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
        }

        tr:not(:last-child) {
            th,
            td {
                border-bottom: solid 1px #d8d8d8;
            }
        }
    }

    .row {
        &:not(:last-child) {
            margin-bottom: 10px;
        }

        .card {
            &:not(:last-child) {
                margin-bottom: 10px;
            }

            .card-header {
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                padding: 8px;
                font-weight: bold;

                border: solid 1px #d8d8d8;
                background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
            }

            .card-body {
                padding: 8px;
                border: solid 1px #d8d8d8;
                border-top: 0;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
            }
        }
    }

    @media (max-width: 345px) {
        display: block;

        .col-xs-4.col-sm-4.col-md-4 {
            width: 100%;
            margin-top: 20px;
        }
    }

    @media (min-width: 346px) {
        .col-xs-4.col-sm-4.col-md-4 {
            padding-left: 10px;
        }
    }
}

.visible-hidden {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.visible-hidden:focus {
    clip: auto;
    height: auto;
    overflow: auto;
    position: absolute;
    width: auto;
}

.borderNone {
    border: none;
}

.col-xs-offset-2 {
    margin-left: 16.66666667%;
}

@media (min-width: 992px) {
    .col-sm-offset-2 {
        margin-left: 16.66666667%;
    }
}

.zIndex1001 {
    z-index: 1001;
}

.alert-modal,
.warning-modal,
.primary-modal,
.danger-modal,
.default-modal {
    .warning-icon {
        position: absolute;
        left: 45%;
        // top: -6.5%;
        top: -20px;
        width: 50px;
    }

    .modal-header {
        background: #d04343;
        padding: 16px;

        .modal-title {
            color: #fff;
            font-size: 24px;
        }

        .close {
            height: auto;

            i {
                font-size: 1.4rem;
            }
        }
    }

    .modal-body {
        padding-top: calc(20px + 5%);
        // font-size: 1.25rem;
        font-size: 0.875rem;
        padding: 16px;

        dl {
            dt,
            dd {
                font-size: 1.111rem;
            }
        }

        p {
            font-size: 1.111rem;
        }

        .questionBankDetailsContent {
            .header {
                background: #495060;
                padding: 5px 10px;
                margin-bottom: 5px;

                label {
                    color: #fff;
                }
            }

            p {
                font-size: 1rem;
            }
        }

        .questionBankModalText {
            color: #767676;
            font-size: 1rem;
        }
    }

    @media (min-width: 992px) {
        .modal-footer {
            .btn + .btn {
                margin-left: 16px;
            }
        }
    }

    @media (max-width: 992px) {
        .modal-footer {
            .btn + .btn {
                margin-left: 0;
            }
        }
    }
}

.warning-modal,
.primary-modal,
.default-modal {
    .modal-header {
        background: #e69b23;

        .modal-title {
            color: #222;
        }

        .close {
            color: #222;
        }
    }

    .modal-body {
        padding: 20px;

        h2 {
            font-weight: bold;

            // @media (min-width: 992px) {
            //     display: flex;
            //     align-items: center;
            //     justify-content: center;
            // }
        }

        .fs-16 {
            font-size: 0.889rem;
        }

        .timer {
            display: inline-block;
            padding: 5px 10px;
            background: #ffffff;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
            color: #2d8659;

            @media (min-width: 992px) {
                margin-left: 5px;
            }
        }
    }
}

.primary-modal {
    .modal-header {
        background: #23356d;

        .modal-title {
            color: #fff;
        }

        .close {
            color: #fff;
        }
    }
}

.default-modal {
    .modal-header {
        background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
    }
}

.primary-modal,
.warning-modal,
.danger-modal {
    @media (min-width: 450px) {
        .modal-footer {
            .btn + .btn {
                margin-left: 16px;
                margin-top: 0;
            }
        }
    }

    @media (max-width: 450px) {
        .modal-footer {
            .btn {
                width: 100%;
            }

            .btn + .btn {
                margin-left: 0;
                margin-top: 20px;
            }
        }
    }
}

.danger-modal {
    .modal-header {
        .close {
            color: #fff;
        }
    }
}

.checkboxField {
    [type='checkbox'] {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        background-color: #ffffff;
        border: solid 2px #222;
        box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
        background-size: 0 0;
        // transition: background-size 0.28s cubic-bezier(0.4, 0, 0.2, 1);

        &:not(:only-child) {
            margin-right: 10px;

            &.marginRight0 {
                margin-right: 0;
            }
        }

        &:checked {
            background: #1b83c7;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
            box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
            background-size: 100% 100%;
            border-color: #1b83c7;

            &:before {
                display: none;
            }

            &:disabled {
                box-shadow: inherit;
            }
        }

        &:disabled.disabled,
        &.disabled {
            background: #cccccc;
            border: 2px solid #cccccc;
            box-shadow: inherit;

            &:hover {
                background: #cccccc;
                border: 1px solid #cccccc;
            }

            &:checked {
                background: #cccccc;
                border: 1px solid #cccccc;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23767676' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
                background-size: 100% 100%;
            }

            &.hasToolTip {
                pointer-events: inherit;
            }
        }
    }

    input[disabled],
    input.disabled {
        background: #cccccc;
        border: 2px solid #cccccc;
        box-shadow: none;

        &:checked:before {
            color: #cccccc;
        }

        &.hasToolTip {
            pointer-events: inherit;
        }
    }
}

.form-switch,
.switch {
    position: relative;
    display: inline-block;
    min-width: 64px;
    width: 64px;
    height: 32px;

    &:focus-within {
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
        border-radius: 34px;
    }

    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #c8c8c8;
        -webkit-transition: 0.4s;
        transition: 0.4s;

        &:before {
            position: absolute;
            content: '';
            height: 24px;
            width: 24px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z' fill='%23FFFFFF' /%3E%3Cpath d='m4 13H8c-.55 0-1-.45-1-1s.45-1 1-1h8c.55 0 1 .45 1 1s-.45 1-1 1z' fill='%233C3C43' /%3E%3C/svg%3E");
        }

        &.round {
            border-radius: 34px;

            &:before {
                border-radius: 50%;
            }
        }
    }

    input {
        opacity: 0;
        // width: 0;
        // height: 0;
        width: 64px;
        height: 32px;

        & + .slider {
            cursor: pointer;
        }

        &:disabled,
        &.disabled {
            & + .slider {
                background-color: #efefef;
                cursor: not-allowed;

                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z' fill='%23FFFFFF' /%3E%3Cpath d='m4 13H8c-.55 0-1-.45-1-1s.45-1 1-1h8c.55 0 1 .45 1 1s-.45 1-1 1z' fill='%233c3c4c' /%3E%3C/svg%3E");
                }
            }
        }

        &:checked {
            & + .slider {
                background-color: #1b83c7;

                &:before {
                    -webkit-transform: translateX(32px);
                    -ms-transform: translateX(32px);
                    transform: translateX(32px);
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z' fill='%23fff' /%3E%3Cpath d='M9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z' fill='%231B83C7'/%3E%3C/svg%3E");
                }
            }

            &:disabled,
            &.disabled {
                & + .slider {
                    background-color: #efefef;

                    &:before {
                        -webkit-transform: translateX(32px);
                        -ms-transform: translateX(32px);
                        transform: translateX(32px);
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z' fill='%23fff' /%3E%3Cpath d='M9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z' fill='%233c3c4c'/%3E%3C/svg%3E");
                    }
                }
            }
        }

        &:focus {
            & + .slider {
                box-shadow: 0 0 1px #2196f3;
            }
        }
    }
}

.isStudent,
.test,
.newRadioField,
.questionBankOptionsField,
.presentationMode {
    input {
        border-radius: 8px;
        border-color: #767676;

        // &[type='text'] {
        //     &:focus {
        //         border: 3px solid #0071eb;
        //     }

        //     &.isTabbed {
        //         &:focus {
        //             border: 3px solid #1941a6;
        //         }
        //     }
        // }

        &[type='radio'] {
            width: 1.5rem;
            height: 1.5rem;
            background-color: #ffffff;
            border: 1px solid #767676;
            box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
            background-size: 0 0;
            // transition: background-size 0.28s cubic-bezier(0.4, 0, 0.2, 1);

            &:checked {
                border-color: none;
                border: 1px solid #767676;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23761FBB'/%3e%3c/svg%3e");
                box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
                background-size: 100% 100%;

                &:before {
                    display: none;
                }

                &:disabled {
                    box-shadow: inherit;
                }
            }

            &:hover {
                border: 1px solid #767676;
                box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
                outline: 10px solid #e7dcf9;
            }

            &:not(:only-child) {
                margin-right: 10px;
            }

            &:disabled,
            &.disabled {
                background: #efefef;
                border: 1px solid #efefef;
                box-shadow: inherit;

                &:hover {
                    background: #efefef;
                    box-shadow: none;
                    outline: none;
                }

                &:checked {
                    background: #efefef;
                    border: 1px solid #efefef;
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238B8B8F'/%3e%3c/svg%3e");
                    background-size: 100% 100%;
                }
            }
        }

        &[type='checkbox'] {
            width: 1.5rem;
            height: 1.5rem;
            background-color: #ffffff;
            border: 1px solid #767676;
            box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
            background-size: 0 0;
            // transition: background-size 0.28s cubic-bezier(0.4, 0, 0.2, 1);

            &:not(:only-child) {
                margin-right: 10px;
            }

            &:checked {
                border-color: none;
                background: #761fbb;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
                box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
                background-size: 100% 100%;
                border-color: #761fbb;

                &:before {
                    display: none;
                }

                &:disabled {
                    box-shadow: inherit;
                }
            }

            &:disabled,
            &.disabled {
                background: #efefef;
                border: 1px solid #efefef;
                box-shadow: inherit;

                &:hover {
                    background: #efefef;
                    border: 1px solid #efefef;
                }

                &:checked {
                    background: #efefef;
                    border: 1px solid #efefef;
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23767676' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
                    background-size: 100% 100%;
                }
            }
        }
    }

    .radioField,
    .checkboxField {
        padding: 8px;
        border-radius: 8px;
        outline: 4px solid transparent;
        border: 4px solid transparent;

        &.paddingLeft35px {
            padding-left: 35px;
        }

        &:focus-within {
            // background: #ffefe0;
            outline: 4px solid #1941a6;
            border: 4px solid rgb(255, 255, 255);

            [type='radio'],
            [type='checkbox'] {
                &:focus-visible {
                    outline: none;
                }
            }
        }

        label {
            width: calc(100% - 45px);
            margin-left: 0;
            display: flex;
            // padding-top: 5px;

            &.widthAuto {
                width: auto;
            }

            .withOptionKey {
                font-size: 1rem;
                font-weight: bold;
                padding-right: 5px;
            }
            // span {
            //     &

            //     &:last-child {
            //         font-weight: normal;
            //     }

            //     &:only-child {
            //         font-weight: normal;
            //         padding-right: 0;
            //     }
            // }

            &.d-block {
                display: block;
            }
            &.textAlignLeft {
                text-align: left;
            }
            /*
            .form-group {
                label span {
                    font-weight: bold;
                }

                .quantity-nav {
                    right: -4px;
                }
            }*/
        }

        .numberInput {
            position: relative;
            width: 60px;
            height: 100%;

            & ~ label {
                width: calc(100% - 70px);
                padding-top: 10px;
            }

            &:not(:only-child) {
                margin-right: 10px;
            }
        }
    }

    .quantity-nav {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 10px;
        color: #222;
        z-index: 4;
        height: 100%;
        max-height: 100%;
    }

    .quantity-nav .disabled {
        color: #dfe3e9;
        cursor: not-allowed;
    }

    .quantity-button {
        min-height: 18px;
        height: 50%;
        display: flex;
        align-items: center;
        width: 22px;
        justify-content: center;
        background-color: white;
        border: 1px solid #767676;
    }

    .quantity-button:last-child {
        border: 1px solid #767676;
    }

    .correctOption {
        background-color: #f3fffd;
        border: 1px solid #0f9b81;

        .fa-check-circle {
            font-size: 18px;
            color: #0f9b81;
        }
    }
}

// 2023 Font updates
html,
body {
    // font-size: 18px;
    font-size: 1rem;
}

h1 {
    // h1 33px 1.83rem
    font-size: 1.83rem;
    font-weight: bold;
}

h2,
.fs-29px {
    // h2 29px 1.61rem
    font-size: 1.61rem;
    font-weight: bold;
}

h3 {
    // h3 26px 1.44rem
    font-size: 1.44rem;
    font-weight: bold;
}

h4,
.fs-24px {
    // h4 24px 1.33rem
    font-size: 1.33rem;
}

h5 {
    // h5 20px 1.11rem
    font-size: 1.11rem;
}

p,
.fontSize18,
.fs-18px {
    // p 18px 1rem
    font-size: 1rem;
}

label,
.fontSize16 {
    // label 16px 0.88rem
    font-size: 0.88rem;
}

.fw-normal {
    font-weight: normal;
}

.savedTime {
    padding: 8px;
    padding-left: calc(1.5rem + 8px + 10px);
    font-size: 0.88rem;
    font-style: italic;
}

.flexInheritBtn {
    .btn {
        flex: inherit;
        margin-left: 0;
        margin-right: 10px;
        margin-bottom: 10px;
    }
}

.autoFlex {
    display: flex;
    flex-wrap: wrap;

    .btn,
    .dropdown {
        margin-left: 0;
        flex: inherit;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 10px;

        #viewDropdownMenuButton,
        .margin0 {
            &.btn {
                margin: 0;
            }
        }
    }

    &.justify-content-right {
        justify-content: right;

        .btn,
        .dropdown {
            margin-right: 0;
            margin-left: 10px;

            #viewDropdownMenuButton {
                &.btn {
                    margin-left: 0;
                }
            }
        }
    }

    .dropdown-menu {
        .btn {
            margin: 0;
        }
    }
}

button,
.buttons,
.btn,
router-link,
.statusTag {
    padding: 6px 12px;
}

.transparent {
    &.table {
        td {
            .gradient.btn-default {
                padding: 6px;
            }
        }
    }
}

.loadingAnimation {
    display: flex;
    justify-content: center;

    &:after {
        content: ' .';
        animation: dots 1s steps(5, end) infinite;
    }
}

@keyframes dots {
    0% {
        color: rgba(0, 0, 0, 0);
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    20% {
        color: rgba(0, 0, 0, 0);
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
        color: white;
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
        text-shadow: 0.25em 0 0 #000, 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    80% {
        text-shadow: 0.25em 0 0 #000, 0.5em 0 0 #000;
    }
    100% {
        text-shadow: 0.25em 0 0 #000, 0.5em 0 0 #000;
    }
}

.merge2ElementsFieldButton {
    display: flex;

    button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: 0;
    }
}

.form-search {
    .form-control {
        padding-right: 30px;
    }
}

.textWrap {
    white-space: inherit;
}

.flexInherit {
    flex: inherit;
}

.attendanceTag {
    &-locked {
        background: #fab8b8;
        border: 1px solid #fab8b8;
        color: #222;

        line-height: 0.8;
        border-radius: 44px;
        padding: 6px 8px;

        p {
            font-size: 0.88rem;
        }

        i {
            line-height: 0.8;
            font-size: 0.8rem;
        }
    }

    &-primary {
        background: #ebf3fe;
        border: 1px solid #ebf3fe;
        color: #222;

        line-height: 0.8;
        border-radius: 44px;
        padding: 6px 8px;

        p {
            font-size: 0.88rem;
        }

        i {
            line-height: 0.8;
            font-size: 0.8rem;
        }
    }

    &-green {
        background: #e5fff5;
        border: 1px solid #e5fff5;
        color: #222;

        line-height: 0.8;
        border-radius: 44px;
        padding: 6px 8px;

        p {
            font-size: 0.88rem;
        }

        i {
            line-height: 0.8;
            font-size: 0.8rem;
        }
    }

    &-gray {
        background: #efefef;
        border: 1px solid #efefef;
        color: #8b8b8f;

        line-height: 0.8;
        border-radius: 44px;
        padding: 6px 8px;

        p {
            font-size: 0.88rem;
        }

        i {
            line-height: 0.8;
            font-size: 0.8rem;
        }
    }

    &-purple {
        background: #f3e8fc;
        border: 1px solid #f3e8fc;
        color: #3c3c43;

        line-height: 0.8;
        border-radius: 44px;
        padding: 6px 8px;

        p {
            font-size: 0.88rem;
        }

        i {
            line-height: 0.8;
            font-size: 0.8rem;
        }
    }

    &-blue {
        background: #dbfbff;
        border: 1px solid #dbfbff;
        color: #3c3c43;

        line-height: 0.8;
        border-radius: 44px;
        padding: 6px 8px;

        p {
            font-size: 0.88rem;
        }

        i {
            line-height: 0.8;
            font-size: 0.8rem;
        }
    }
}

.timerTag {
    &-earlyStart {
        border: 1px solid #0071eb;
        color: #222;
        line-height: 0.8;
        padding: 4px 8px;

        p {
            font-size: 0.88rem;
        }
    }

    &-success {
        border: 1px solid #2d8659;
        color: #2d8659;
        line-height: 0.8;
        padding: 4px 8px;

        p {
            font-size: 0.88rem;
        }
    }

    &-ended {
        border: 1px solid #767676;
        color: #767676;
        line-height: 0.8;
        padding: 4px 8px;

        p {
            font-size: 0.88rem;
        }
    }

    &-primary {
        border: 1px solid #0071eb;
        color: #0071eb;
        line-height: 0.8;
        padding: 4px 8px;

        p {
            font-size: 0.88rem;
        }
    }
}

.color-success {
    color: #2d8659;
}

.color-danger {
    color: #d04343;
}

.color-dark-orange {
    color: #ac631b;
}

.border0 {
    border: 0;
}

.toggle-token {
    input {
        appearance: none;
        display: none;
        height: 0;
        width: 0;

        & + label {
            padding: 6px 12px;
            padding-top: 8px;
            border-radius: 8px;
            background: #2d8659;
            color: #fff;
        }

        &:checked + label {
            background: #cccccc;
            color: #222;
        }
    }
}

.card-body-cons,
.word-wrap-break-word {
    word-wrap: break-word;
}

.tox-tinymce {
    &:focus-within {
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255 255 255);
    }
}

.bs-0 {
    box-shadow: none;
}

.accommodationsStudentList {
    border-color: #efefef;
    border-top: 0;
    border-collapse: collapse;

    thead {
        background: #e0e0e0;
    }

    th {
        border-right-color: #efefef;
        padding: 6px 12px;
    }

    tr {
        border-top: 1px solid #e0e0e0;

        td {
            border-right-color: #efefef;
            padding: 6px 12px;
        }
    }

    &.tableBodyFixedHeight {
        thead,
        tbody tr {
            display: table;
            width: 100%;
            table-layout: fixed;
        }

        tbody {
            display: block;
            max-height: 400px;
            overflow-y: auto;
        }

        .selectAllFixedWidth {
            width: 45px;
            max-width: 45px;
        }
    }
}

.color-purple {
    color: #761fbb;
}

.panel-default {
    .panel-collapse {
        .panel-body.borderNone {
            border: none;
        }
    }
}

.overflowYHidden {
    overflow-y: hidden;
}

mark {
    &.offWhiteHighlight {
        background: #e2e2e2;
    }
}
div {
    &.scrollable-table-wrapper {
        overflow: auto;
        max-height: 500px;
        min-height: 14px;
    }
}

.color-dark-blue {
    color: #0071eb;
}

.fa-stack {
    &.small {
        font-size: 0.6rem;

        i {
            vertical-align: middle;
        }
    }
}

.background-EFEFEF {
    background: #efefef;
}

.whiteSpaceInherit {
    white-space: inherit;
}
.new-primary-color {
    &:hover {
        color: #0071eb;
    }
}

.question {
    &.presentationMode {
        img {
            max-width: 100%;
        }
    }
}

.publishAccommodationsBlue {
    color: #1891eb;
}

.right-corner-triangle {
    position: absolute;
    top: 0;
    right: 0;
    width: 65px;
    height: 35px;
    text-align: right;
    padding: 5px 5px;
    color: #222;
    background: yellow;
    clip-path: polygon(100% 100%, 100% 0%, 0% 0%);
}

.position-relative {
    position: relative;
}

.copiedIndicationBar {
    background: yellow;
    padding: 0px 20px;
    text-align: center;
    text-transform: uppercase;

    label {
        font-weight: normal;
    }
}

.vue-simple-suggest.designed {
    .input-wrapper {
        input {
            border: 1px solid #767676 !important;

            &:focus {
                border: 1px solid #1991eb !important;
                outline: 0;
            }

            &:focus-visible {
                border: 1px solid #1991eb !important;
                outline: 4px solid #1941a6;
            }
        }
    }
}

.overflowAuto {
    overflow: auto;
}

.questionBankTags {
    padding-left: 7px;
    padding-right: 7px;

    li {
        display: inline;
    }

    .tag {
        background-color: #495060;
        border-radius: 4px 3px 3px 4px;
        position: relative;
        color: #fff;
        display: inline-block;
        font-size: 1rem;
        line-height: 22px;
        padding: 0 8px 0 10px;
        margin: 4px 4px 4px 8px;

        &:before {
            content: '';
            position: absolute;
            left: -5px;
            top: 1px;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-right: 6px solid #495060;
            border-bottom: 10px solid transparent;
        }

        &:after {
            border: none;
        }
    }
}

.div-focusable {
    &:focus-visible {
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255 255 255);
    }
}

.custom-arrow-icon {
    width: 10px;
    height: 22px;
    transform: translateX(3px) translateY(0);
    transition: transform 0.1s;

    &-top,
    &-bottom {
        background-color: #222;
        height: 2px;
        left: -5px;
        position: absolute;
        top: 50%;
        width: 100%;

        &:after {
            background-color: #1991eb;
            content: '';
            height: 100%;
            position: absolute;
            top: 0;
            transition: all 0.15s;
        }
    }

    &-top {
        transform: rotate(45deg);
        transform-origin: bottom right;

        &:after {
            left: 100%;
            right: 0;
            transition-delay: 0s;
        }
    }

    &-bottom {
        transform: rotate(-45deg);
        transform-origin: top right;

        &:after {
            left: 0;
            right: 100%;
            transition-delay: 0.15s;
        }
    }

    &:hover & {
        &-top {
            background-color: transparent;

            &:after {
                left: 0;
                transition-delay: 0.15s;
            }
        }

        &-bottom {
            background-color: transparent;

            &:after {
                right: 0;
                transition-delay: 0s;
            }
        }
    }

    &:active {
        transform: translateX(3px) translateY(0) scale(0.8);
    }
}

.cardCons-question-bank {
    margin: 0 0 20px 20px;
    width: calc(100% - 20px);

    font-size: inherit;
    text-align: inherit;
    font-weight: inherit;
    padding: inherit;
    height: 100%;

    .card {
        border-radius: 3px;
        background-color: #ffffff;
        border: 1px solid #d8d8d8;
        box-shadow: 4px 4px 4px 0 rgba(202, 202, 202, 0.5);
        position: relative;
    }

    .card-header {
        background-color: #495060;
        color: #ffffff;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 10px 15px;
        line-height: 1;

        div {
            white-space: nowrap;
            overflow: hidden;
        }

        position: relative;
    }

    .card-body {
        border: 1px solid transparent;

        display: flex;
        flex-wrap: wrap;
        word-wrap: break-word;
        padding: 10px 15px;
        height: 120px;

        .question-area {
            // max-height: 72%;
            overflow: hidden;
        }

        .moreLink {
            line-height: 1;
            font-size: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:hover {
            cursor: pointer;
            .custom-arrow-icon-top:after {
                left: 0;
                transition-delay: 0.15s;
            }

            .custom-arrow-icon-bottom:after {
                right: 0;
                transition-delay: 0s;
            }
        }

        &:active {
            .custom-arrow-icon {
                transform: translateX(3px) translateY(0) scale(0.8);
            }
        }

        .questionDiv {
            h1,
            h1 span {
                // h1 33px 1.83rem
                font-size: 1.83rem !important;
                font-weight: bold;
            }

            h2,
            h2 span {
                // h2 29px 1.61rem
                font-size: 1.61rem !important;
                font-weight: bold;
            }

            h3,
            h3 span {
                // h3 26px 1.44rem
                font-size: 1.44rem !important;
                font-weight: bold;
            }

            h4,
            h4 span {
                // h4 24px 1.33rem
                font-size: 1.33rem !important;
            }

            h5,
            h5 span {
                // h5 20px 1.11rem
                font-size: 1.11rem !important;
            }

            p,
            p span {
                // p 18px 1rem
                font-size: 1rem !important;
            }
        }
    }

    .card-footer {
        background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
        border-top: 1px solid #d8d8d8;
        box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
        padding: 15px;
    }

    .cardBtns {
        position: absolute;
        right: 15px;
        bottom: 48px;

        button {
            border-radius: 100px;
            padding: 6px 8.93px;
        }
    }
}

@media (min-width: 992px) {
    .cardCons-question-bank {
        width: calc(50% - 20px);
    }
}

@media (min-width: 1200px) {
    .cardCons-question-bank {
        width: calc(33.33% - 20px);

        .card-body {
            height: 150px;
        }
    }
}

.orderNo1 {
    order: 1;
}

.orderNo2 {
    order: 2;
}

.orderNo3 {
    order: 3;
}

.orderNo4 {
    order: 4;
}

.moduleActivityTable-type {
    width: 128px;

    @media (min-width: 1400px) {
        width: 192px;
    }
}

.evaluationShowMoreIcon {
    display: none;

    span {
        display: none;
    }
}

@media (min-width: 306px) {
    .evaluationShowMoreIcon {
        display: block;
    }
}

@media (min-width: 360px) {
    .evaluationShowMoreIcon {
        span {
            display: block;
        }
    }
}

.dropdownHeading {
    width: 100%;
    background: #f8f8f8;
    padding: 5px 10px;
    color: #767676;
    margin-bottom: 10px;
}
