
.test-status-question-btn[disabled] {
    color: #222;
    opacity: 0.3;
}
.test-sideBar .fas.fa-circle,
.statusBar .fas.fa-circle {
    font-size: 6px;
}

body.test .test-sideBar .panel-body {
    padding: 0;
}
@media (min-width: 992px) {
    .table.test-sideBar {
        border: none;
    }
}
.subHeader {
    display: flex;
    align-items: center;
    line-height: 0.7;
}
.panel-body.test-sideBar-panel-body {
    overflow-x: auto;
}
.test-sideBar thead tr:only-child th {
    font-size: 13px;
}

ul {
    padding: 0;
    margin: 0;

    li {
        list-style: none;

        .fa,
        .fab,
        .fal,
        .far,
        .fas {
            vertical-align: text-bottom;
        }
    }
}
.test-sideBar {
    li {
        padding: 14px 10px;
        // border-radius: 8px;

        a {
            color: #3c3c43;

            &.link {
                color: #1941a6;
            }
        }

        &:hover {
            // background: rgba(245, 158, 11, 0.6);
            // border: 1px solid rgba(245, 158, 11, 0.6);
        }

        &:active {
            // background: #C97420;
            // border: 1px solid #C97420;
        }

        &.active {
            background: #ffefe0;
            // border: 1px solid #C97420;
        }

        &:focus-within {
            outline: 4px solid #1941a6;
        }
    }

    .panel-body {
        white-space: inherit;
    }

    .isClarification {
        display: grid;
        grid-template-columns: auto 0.75fr;
        text-align: center;
        align-items: center;

        .tableStatusTag p {
            font-size: 14px;
        }
    }

    .clarificationReleased {
        display: grid;
        grid-template-columns: auto 0.75fr 0.5fr;
        text-align: center;
        align-items: center;

        a:last-child,
        p {
            font-size: 14px;
        }
    }
}
.statusBar {
    -moz-transition: width 0.5s ease-out;
    -o-transition: width 0.5s ease-out;
    -webkit-transition: width 0.5s ease-out;
    transition: width 0.5s ease-out;
    display: inline-block;
    z-index: 0;
    position: fixed;
    height: calc(100% - 10px);
    z-index: 100;

    ul {
        display: grid;
        // grid-gap: 10px;
    }

    li {
        display: grid;
        align-items: center;
        grid-column-gap: 10px;
        padding: 16px 8px;
        border-left: 4px solid transparent;

        &:not(:first-child) {
            padding-top: 16px;
        }

        a:first-child {
            color: #3c3c43;
        }

        &:hover {
            background: #e7dcf9;
        }

        &:focus-visible {
            background: #e7dcf9;
            outline: 5px solid #1941a6;
            outline-offset: 3px;
        }

        &.haveAnswered {
            background: #2d8659;
            a:first-child {
                color: #fff;
            }
        }

        &.active {
            background: #fff;
            border-left: 5px solid #0071eb;

            &:hover {
                background: none;
            }

            a:first-child {
                color: #3c3c43;
            }
        }

        &.disabled {
            // &.active {
            //   border-left-color: #989b9c;
            //   background: #f6f8fa;
            // }

            a.disabled {
                color: #8b8b8f;
            }

            &.haveAnswered {
                background: #efefef;
            }
        }
    }

    .dropdown-menu-new {
        li {
            display: list-item;
            background: none;
            border: none;
            padding: 0;

            a {
                background: none;
                border-left: 4px solid transparent;
                padding: 16px 8px;

                &:hover {
                    background: #e7dcf9;
                    border-left: 4px solid #ab7cf8;
                }

                &.active {
                    background: #fff;
                    border-left: 5px solid #0071eb;

                    &:hover {
                        background: none;
                    }

                    a:first-child {
                        color: #3c3c43;
                    }
                }
            }

            ul {
                padding: 0 10px 0 20px;

                li {
                    display: flex;
                    grid-column-gap: 5px;

                    a,
                    i {
                        color: #3c3c43;
                    }
                }
            }
        }
    }

    .divider {
        margin-left: 0;
        margin-right: 0;
    }
    &.needClarification {
        width: 380px;

        li {
            grid-template-columns: 6fr 5fr;
        }
    }

    &.isReleased {
        li {
            grid-template-columns: 1.5fr 2fr 2fr;
            grid-template-areas: 'leftContent' 'middleContent' 'rightContent';
        }
    }

    .menuText {
        opacity: 1;
        -moz-transition: opacity 0.6s 0.2s ease-out;
        -o-transition: opacity 0.6s 0.2s ease-out;
        -webkit-transition: opacity 0.6s 0.2s ease-out;
        transition: opacity 0.6s 0.2s ease-out;
    }

    #toggleIcon {
        -moz-transition: transform 0.4s ease-out;
        -o-transition: transform 0.4s ease-out;
        -webkit-transition: transform 0.4s ease-out;
        transition: transform 0.4s ease-out;
    }

    .rotate {
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        -moz-transition: transform 0.4s ease-out;
        -o-transition: transform 0.4s ease-out;
        -webkit-transition: transform 0.4s ease-out;
        transition: transform 0.4s ease-out;
    }

    &.sidebar--Collapse {
        width: auto;
        max-width: 186px;
        -moz-transition: width 0.6s ease-out;
        -o-transition: width 0.6s ease-out;
        -webkit-transition: width 0.6s ease-out;
        transition: width 0.6s ease-out;

        .menuText {
            opacity: 0;
            -moz-transition: opacity 0.3s ease-out;
            -o-transition: opacity 0.3s ease-out;
            -webkit-transition: opacity 0.3s ease-out;
            transition: opacity 0.3s ease-out;
            position: absolute;
        }
    }

    .panel {
        border: none;
        margin: 0;

        .panel-heading,
        .panel-body {
            padding: 16px 8px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .panel-body {
            background: #f7faff;
            height: calc(100% - 192px);

            &.panel-body-closed {
                // padding: 16px 0;

                p {
                    padding-left: 8px;
                    padding-right: 8px;
                }

                li {
                    grid-template-columns: auto;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .modal-dialog {
        width: 90%;
        max-height: 50%;
        overflow: auto;
    }
}

@media (min-width: 992px) {
    .statusBar {
        width: 240px;
    }
}
