
.tableStatusTag {
    // font-weight: bold;
    text-align: center;
    padding: 6px 8px;
    border-radius: 44px;
    background-color: #fff;
    // height:36px;
    // line-height: 1.3;
    font-size: 0.88rem;
    line-height: 0.8;

    p {
        font-size: 0.88rem;
        display: inline-block;
    }

    &.tableStatusTag-dark,
    &.Starts {
        // border: 1px solid #767676;
        // background-color: #EFEFEF;
        // color: #767676;
        background: #efefef;
        border: 1px solid #767676;
        color: #3c3c43;
    }

    &.tableStatusTag-primary {
        // border: 1px solid #0071EB;
        // background-color: #FFF;
        // color: #0071EB;
        background: #93c4f9;
        border: 1px solid #0071eb;
        color: #3c3c43;
    }

    &.tableStatusTag-warning {
        // border: 1px solid #C97420;
        // background-color: #FFF;
        // color: #C97420;
        background: #e3c1a0;
        border: 1px solid #ac631b;
        color: #3c3c43;
    }

    &.tableStatusTag-success {
        // border: 1px solid #2D8659;
        // background-color: #FFF;
        // color: #2D8659;
        background: #a5c9b7;
        border: 1px solid #2d8659;
        color: #3c3c43;
    }

    &.tableStatusTag-danger {
        // border: 1px solid #D04343;
        // background-color: #FFF;
        // color: #D04343;
        background: #e6aeae;
        border: 1px solid #d04343;
        color: #3c3c43;
    }

    &.danger {
        border: 1px solid #d04343;
        background: #d04343;
        color: #fff;
        // font-weight: normal;
    }

    &.default {
        border: 1px solid #efefef;
        background: #efefef;
        color: #767676;
        // font-weight: normal;
    }

    &.answered {
        border: 1px solid #e4efe9;
        background: #e4efe9;
        color: #767676;
    }
}

.testSidebar-status {
    font-size: 0.667rem;
    line-height: 1;
    padding: 6px 12px;

    p {
        font-size: 0.667rem;
        line-height: 1;
    }

    &.danger {
        padding: 6px 8px;
    }
}
