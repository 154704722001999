
#rosterSyncConfirmationModal .modal-body .row:last-child {
    margin-top: 20px;
}

#rosterSyncConfirmationModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#rosterSyncConfirmationModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}
