
.numberInput {
    position: relative;
    display: inline-block;
}

.quantity-nav {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
    color: #222;
    z-index: 4;
    height: 100%;
    max-height: 100%;
}

.quantity-button {
    min-height: 18px;
    height: 50%;
    display: flex;
    align-items: center;
    width: 22px;
    justify-content: center;
    // border: 1px solid ##dfe3e9;
    border: 1px solid #767676;
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);

    &:last-child {
        // border-top: 1px solid #dfe3e9;
        border-top: 1px solid #767676;
    }

    &.disabled {
        background: #efefef;
        // border: 1px solid #EFEFEF;
        color: #8b8b8f;
        cursor: not-allowed;
    }
}

.input-group-addon,
.input-group-addon:last-child {
    border-radius: 3px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border: 1px solid #d8d8d8;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
}

.input-group input {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.iratPoints .numberInput {
    width: 110px;
}

.timerTopBar #durationHours-div .input-group,
.timerTopBar #durationMinutes-div .input-group {
    margin-bottom: 0;
    width: 122px;
}

input.iratDurationMinutes,
input.iratDurationHours,
input.tratDurationMinutes,
input.tratDurationHours,
input.applicationDurationMinutes,
input.applicationDurationHours,
input.evaluationDurationMinutes,
input.evaluationDurationHours {
    width: 64px;
}

input.iratDurationDays,
input.tratDurationDays,
input.applicationDurationDays,
input.evaluationDurationDays {
    width: 64px;
}

.fixedWidthNumber {
    width: 64px !important;
}

.form-control[type='number'] {
    outline: 0;
    outline-offset: 0;
    box-shadow: none;
}

.numberGroup {
    display: inline-flex;

    &:focus-within {
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
        border-radius: 3px;
    }
}
