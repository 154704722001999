
.qnsOptions {
    margin-top: 20px;
}

.qnsOptions.correctOption {
    margin-top: 15px;
}

.qnsOptions:first-child,
.qnsOptions.correctOption:first-child {
    margin-top: 0;
}

.optionKey {
    font-weight: bold;
}

.correctOption {
    padding: 10px;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #f3fffd;
    border: 1px solid #0f9b81;
}

.correctOption i {
    color: #0f9b81;
}

.gridBase table {
    table-layout: fixed;
    background: none;
}

.transparent.table,
table.transparent th,
table.transparent td {
    border: 0;
    box-shadow: none;
    text-align: center;
}

.transparent.table thead tr {
    background-image: none;
    background: none;
    box-shadow: none;
}

table.transparent tbody tr td {
    padding: 20px;
    text-align: left;
    font-weight: bold;
    vertical-align: middle;
}

table.transparent tbody tr:first-child td {
    padding: 20px 0 10px;
    text-align: left;
    font-weight: bold;
    vertical-align: middle;
}

table.transparent tbody tr:last-child td {
    padding-bottom: 0;
}

table.transparent td:last-child div {
    border-left: 0;
    border-right: 1px solid #d8d8d8;
    margin-left: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.transparent.table > thead > tr > th {
    vertical-align: middle;
}

.transparent.table > thead > tr:first-child > th {
    padding: 5px 20px 10px;
}

.transparent.table > thead > tr:last-child > th {
    padding: 5px 20px 0;
}

.transparent.table td:first-child div.gradient.btn-default {
    border-left: 1px solid #d8d8d8;
    border-right: 0;
    margin-left: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.transparent.table td:not(:first-child) div.gradient.btn-default {
    border-left: 0;
    border-right: 0;
    margin-left: 0;
    border-radius: 0;
    box-shadow: none;
}

.transparent.table td:last-child div.gradient.btn-default {
    border-left: 0;
    border-right: 1px solid #d8d8d8;
    margin-left: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.transparent.table td:nth-child(2):last-child div.gradient.btn-default {
    border: 1px solid #d8d8d8;
}

.transparent.table thead tr:nth-child(3) th {
    border: 1px solid #d8d8d8;
    border-right: 0;
    padding: 20px;
    text-transform: none;
}

.transparent.table thead tr:nth-child(3) th:last-child {
    border-right: 1px solid #d8d8d8;
}

.transparent.table thead tr:nth-child(3) {
    margin-bottom: 10px;
}

.gridBase .profile_image.marginRight5 {
    margin-right: 5px;
}

#exTab1 .nav-pills > li > a {
    width: 250px;
    border-radius: 0;
    padding: 20px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom: 1px solid #fff;
    border: solid 1px #d8d8d8;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    color: #222;
}

#exTab1 .nav-pills > li.active > a {
    border: 1px solid #1991eb;
    background-image: none;
    background: #fff;
    border-bottom: none;
    border-top: 3px solid #1991eb;
}

#exTab1 .tab-content {
    padding: 20px;
    border: 1px solid #1991eb;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background: #fff;
}

.numberInput {
    position: relative;
    width: 185px;
}

.quantity-nav {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
    color: #222;
    z-index: 4;
    height: 100%;
    max-height: 100%;
}

.quantity-nav .disabled {
    color: #dfe3e9;
    cursor: not-allowed;
}

.quantity-button {
    min-height: 18px;
    height: 50%;
    display: flex;
    align-items: center;
    width: 22px;
    justify-content: center;
    background-color: white !important;
    /* border: 1px solid #dfe3e9 !important; */
    border: 1px solid #767676 !important;
}

.quantity-button:last-child {
    /* border: 1px solid #dfe3e9 !important; */
    border-top: 1px solid #767676 !important;
}

.width150px {
    width: 150px;
}

.questionBankOptionsField {
    .checkboxField label {
        align-items: baseline;
    }

    .fa-check-circle,
    .fa-check-square {
        font-size: 18px;
    }
}
