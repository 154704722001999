/*FROM OLD ACCOUNT CENTER*/
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
.glyphicon-spin {
    -webkit-animation: spin 1000ms infinite linear;
    animation: spin 1000ms infinite linear;
}

/*END FROM ACCOUNT CENTER*/

.loginPage {
    text-align: left;
    display: block;
    margin-bottom: 30px;
}

// .loginPage label.form-dropdown:after {
//   right:4%;
// }

.container-fluid {
    padding: 0 25px;
}

.hide,
.hidden {
    display: none;
}

.show {
    display: block;
}

.swal2-overlay {
    z-index: 50000;
}

.swal2-container {
    z-index: 50001;
}

.width100 {
    width: 100%;
}

.textCenteralize {
    text-align: center;
}

@media (max-width: 991px) {
    .col-xs-12 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .container-fluid {
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    select {
        padding-right: 18px;
    }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
    transition: all 0.3s ease;
}
.slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-active {
    padding-left: 10px;
    opacity: 0;
}
@keyframes fill-up-half {
    0% {
        y: 150px;
        height: 0;
    }
    20% {
        y: 70px;
        height: 80px;
    }
    100% {
        y: 10px;
        height: 140px;
    }
}
@keyframes appear {
    0% {
        opacity: 0;
    }
    99% {
        opacity: 0;
    }
    100% {
        opacity: 100;
    }
}

#loading {
    z-index: 60000;
    position: fixed;
    margin: auto;
    top: 30vh;
    left: calc(50vw - 75px);
    transition: opacity 1.5s ease-in;
}
#loading.show {
    opacity: 1;
}
#loading.hide {
    opacity: 0;
}
#loading .logo-water-fill {
    animation: fill-up-half 20s forwards ease-out;
}
#loading.hide .logo-water-fill {
    transition: height 0.5s ease-out;
    transition: y 0.5s ease-out;
    y: 0;
    height: 150px;
}

/*Sidebar CSS*/
.nav-none .top_nav {
    margin-left: 0px;
}
// .nav-none footer {
//   margin-left: 0px;
// }

//hidden left menu
.nav-none .left_col {
    display: none;
}
/*For profile view*/
// p.form-control-static{
//   color:#222;
//   font-size:16px;
// }
.profile_info {
    width: 100%;
    display: block;
    padding-bottom: 20px;
    color: #222222;
    padding-top: 5px;
}
.profile {
    min-height: 72px;
    width: 100%;
}
.profile_pic {
    float: none;
    width: auto;
}
.badge_profile {
    height: 72px;
    width: 72px;
    // text-align: center;
    // vertical-align: middle;
    color: #222222;
    font-size: 30px;
    font-weight: 900;
    border-radius: 50%; /* may require vendor prefixes */
    background: white;
    display: block;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0.8;
}

@media (max-width: 991px) {
    .nav-sm .profile {
        padding-bottom: 70px;
    }
}

/*gentelella panel*/

.nav-sm .main_container .top_nav {
    display: block;
    margin-left: 0px;
    z-index: 2;
}
.nav-sm .profile {
    background: #495060;
    display: block;
    padding-bottom: 50px;
}
.nav-sm .profile .badge_profile {
    width: 48px;
    height: 48px;
    line-height: 48px;
    font-size: 24px;
}
.nav-sm .profile .profile_info {
    width: 100%;
    display: block;
    padding-bottom: 0px;
    color: white;
    padding-top: 5px;
}

/*Theme Ovverride*/
img.center {
    display: block;
    margin: 0 auto;
}
.login {
    background: white;
}

body.login {
    background: #f5f7f9;
}

.login_content {
    margin: 0 auto;
    padding: 25px 0 0;
    position: relative;
    text-align: left;
    text-shadow: 0 1px 0 #fff;
    min-width: 280px;
}

.login_content h1,
.login_content p {
    text-align: center;
}

.login_content form input[type='text'],
.login_content form input[type='email'],
.login_content form input[type='password'] {
    border: 2px solid #c8c8c8;
}

.loginBtn {
    margin-bottom: 20px;
}

.terms-conditions-checkbox {
    text-align: left;
    margin-bottom: 30px;
    font-size: 14px;
}

/* Anchor CSS */
.disabled {
    pointer-events: none;
    cursor: not-allowed;
}

a.disabled {
    color: gray;
    pointer-events: none;
    cursor: not-allowed;
}

a.disabled:hover {
    color: gray;
}

a.page-link.disabled {
    color: gray;
    cursor: not-allowed;
    opacity: 0.65;
    filter: alpha(opacity=65);
    box-shadow: none;
}

a.page-link.active {
    color: black;
}

a:hover {
    cursor: pointer;
}

/* End Anchor CSS */
/*Bounce Button*/
@-webkit-keyframes bounce {
    0% {
        transform: scale(1, 1) translate(0px, 0px);
    }

    30% {
        transform: scale(1, 0.9) translate(0px, 10px);
    }

    75% {
        transform: scale(1, 1.1) translate(0px, -10px);
    }

    100% {
        transform: scale(1, 1) translate(0px, 0px);
    }
}

.bounce {
    -webkit-animation: bounce 0.75s infinite;
}

@media (max-width: 585px) {
    .wizard {
        width: 90%;
        height: auto !important;
    }

    span.round-tab {
        font-size: 16px;
        width: 50px;
        height: 50px;
        line-height: 50px;
    }

    .wizard .nav-tabs > li a {
        width: 50px;
        height: 50px;
        line-height: 50px;
    }

    .wizard li.active:after {
        content: ' ';
        position: absolute;
        left: 35%;
    }
}

/* End of Wizard CSS */

/* Bar Chart */

.svg-y-label {
    cursor: pointer;
}

/* End of Bar Chart */

/* Carousel Control for trat report */
.carousel-control {
    width: auto;
    color: #2192dd;
}
.carousel-control.disabled {
    color: gray;
}

/* End of Carousel Control for trat report */

/* Pagination CSS */

// .pagination > .active > a,
// .pagination > .active > a:hover,
// .pagination > .active > a:focus,
// .pagination > .active > span,
// .pagination > .active > span:hover,
// .pagination > .active > span:focus {
//   z-index: 2;
//   border-radius: 3px;
//   box-shadow: 1px 1px 4px 0 rgba(202, 202, 202, 0.5);
//   background-color: #9b9f9f;
//   border: solid 1px #ededed;
// }

// .paginate{
//   font-size:18px;
//   height:48px;
//   color:black;
// }

// .pagination {
//     display: inline-block;
//     padding-left: 0;
//     margin:0;
//     height:100%;
//     border-radius: 3px;
// }

// .paginate .pagination > li >a.btn-back-and-next{
//   width:110px;
// }

// .paginate .pagination >li >a.current-question-group{
//   background-color:#9b9f9f;
//   color:white;
// }

// .test-listing-pagination li a {
//   height: 48px;
//   width: 48px;
//   line-height: 30px;
//   text-align: center;
// }

// .test-listing-pagination li a {
//     height:inherit;
// }

// .pagination > li > a,
// .pagination > li > span {
//     color:#222;
//     border: solid 1px #ededed;
// }

// .paginate .pagination > li > a.current-question-group {
//     border:solid 1px #ededed;
//     box-shadow:1px 1px 4px 0 rgba(202, 202, 202, 0.5);
//     border-radius:3px;
//     border-bottom-left-radius:4px;
//     border-top-left-radius:4px;
// }

// .paginate .pagination >li >a.answered{
//   background-color:blue;
//   color:white;
//   //border-color:blue;
// }

// .paginate .pagination > li > a.answered {
//     background:rgba(29, 131, 199, 0.5);
// }

// .paginate .pagination > li >a {
//   border-left: solid 1px #ededed;
//   border-right: solid 1px #ededed;
//   min-width:50px;
//   text-align:center;
//   color:black;
// }

// .paginate .pagination > li > a {
//     width:48px;
//     line-height: 30px;
//     font-size:14px;
// }

// .paginate, .paginate .pagination > li > a {
//     height:36px;
//     line-height: 24px;
//     min-width:48px;
// }

.question-group-status-table tr:first-child div {
    border-top: 0;
}
.question-group-status-table tr div {
    border-top: 1px solid #ededed;
}

/*TRAT DISABLE PAGINATION FOR NON TEAM LEADER*/

// .pagination.disabled{
//   opacity:.5;
// }

// .pagination.disabled >li >a{
//   cursor:default !important;
// }

/* End of Pagination CSS */

/* Tests Page CSS */
/*Custom CSS for tests/index.vue*/
.test-item {
    width: 100%;
    padding-bottom: 10px;
    display: inline-block;
    min-height: 70px;
}
.test-item-border {
    border-bottom: dotted 1px #d1d1d1;
}
.test-item h3 {
    margin-top: 0 !important;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
}
.test-item p {
    font-size: 16px;
}

.test-action {
    display: inline-block;
    vertical-align: middle;
}

/*Test Page CSS Questions and Options CSS*/
.question-box {
    min-height: 100px;
    display: block;
}

.question-box span.question-no {
    font-size: 16px;
    color: #222222;
    font-weight: bold;
}

.question-box span.question-more-info {
    font-size: 16px;
    color: #222222;
    font-weight: bold;
    margin-bottom: 10px;
}

.question-box .question-desc {
    font-size: 16px;
    color: #222222;
}

.question-box .options {
    min-height: 70px;
    margin-bottom: 15px;
}

.question-box .options .option {
    margin-bottom: 5px;
}

.question-box .options .option .description {
    padding: 5px;
    font-size: 16px;
    color: #222222;
    display: inline-block;
}

.question-box .options .option .status {
    padding-top: 2px;
    text-align: center;
    vertical-align: middle;
    height: 100%;
    max-width: 40px;
}

.question-box .options .option .description.selected {
    background-color: #668cff;
    border-radius: 5px;
    color: white;
}

.question-box .options .option .description.correct {
    background-color: green;
    border-radius: 5px;
    color: white;
}

.question-box .options .option .description.wrong {
    background-color: red;
    border-radius: 5px;
    color: white;
}

.question-box .options .option .description {
    font-size: 14px;
}

.question-box .options .option .description.disabled {
    opacity: 0.5;
}

.paginate.fixed {
    position: fixed;
    text-align: center;
    padding-right: 25px;
    width: 100vw;
    bottom: 60px;
    height: 50px;
    vertical-align: middle;
}

.arrow_box.answered {
    position: relative;
    border: 4px solid #blue;
}
.arrow_box.answered:after,
.arrow_box:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.arrow_box.answered:after,
.arrow_box.answered:before {
    border-bottom-color: blue;
    border-width: 10px;
    margin-left: -10px;
}

.arrow_box.unanswered {
    position: relative;
}
.arrow_box.unanswered:after,
.arrow_box:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.arrow_box.unanswered:after,
.arrow_box.unanswered:before {
    border-bottom-color: red;
    border-width: 10px;
    margin-left: -10px;
}

span.round-tab {
    width: 70px;
    height: 70px;
    line-height: 70px;
    display: inline-block;
    border-radius: 100px;
    background: #fff;
    border: 2px solid #e0e0e0;
    z-index: 2;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 25px;
}
span.round-tab i {
    color: #555555;
}

/* Peer Evaluation */

#peer-eval-tab li a {
    color: #333;
    font-size: 14px;
}
#peer-eval-tab {
    padding: 0;
    margin: -20px -20px 0;
}
#peer-eval-tab li:before {
    content: '';
    width: 100%;
    height: 2px;
    background: #d2d2d2;
    position: absolute;
    left: 50%;
    top: 27px;
    margin: 0 auto;
}
#peer-eval-tab li.active:before {
    background: #1abed7;
}
#peer-eval-tab li.line-active:before {
    background: #d2d2d2;
}
#peer-eval-tab li:last-child:before {
    content: none;
}
#peer-eval-tab li a,
#peer-eval-tab li a:hover,
#peer-eval-tab li a:active,
#peer-eval-tab li a:focus,
#peer-eval-tab li a:visited {
    font-size: 14px;
    font-weight: 500;
    border-radius: 0;
    border: none;
    padding: 11px 25px;
    text-align: center;
    margin-right: 0;
    color: #d2d2d2;
    background: none;
    opacity: 1;
    cursor: default;
    pointer-events: none;
}
#peer-eval-tab li a u {
    margin: 0 auto;
    text-decoration: none;
    width: 34px;
    height: 34px;
    line-height: 30px;
    text-align: center;
    display: block;
    border-radius: 50%;
    letter-spacing: -0.06em;
    background: #f9feff;
    border: solid 2px #d2d2d2;
    font-weight: bold;
}
#peer-eval-tab li.active a {
    background: none;
    color: #1abed7;
}
#peer-eval-tab li.active a u {
    border-color: #1abed7;
    color: #fff;
    background: #1abed7;
}

/* End of Peer Evaluation */

// ------ New UI ------ //

/* Menu bar & Title & BreadCrumb */

.page-title {
    height: 0;
    min-height: 0px;
    padding: 0;
}
.top_nav .nav_menu {
    background-color: rgb(247, 247, 247);
    border: none;
}
.toggle {
    padding-top: 21px;
    width: 40px;
    margin-left: 5px;
}
.left_col {
    background: #495060;
    //margin-top: 55px;
    width: 100%;
}
.main_container .top_nav {
    display: block;
    margin-left: 0px;
}
//container for the right side

// .form-control{
//   height:48px;
// }

.has-feedback-left .form-control-feedback {
    right: 12px;
    // top: 10px;
    top: 35%;
    // font-size: 14px;
    font-size: 0.7em;
    line-height: 1.2;
    width: 13px;
}

.preview-image {
    max-width: inherit;
    background: white;
}
.preview-image,
.actual-image {
    // background-color: #989b9c;
    width: 100%;
    text-align: center;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    margin: 0 auto;
}
.actual-image {
    background-color: #989b9c;
    color: white;
    width: 100px;
    height: 100px;

    background-position: center;
    background-size: cover;
}

.actual-image.img24px {
    width: 24px;
    height: 24px;
}

ul,
ol {
    margin-top: 0;
    margin-bottom: 11px;
}

ol {
    display: block;
    list-style-type: decimal;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 40px;
}

.title_left {
    padding-bottom: 20px;
}

.page-title .title_left h3 {
    margin: 10px 0;
    font-weight: normal;
}

.peerHeader span {
    font-size: 14px;
    padding: 2px 5px;
    border-radius: 3px;
    background: #fff;
    border: solid 1px #e9e9e9;
    color: #767676;
    line-height: 15px;
}

.page-title .title_left .peerHeader h3 {
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    padding-left: 10px;
}

.peerSubTop {
    text-align: right;
    padding-top: 20px;
}

@media (max-width: 991px) {
    .peerSubTop {
        padding-top: 0;
        padding-bottom: 30px;
        text-align: left;
        display: flex;
        flex-wrap: wrap;
    }
}

.title_left.peerTitle {
    width: 50% !important;
    padding-bottom: 30px;
}

@media (max-width: 991px) {
    .title_left.peerTitle {
        width: 100% !important;
    }
}

.title_left span {
    font-size: 14px;
}

.title_left h3 {
    font-size: 26px;
}

/* End of Menu Bar & Title & BreadCrumb */
.row {
    margin: 0;
}

.flagQns:hover i {
    color: red;
}

.description p {
    margin: 0;
}

.question-box .options .option .description {
    padding: 0;
}

.question-box .options .option .status {
    padding-top: 0;
}

.quizRC.right-container {
    padding: 0 25px 30px 25px !important;
}

@media (max-width: 991px) {
    .quizRC.right-container {
        padding: 0 25px 30px 25px !important;
    }
}

.peerQns {
    font-size: 16px;
    font-weight: 100;
    line-height: 1;
}

.peerContainer .form-group:first-child {
    margin-bottom: 20px;
}

.question-desc p {
    margin: 0;
    font-family: 'Calibri';
}

.iconsTRAT {
    padding: 0;
    width: auto;
    font-size: 14px;
}

.sticky-col {
    position: absolute;
    top: 0;
    left: 0;
    width: 192px;
}

.statusBar .x_panel {
    background: none;
    border: none;
}

.statusCons {
    background: #fff;
    border: 1px solid #e6e9ed;
}

.clarifiQns .quesNo {
    font-weight: bold;
    color: #222;
}

.dl-horizontal dt {
    width: auto;
}

// Timer
div.timer-div {
    text-align: center;
}

div.timer-div div.clock {
    height: 68px;
}

div.timer-div div.clock div.number-div {
    margin: auto;
    display: inline-block;
    height: 40px;
    background-color: #ffffff;
    width: 40px;
    border-radius: 3px;
    vertical-align: middle;
}

div.timer-div div.clock div.number-div.big {
    margin: auto;
    display: inline-block;
    height: 190px;
    background-color: transparent;
    width: auto;
}

div.timer-div div.clock div.number-div div.number {
    font-size: 40px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 45px;
    letter-spacing: normal;
    text-align: center;
}

div.timer-div div.clock div.number-div div.number.big {
    font-size: 120px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
}

div.timer-div div.clock div.time-over-div {
    font-size: 40px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7b1717;
}

div.timer-div div.clock div.time-over-div.big {
    font-size: 60px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7b1717;
}

div.timer-div div.clock div.label-div {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    width: 100%;
    color: #ffffff;
    height: 12px;
    margin-bottom: 5px;
}

div.timer-div div.clock div.number-div div.label-div.big {
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #767676;
    height: auto;
    width: auto;
    margin-bottom: 0px;
}

div.timer-div div.clock div.colon {
    display: inline-block;
    font-size: 40px;
    margin-right: 2px;
    color: #ffffff;
}

div.timer-div div.clock div.colon.big {
    display: inline-block;
    position: relative;
    top: -15px;
    font-size: 80px;
    margin-right: 2px;
}

div.timer-div div.clock.green div.number {
    color: #47a647;
}

div.timer-div div.clock.orange div.number {
    color: orange;
}

div.timer-div div.clock.red div.number {
    color: #7b1717;
}

div.timer-div div.clock.green div.colon.big {
    color: #47a647;
}

div.timer-div div.clock.orange div.colon.big {
    color: orange;
}

div.timer-div div.clock.red div.colon.big {
    color: #7b1717;
}

// Progress bar
.progress {
    height: 20px !important;
    width: 100%;
    border-radius: 3px;
    background-color: #ffffff;
    margin-bottom: 0;
}
.progress-bar {
    background-color: #47a647;
}
.progress-bar.green {
    background-color: #47a647;
}
.progress-bar.orange {
    background-color: orange;
}
.progress-bar.red {
    background-color: #47a647;
}

@media (min-width: 992px) {
    .progress {
        width: 310px;
        float: right;
    }
}

// Ans status
.question-group-status-table td.action a {
    font-size: 16px;
    font-weight: bold;
    text-align: right;
    color: #0d77bd !important;
}

// Team base
span.team-initial {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background-color: #495060;
    line-height: 26px;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    text-align: center;
}

.member-list.well {
    padding: 0px;
    margin-bottom: 0px;
}

@media (min-width: 768px) {
    .dl-horizontal dd {
        margin-left: 50px;
    }
}

/* End of Test Page CSS New UI*/

/* Icons */

.fa.fa-sort {
    margin-left: 5px;
}

.fa.fa-circle {
    font-size: 10px;
}

i.fa-sort-desc,
i.fa-sort-asc {
    height: 8px;
    position: relative;
}

i.fa-sort-desc:before {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: block;
}

i.fa-sort-asc:before {
    position: relative;
    top: 50%;
    display: block;
}

.fa.fa-trash:before {
    content: '\f1f8';
}

/* End of Icons */

/* Label */

.label-danger {
    background: #fff;
    border: 1px solid #7b1717;
    color: #7b1717;
}

.label-success {
    border-radius: 3px;
    background-color: #6b896f;
    box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
    font-style: normal;
}

.label.label-default {
    background: white;
    color: #222;
    border: 1px solid #e9e9e9;
}

/* End of Label */

/* Discussion */

.discussionContent {
    padding: 0 30px;
}

@media (min-width: 992px) {
    .discussionTeachers {
        text-align: right;
    }
}

@media (max-width: 991px) {
    .eGalleryVote {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}

/* End of Discussion */

/* x container */

// .x_panel {
//   border-radius: 3px;
//   box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
//   background-color: #ffffff;
//   border: solid 1px #e9e9e9;
// }

// .x_panel {
//   background:#f8f8f8;
//   border:1px solid #f8f8f8;
//   box-shadow: none;
//   margin:0;
//   padding:30px 0 !important;
// }

// .x_title {
//   padding:15px 20px;
//   border-bottom:1px solid #ededed;
//   margin:0;
//   font-size:16px;
//   font-weight: bold;
//   line-height: 1;
// }

// .x_title {
//   border-bottom:1px solid #000 !important;
// }

// .x_title {
//     border-bottom: 0px;
//     padding: 1px 5px 6px;
//     margin-bottom: 10px;
// }

// div.form-div .x_content {
//     background-color: #f3f8fc;
//     padding:20px !important;
// }

// div.form-div .x_footer {
//   border-radius: 2px;
//   background-color: #495060;
//   border: solid 1px #e9e9e9;
// }

/* End of X Container */

// .diagramBoxFooter {
//   background-color: #495060;
//   padding:20px !important;
//   border-radius:3px;
// }

.confirmQnsSetting {
    background: #47a647;
    padding: 10px;
    text-align: center;
    color: #fff;
    line-height: 1;
    border-radius: 3px;
    box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
    height: 36px;
}

.displayingQnsOption {
    display: flex;
    height: 100%;
    font-weight: bold;
    font-size: 14px;
}

.displayingQnsOptionCorrect {
    border-radius: 3px;
    background-color: rgba(86, 174, 86, 0.1);
    display: flex;
    height: 100%;
    font-weight: bold;
    padding: 5px 10px;
}

.displayPoints {
    border-radius: 3px;
    box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
    border: solid 1px #47a647;
    background: #fff;
    color: #47a647;
    padding: 5px 15px;
    font-size: 10px;
}
.displayRequired {
    border-radius: 3px;
    box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
    border: solid 1px #7b1717;
    background: #fff;
    color: #7b1717;
    padding: 5px 15px;
    font-size: 10px;
}

.minOfCharacters {
    font-size: 12px;
    font-weight: bold;
    color: #222222;
}

.confirmQnsSetting {
    background: #47a647;
    padding: 10px;
    text-align: center;
    color: #fff;
    line-height: 1;
    border-radius: 3px;
    box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
    height: 36px;
}

.ratingMobileOptionsDefault {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.ratingMobileOptionsDefault:first-child {
    margin-top: 0;
}

.mobileRatingTopOption {
    width: 100%;
}

.mobileRatingBtmOption {
    width: 100%;
    border-radius: 3px;
    border: solid 1px #cacaca;
    padding: 5px 10px;
    margin-top: 5px;
}

.displayingOptions {
    margin-top: 15px;
}

.displayingOptions:first-child {
    margin-top: 0;
}

.hoverDisplayQns:hover {
    border: dashed 2px #495060;
    border-radius: 3px;
}

.hoverDisplayQns:hover .hoverDisplayQnsInner {
    opacity: 0.5;
}

.hoverDisplayQns .onHover {
    display: none;
    text-align: right;
}

.hoverDisplayQns:hover .onHover {
    display: block;
}

.hoverDisplayQns:hover .question-info {
    margin-top: 10px;
}

.displayQNS {
    padding: 10px;
}

.modal-body .displayQNS {
    padding: 0;
}

.infoEditQns {
    border-radius: 3px;
    background-color: rgba(29, 131, 199, 0.1);
    border: solid 1px rgba(29, 131, 199, 0.2);
    padding: 5px;
    display: block;
    font-size: 12px;
}

.questionGroup {
    margin-bottom: 30px;
    border: 1px solid #e9e9e9;
}

.questionGroup.selected-top {
    border-top: solid 4px blue;
}

.questionGroup.selected-bottom {
    border-bottom: solid 4px blue;
}

.questionGroup .header {
    color: white;
    padding-left: 10px;
    line-height: 45px;
    height: 45px;
    background-color: #495060;
    font-weight: bold;
}

.questionGroup .header .fa-stack {
    cursor: pointer;
}

.questionGroup .content {
    padding: 10px;
    text-align: center;
    min-height: 75px;
}

.comparison-question-groups-main-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
}

.comparison-question-groups-inner-wrapper {
    flex: 1 1 auto;
    text-align: center;
    margin: 6px 8px;
    display: inline-block;
}

.comparison-question-groups-inner-wrapper .grouping-wrapper {
    border: 1px solid #ffd504;
    background: #ffd504;
    display: flex;
    flex-wrap: wrap;
}

.comparison-question-groups-inner-wrapper .grouping-wrapper:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.comparison-question-groups-inner-wrapper .grouping-wrapper:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-width: 0;
}

.grouping-wrapper .question-group-wrapper {
    border: 1px solid #ffd504;
    background: #f8f8f9;
    flex: 1 1 auto;
    padding: 2px 4px;
    line-height: 1.6em;
    border-radius: 3px;
}

.question-group-wrapper .question-pos {
    padding: 2px;
    background: #eff2f7;
    border-radius: 3px;
    margin: 0 2px;
}

.question-group-wrapper .group-pos {
    text-align: center;
    font-size: 10px;
    color: #9c9d68;
}

.manageQnsBorder {
    border: solid 1px #ededed;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    overflow: auto;
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown a:hover {
    background-color: #ddd;
}

.displayTopic {
    border-radius: 3px;
    box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
    border: solid 1px #222222;
    background: #ffffff;
    color: #222222;
    padding: 5px 15px;
    font-size: 10px;
}

.displayDifficultyLvl {
    border-radius: 3px;
    box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
    border: solid 1px #d44811;
    background: #ffffff;
    color: #d44811;
    padding: 5px 15px;
    font-size: 10px;
}

.ratingRubricDiv {
    padding-left: 10px;
}

@media (min-width: 992px) {
    .displayingQnsOption,
    .displayingQnsOptionCorrect {
        align-items: center;
    }
}

@media (max-width: 991px) {
    .ratingWeightDiv {
        width: 22%;
    }

    .ratingTextDiv {
        width: 30%;
    }
}

/* End of Question Builder */

/* Log in */

.accCodeForm .help-block {
    border-radius: 3px;
    background-color: rgba(180, 0, 32, 0.1);
    padding: 15px;
    color: #000;
    text-align: left;
    margin: 0;
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.accCodeForm .help-block.correct-display {
    border-radius: 3px;
    background-color: rgba(71, 166, 71, 0.16);
    padding: 15px;
    color: #000;
    text-align: left;
    margin: 0;
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.fills {
    position: relative;
    color: #aaa;
    font-size: 16px;
}

.fills .fa-times-circle,
.fills .fa-check-circle {
    position: absolute;
    top: 10px;
    right: 10px;
}

// @media (min-width: 992px)
// {
//   .login-wrapper {
//     padding:20px;
//   }
// }

/* End of Log In */

/* Error Message */

.has-error .help-block {
    text-align: left;
    font-style: italic;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
    color: #eb1919;
}

.has-error .form-control,
.has-error .form-control:focus {
    border: solid 2px #eb1919;
}

/* End of Error Message */

/* Password Page */

// .strengthBar {
//     height: 5px;
//     border-radius: 5px;
//     background-color: #dedede;
//     margin-top:5px;
//     text-align:left;
// }

// .WeakBar {
//     width:25%;
//     background: #b40020;
//     display:block;
//     height:5px;
//     border-radius:5px;
// }

// .FairBar {
//     width:50%;
//     background:#e69618;
//     display:block;
//     height:5px;
//     border-radius:5px;
// }

// .AlmostBar {
//     width:75%;
//     background:#0078d7;
//     display:block;
//     height:5px;
//     border-radius:5px;
// }

// .PassBar {
//     width:100%;
//     background:#47a647;
//     display:block;
//     height:5px;
//     border-radius:5px;
// }

// .WeakTxt {
//     color:#b40020;
// }

// .FairTxt {
//     color:#e69618;
// }

// .AlmostTxt {
//     color:#0078d7;
// }

// .PassTxt {
//     color:#47a647;
// }

// .v-popover {
//     height:0 !important;
//     display:inline-block;
//     width:10% !important;
// }

// .correct0f9b81 {
//     color:#0f9b81;
// }

/* End of Password Page */

/* Index Page */

// .searchListing {
//     display:table-cell;
// }

// .listingRow {
//     width:100%;
//     display:flex;
//     flex-wrap:wrap;
//     align-items:center;
// }

// @media (min-width: 992px)
// {
//   .listingTableHidden {
//     width:100%;
//     overflow:hidden;
//     overflow-x:scroll;
//     // border:1px solid #ededed;
//   }

//   .searchListing {
//       width:19%;
//   }

//   .listingToggle {
//       width:29%;
//   }
// }

// @media (max-width: 991px) {
//   .indexListing {
//       padding:10px 25px !important;
//   }

//   .filter-by {
//       padding-left:0;
//   }

//   .indexDisplay {
//       padding:0;
//   }
// }

/* End of Index Page */

/*a:focus{
  color:red !important;
}*/
