label {
    font-size: 0.834rem;
    // font-size: 0.875em;
    margin-bottom: 0;
}

input,
select {
    border-radius: 3px;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #efefef;
    color: #354052;
    // font-weight: bold;
    border: 1px solid #efefef;
}

.form-horizontal {
    display: flex;
    align-items: center;
    vertical-align: center;
    // margin-bottom: 30px;
}

.form-horizontal .control-label {
    padding-top: 0;
}

select.form-control {
    background: linear-gradient(to bottom, #fff 0%, #f5f7f9 100%);
    box-shadow: none;
    border: solid 1px #767676;
    min-width: 150px;
    padding-right: 30px;
    line-height: 1.2;
    height: auto;
    width: auto;
    min-width: 100%;
    max-width: 100%;
}

// .form-control {
//   border: solid 1px #dfe3e9;
//   line-height:1;
//   height:36px;
//   color: #222222;
//   padding:10px 15px;
// }

.has-feedback .form-control {
    padding-right: 15px;
}

.form-control-feedback {
    margin-top: 0;
    line-height: inherit;
    height: inherit;
    font-size: 15px;
    // font-size: 0.9375em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.form-horizontal .control-label {
    color: #767676;
    padding-right: 0;
    font-weight: normal;
}

.form-control-static {
    width: 100%;
    padding: 0;
    min-height: auto;
    margin-bottom: 20px;
}

input[type='number']::-webkit-inner-spin-button {
    opacity: 1;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.quantity input {
    //  width: 45px;
    height: 42px;
    line-height: 1.65;
    float: left;
    display: block;
    padding: 0;
    margin: 0;
    padding-left: 20px;
    border: 1px solid #eee;
}

.quantity input:focus {
    outline: 0;
}

.container input:checked ~ .checkmark {
    background-color: #2196f3;
}

.eGalleryVote input[type='radio']:checked + span {
    color: #47a647;
}

textarea::-moz-placeholder,
textarea:-moz-placeholder,
textarea::-webkit-input-placeholder,
textarea:-ms-input-placeholder {
    color: #9b9f9f;
    font-size: 1rem;
    font-weight: 100;
    font-family: Calibri;
    font-style: italic;
    font-style: oblique;
}

.form-horizontal .control-label {
    line-height: 1;
    font-size: 1rem;
    color: #222;
    font-weight: bold;
}

input:checked + .slider {
    // background-color: #2196f3;
    background-color: #1b83c7;
}

input:focus + .slider {
    box-shadow: 0 0 1px #1b83c7;
}

input[disabled] + .slider {
    background-color: #c5d0de;
}

input[type='number'].range {
    padding: 5px;
}

.radio-custom {
    input[type='checkbox'] {
        position: relative;
        margin: 0;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        box-shadow: inset 0 0 0 2px #fff;
        border: solid 2px #cacaca;
        background: #fff;

        &:checked {
            border: solid 2px #0f9b81;
            background: #0f9b81;
            box-shadow: inset 0 0 0 2px #0f9b81;

            &:before {
                font-family: FontAwesome;
                content: '\f00c';
                color: #fff;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &:hover {
            color: #1991eb;
            border: solid 2px #1991eb;
        }
    }
}

.checkbox-custom {
    input[type='checkbox'] {
        position: relative;
        margin: 0;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        width: 24px;
        height: 24px;
        border-radius: 20%;
        box-shadow: inset 0 0 0 2px #fff;
        border: solid 2px #cacaca;
        background: #fff;

        &:checked {
            border: solid 2px #0f9b81;
            background: #0f9b81;
            box-shadow: inset 0 0 0 2px #0f9b81;

            &:before {
                font-family: FontAwesome;
                content: '\f00c';
                color: #fff;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &:hover {
            color: #1991eb;
            border: solid 2px #1991eb;
        }
    }
}

.qnsBuilderQnsOptions {
    input[type='radio'] {
        display: none;

        & + label:before {
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            content: '';
            /* create custom radiobutton appearance */
            display: inline-block;
            width: 15px;
            height: 15px;
            margin-right: 5px;
            padding: 3px;
            /* background-color only for content */
            background-clip: content-box;
            border: solid 1px #cacaca;
            background-color: #fff;
            border-radius: 50%;
        }

        &:checked {
            & + label:before {
                appearance: none;
                -moz-appearance: none;
                -webkit-appearance: none;
                padding: 3px;
                background-color: #47a647;
            }

            & + label {
                color: #47a647;
            }
        }
    }

    label {
        display: inline-flex;
        align-items: center;
        font-size: 1rem;
    }
}

.quantity input {
    padding-left: 10px;
}

.description p span {
    font-size: 14px !important;
    // font-size: 0.875em !important;
    font-weight: normal;
    line-height: 1.2;
}

// .form-control option {
//   background:#000 !important;
// }

/* Input checkbox */

.checkboxField {
    display: flex;
    // font-size: 1rem;
}

// .checkboxField input[type="checkbox"] {
//   appearance: none;
//   -moz-appearance: none;
//   -webkit-appearance: none;
//   outline: none;
//   box-shadow: inset 0 0 0 2px #fff;
//   background: #fff;
//   border-radius: 10%;
//   position: relative;
//   margin: 0;
//   min-width: 15px;
//   height: 15px;
//   border: solid 2px #222;
// }

// .grey.checkboxField input[type="checkbox"] {
//   box-shadow: inset 0 0 0 2px #dedede;
//   background: #dedede;
// }

// .checkboxField {
//   input[type="checkbox"] {
//     &:checked {
//       border: none;

//       &:before {
//         appearance: none;
//         -moz-appearance: none;
//         -webkit-appearance: none;
//         font-family: FontAwesome;
//         display: inline-block;
//         content: "\f14a";
//         // letter-spacing: 5px;
//         color: #1d83c7;
//         // font-size: 17px;
//         // font-size: 1.0625em;
//         // line-height: 0.8;
//         width: 15px;
//         background: #fff;
//         border-radius: 20%;
//       }

//       &.width30px {
//         min-width: 30px;
//         height: 30px;

//         &:checked {
//           &:before {
//             font-size: 36px;
//             // font-size: 2.25em;
//             width: 30px;
//           }
//         }
//       }
//     }
//   }

.thumb-up-toggle {
    input[type='checkbox'] {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        outline: none;

        &:before {
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            font-family: FontAwesome;
            display: inline-block;
            content: '\f164';
            color: #d8d8d8;
            // font-size: 50px;
            font-size: 3.125em;
            font-weight: 100;
        }

        &:checked {
            &:before {
                content: '\f164';
                color: #29cb97;
            }
        }
    }
}

@media (max-width: 991px) {
    .thumb-up-toggle input[type='checkbox']:before {
        font-size: 20px;
        // font-size: 1.25em;
    }
}

.tick-toggle {
    input[type='checkbox'] {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        outline: none;

        &:before {
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            font-family: FontAwesome;
            display: inline-block;
            content: '\f14a';
            color: #d8d8d8;
            // font-size: 24px;
            font-size: 1.5em;
            font-weight: 100;
        }

        &:checked {
            &:before {
                content: '\f14a';
                color: #1d83c7;
            }
        }
    }
}

@media (max-width: 991px) {
    .tick-toggle input[type='checkbox']:before {
        font-size: 20px;
        // font-size: 1.25em;
    }
}

/* End of Input Checkbox */

.mce-notification-warning {
    display: none !important;
}

.mce-notification-inner {
    display: none !important;
}
.mce-close {
    display: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
}

.form-control {
    // border: solid 1px #dfe3e9;
    border: 1px solid #767676;
    line-height: 1;
    // height: 36px;
    // height: inherit;
    color: #222222;
    padding: 10px;
    font-size: 1rem;
    min-height: 36px;
    height: auto;
}

.form-control {
    resize: none !important;
}

p.form-control-static {
    font-size: 1rem;
    padding: 0;
    display: flex;
}

.control-label {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 5px;
    color: #767676;
}

.col-form-label {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;
}

.form-group {
    margin-bottom: 0;
}

.form-date {
    display: inline-block;
    // margin-left:10px;
    width: 100%;
    position: relative;
}

.form-date .form-control {
    border-radius: 3px;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    border: 1px solid #d8d8d8;
}

.form-date.viewFilter {
    margin-left: 10px;
}

.form-date:after {
    content: '\f073';
    font-family: 'FontAwesome';
    font-size: 1rem;
    color: #767676;
    right: 12px;
    top: 7px;
    position: absolute;
    pointer-events: none;
}

.help-block {
    min-height: 30px;
    margin: 0;
    padding: 5px 0;
}
.form-group {
    width: 100%;
}

.input-group.date .input-group-addon {
    border: none;
    color: #767676;
    background-color: transparent;
    width: auto;
    display: flex;
    align-items: center;
}

.input-group-addon {
    color: #222;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
}

.input-group.date .form-control {
    border: none;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    box-shadow: none;
    color: #222;
    font-weight: normal;
}

.input-group.date {
    border-radius: 3px;
    border: 1px solid #d8d8d8;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    // margin-bottom:30px;
    display: flex;
}

.input-group.currency input {
    border-top-left-radius: 1px !important;
    border-bottom-left-radius: 1px !important;
    border-left: none;
}

.input-group.currency select {
    padding-right: 10px;
}

/* form-dropdown */

.form-dropdown select,
.formDropdown select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

label.form-dropdown {
    position: relative;
}

// label.form-dropdown:after {
//     content:"\f0d7";
//     font-family: "FontAwesome";
//     font-size: 14px;
//     color:#989b9c;
//     right:14%; top:18%;
//     position:absolute;
//     pointer-events:none;
// }

.form-dropdown {
    // display: inline-block;
    // min-width: 150px;
    // margin-left: 10px;
    width: 100%;
    position: relative;
}

.form-dropdown.viewFilter {
    margin-left: 10px;
}

.form-dropdown.viewFilter.marginLeft0 {
    margin-left: 0;
}

.form-dropdown.digit {
    display: inline-block;
    min-width: 70px;
    margin-left: 10px;
    max-width: 70px;

    &.marginLeft0 {
        margin-left: 0;
    }
}

.formDropdown {
    position: relative;
}

.form-dropdown:after,
.formDropdown:after {
    content: '\f0d7';
    font-family: 'FontAwesome';
    font-size: 1rem;
    color: #3c3c43;
    right: 12px;
    // top: 12px;
    top: 24%;
    position: absolute;
    pointer-events: none;
    line-height: 1.2;
}

.input-group.currency .form-dropdown {
    min-width: auto;
}

.input-group.currency .form-dropdown select {
    border: none;
}

.input-group.currency .form-dropdown:after {
    right: 0;
    top: 0;
}

.calendar-time select {
    height: 28px;
}

.daterangepicker .calendar-time {
    line-height: 1;
}

/* end of form-dropdown */

.form-control {
    &:focus {
        border: 1px solid #1991eb;
        outline: none;
    }

    &:focus-visible {
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
    }
}

@media screen and (max-width: 767px) {
    .table-responsive {
        margin-bottom: 0;
    }
}

@media (min-width: 992px) {
    .form-date {
        width: 200px;
    }

    .periodBars .form-date {
        margin-left: 20px;
    }

    .periodBars .form-date:first-child {
        margin-left: 10px;
    }

    .max90px {
        max-width: 90px;
        width: 90px;
    }

    .width300px {
        width: 300px;
    }
}
@media (max-width: 991px) {
    .form-dropdown.viewFilter {
        margin-left: 28px;
    }
    .form-date.viewFilter {
        margin-left: 25px;
    }

    .subBar .periodBars {
        display: block;
    }

    .periodBars .form-date {
        width: 100%;
    }

    .periodBars .form-date {
        margin-left: 0;
        margin-top: 10px;
    }

    .max60px {
        max-width: 60px;
        width: 60px;
    }

    .width280px {
        width: 280px;
    }
}

// 2023

.radioField,
.checkboxField {
    .fa-check-circle,
    .fa-times-circle {
        font-size: 1.5rem;
    }

    .wrong {
        color: #ef4444;
    }

    .correct {
        color: #2d8659;
    }
}
