
.discussionOption {
    margin-top: 20px;
    line-height: 1.2;
    padding: 10px;
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 5px;

    &:first-child {
        margin-top: 0;
    }
}

.col-xs-12,
.col-md-8,
.col-md-4 {
    float: left;
}
.tableFixHead {
    overflow-y: auto;
    max-height: 200px;

    table {
        margin: 0;
        border-collapse: collapse;
        width: 100%;
        border: none;

        thead {
            position: sticky;
            top: 0;
        }

        tbody {
            td {
                border-top: solid 1px #d8d8d8;
                padding: 10px 20px;

                &:last-child {
                    width: 100px;
                }
            }
        }
    }
}

.vsaRightPanel {
    border: 1px solid #d8d8d8;
    border-radius: 5px;

    h3 {
        background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
        padding: 15px;
        margin: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
}

.vsaLeftPanel {
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    padding: 10px;
    margin-top: 20px;
    max-height: 525px;
    overflow: auto;
}

@media (min-width: 992px) {
    .paddingLeft10px {
        padding-left: 10px;
    }
    .paddingRight10px {
        padding-left: 10px;
    }
}
